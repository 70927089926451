import { useState } from 'react';
import {
  TypesLeftRightPicker,
  TypesSizesForDrum,
  TypesWeightForDrum,
  TypesBirthDayPicker,
  TypesBirthDayForDrum,
  TypesSetParametersPicker,
} from '../models';
import {
  sizesSwiperValuesCm,
  sizesSwiperValuesMm,
  sizesSwiperValuesKg,
  sizesSwiperValuesGramm,
  months,
  years,
} from '../utils';
import { getDaysInMonth } from 'date-fns';

const useSwiperPickerHelper = () => {
  const [weightPicker, setWeightPicker] = useState<TypesWeightForDrum>({ kg: 0, date: undefined, gramm: 0 });

  const [sizePicker, setSizePicker] = useState<TypesSizesForDrum>({ cm: 0, mm: 0 });

  const [birthDayPicker, setBirthDayPicker] = useState<TypesBirthDayForDrum>({ day: 1, month: months[0], year: 1987 });

  const handleSetWeight = ({ left, middle, right }: TypesSetParametersPicker) => {
    if (middle && typeof middle === 'number' && typeof left === 'string' && typeof right === 'number') {
      setWeightPicker({ kg: middle, date: left, gramm: right });
    } else if (!middle && typeof left === 'number' && typeof right === 'number') {
      setWeightPicker({ kg: left, date: middle, gramm: right });
    }
  };

  const handleSetSize = ({ left, middle, right }: TypesSetParametersPicker) => {
    if (middle && typeof middle === 'number' && typeof left === 'string' && typeof right === 'number')
      setSizePicker({ date: left, cm: middle, mm: right });
    else if (!middle && typeof left === 'number' && typeof right === 'number') {
      setSizePicker({ cm: left, date: middle, mm: right });
    }
  };

  const handleSetBirthDay = ({ left, middle, right }: TypesBirthDayPicker) => {
    if (typeof left === 'number' && typeof middle === 'string' && typeof right === 'number')
      setBirthDayPicker({ day: left, month: middle, year: right });
  };

  const getCountDays = () => {
    const month = months.findIndex((elem) => elem === birthDayPicker.month) + 1;

    const count = getDaysInMonth(new Date(`${month}/01/${birthDayPicker.year}`));

    return Array(count)
      .fill(null)
      .map((_, i) => i + 1);
  };

  return {
    weightPicker,
    setWeightPicker,
    sizePicker,
    setSizePicker,
    birthDayPicker,
    months,
    years,
    days: getCountDays(),
    setBirthDayPicker,
    handleSetSize,
    handleSetWeight,
    handleSetBirthDay,
    sizesSwiperValuesCm,
    sizesSwiperValuesMm,
    sizesSwiperValuesKg,
    sizesSwiperValuesGramm,
  };
};

export default useSwiperPickerHelper;
