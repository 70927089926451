import { Stack, StackProps } from '@mui/material';
import { Button, Typography } from 'front-package-ui-kit';
import React, { FC } from 'react';
import { IFilterButton, upperCaseFirst } from '../../../utils';

interface IProps extends Omit<StackProps, 'className'> {
  title?: string;
  options: IFilterButton[];
  filterValues: string[];
  onFilterButtonClickHandler: (filterItem: string) => () => void;
}

const FilterMenuItem: FC<IProps> = ({ title, options, filterValues, onFilterButtonClickHandler, ...props }) => {
  return (
    <Stack rowGap="12px" {...props}>
      {Boolean(title) && (
        <Typography textTransform="uppercase" variant="text7" opacity={0.5}>
          {title}
        </Typography>
      )}

      <Stack direction="row" flexWrap="wrap" columnGap="8px" rowGap="12px">
        {options?.map(({ value, disabled, name }, index) => (
          <Button
            key={`${value}-${index}`}
            variant={filterValues.includes(value) && !disabled ? 'contained' : 'outlined'}
            disabled={disabled}
            onClick={onFilterButtonClickHandler(value)}
            color={filterValues.includes(value) && !disabled ? 'lime' : 'grey20'}
            size="S"
          >
            <Typography whiteSpace="nowrap" disabled={disabled} variant="text6" fontWeight={500}>
              {upperCaseFirst(name)}
            </Typography>
          </Button>
        ))}
      </Stack>
    </Stack>
  );
};

export default FilterMenuItem;
