import { useAppDispatch, useCards } from '.';
import { getCardListThunk } from '../redux/order/thunks';
import { getTransformedCardAccount } from '../utils';
import { useEffectOnce } from 'usehooks-ts';

const useGetCard = () => {
  const dispatch = useAppDispatch();

  const { cards } = useCards();

  useEffectOnce(() => {
    dispatch(getCardListThunk());
  });

  const cardAccounts = getTransformedCardAccount(cards);

  return { cardAccounts };
};

export default useGetCard;
