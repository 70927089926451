import React from 'react';

import { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyCourseDetails = lazy(() => import('./CourseDetails'));

const CourseDetailsPage = () => (
  <Suspense fallback={<Loading />}>
    <LazyCourseDetails />
  </Suspense>
);

export default CourseDetailsPage;
