import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyPrivacyPolicy = lazy(() => import('./PrivacyPolicy'));

const PrivacyPolicyPage = () => (
  <Suspense fallback={<Loading />}>
    <LazyPrivacyPolicy />
  </Suspense>
);

export default PrivacyPolicyPage;
