import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyVideoDetails = lazy(() => import('./VideoDetails'));

const VideoDetailsPage = () => (
  <Suspense fallback={<Loading />}>
    <LazyVideoDetails />
  </Suspense>
);

export default VideoDetailsPage;
