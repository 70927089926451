import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyMethodPayment = lazy(() => import('./MethodPayment'));

const MethodPaymentPage = () => (
  <Suspense fallback={<Loading />}>
    <LazyMethodPayment />
  </Suspense>
);

export default MethodPaymentPage;
