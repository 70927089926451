import React, { FC } from 'react';
import s from './styles.module.scss';
import { Stack, BoxProps, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import SliderCards, { TRenderCard, TSkeleton } from '../../molecules/SliderCards';
import { ICourse } from '../../../api/types';
import { KeyboardArrowRightIcon, Skeleton, Typography } from 'front-package-ui-kit';

export const TEST_ID = 'CourseSwiper';

interface IProps extends BoxProps {
  courses: ICourse[];
  title: string;
  path: string;
  renderCard: TRenderCard<ICourse>;
  skeleton?: TSkeleton;
  loading?: boolean;
}

const CourseSwiper: FC<IProps> = ({ title, path, courses, renderCard, loading, skeleton, ...props }) => {
  return (
    <Box flexShrink={0} {...props}>
      <Stack className={s.title} direction="row" justifyContent="space-between">
        {!loading && (
          <Typography variant="h4" fontWeight={700}>
            {title}
          </Typography>
        )}

        {loading && <Skeleton width="45%" height="20px" borderRadius="4px" />}

        {!loading && courses.length > 1 && (
          <Link className={s.link} to={path}>
            <Typography variant="text6" color="black" opacity={0.5} fontWeight={500}>
              Все
            </Typography>

            <KeyboardArrowRightIcon size={16} color="grey" />
          </Link>
        )}
      </Stack>

      <SliderCards options={courses} renderCard={renderCard} loading={loading} skeleton={skeleton} />
    </Box>
  );
};

export default CourseSwiper;
