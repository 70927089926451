import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { API_BASE_URL } from './config';
import { store } from '../redux/store';
import { ApiVersion, Environment, ErrorMessages } from '../utils/constants/config';
import { RemoveTokens, setSession } from './jwt';
import { createServiceNotice } from '../redux/notifications/slice';
import { noticeCreator } from '../utils';
import { setLoading, setTokens } from '../redux/auth/slice';
import jwtDecode from 'jwt-decode';
import { setServerConection } from '../redux/serverConection/slice';

const ApiClient = axios.create();

interface IRequestQueueElem {
  resolve: (value: string | PromiseLike<string>) => void;
  reject: (reason?: any) => void;
}

let timer: NodeJS.Timer | null = null;
let isRefreshing = false;
let requestQueue: IRequestQueueElem[] = [];

const shouldIntercept = (tokenTime: number) => {
  const currentTime = Date.now() / 1000;

  return tokenTime < currentTime;
};

const handleTokenRefresh = async (): Promise<ITokens> => {
  const tokensStringify = await window?.getTokens();

  if (!tokensStringify) {
    throw new Error();
  }

  const tokens: ITokens = JSON.parse(tokensStringify);

  timer = setTimeout(() => {
    store.dispatch(setLoading(true));
  }, 5000);

  try {
    const { data: newTokens } = await axios.post<ITokens>(`${API_BASE_URL}/${ApiVersion.baseService}/clients/refresh`, {
      refresh_token: tokens.refresh_token,
    });

    store.dispatch(setServerConection(true));

    return newTokens;
  } catch (e) {
    if (e instanceof AxiosError) {
      if (e.response?.status === 401) {
        await setSession(RemoveTokens.message);

        store.dispatch(
          createServiceNotice({
            notice: noticeCreator(ErrorMessages.refreshToken, 'error'),
            otherInfo: { error: e, pathname: 'handleTokenRefresh', forEnvironment: Environment.production },
          })
        );

        store.dispatch(setServerConection(true));

        throw new Error();
      } else {
        store.dispatch(setServerConection(false));

        throw new Error();
      }
    } else {
      throw new Error();
    }
  } finally {
    clearTimeout(timer);

    store.dispatch(setLoading(false));
  }
};

const attachTokenToRequest = (request: AxiosRequestConfig<any>, token: string) => {
  if (!request?.headers) return;

  request.headers.Authorization = token;
};

const refreshManager = (axiosClient: AxiosInstance) => {
  const processQueue = (error: unknown, token: string | null = null) => {
    requestQueue.forEach((prom: any) => {
      if (error) {
        prom.reject(error);
      } else {
        if (token === null) return;

        prom.resolve(token);
      }
    });

    requestQueue = [];
  };

  const interceptor = async (config: AxiosRequestConfig<any>) => {
    const tokensStringify = await window?.getTokens();

    if (!tokensStringify) return config;

    const tokens: ITokens = JSON.parse(tokensStringify);

    if (config?.headers) config.headers.Authorization = tokens.access_token;

    const tokenTime = tokens.tokenTime ? tokens.tokenTime : jwtDecode<{ exp: number }>(tokens.access_token).exp;

    if (!shouldIntercept(tokenTime)) return config;

    if (isRefreshing) {
      await new Promise<string>(function (resolve, reject) {
        requestQueue.push({ resolve, reject });
      })
        .then((token) => {
          attachTokenToRequest(config, token);
        })
        .catch(() => {
          return Promise.reject();
        });

      return config;
    }

    isRefreshing = true;

    try {
      const newTokens = await handleTokenRefresh();

      setSession(newTokens);

      attachTokenToRequest(config, newTokens.access_token);

      processQueue(null, newTokens.access_token);

      store.dispatch(setTokens(newTokens));
    } catch (error) {
      processQueue(error, null);
    } finally {
      isRefreshing = false;
    }

    return config;
  };

  const responseErrorInterceptor = async (error: unknown) => {
    if (error instanceof AxiosError) {
      if (error.response?.status === 401) {
        if (isRefreshing) {
          return Promise.reject(error);
        }

        isRefreshing = true;

        try {
          const newTokens = await handleTokenRefresh();

          setSession(newTokens);

          store.dispatch(setTokens(newTokens));
        } catch (e) {
          //подумать, есть ли необходимость при обработке ошибки рефреша, совершать дополнительные действия
        } finally {
          isRefreshing = false;
        }
      }
    }

    return Promise.reject(error);
  };

  axiosClient.interceptors.request.use(interceptor, undefined);
  axiosClient.interceptors.response.use(undefined, responseErrorInterceptor);
};

refreshManager(ApiClient);

export default ApiClient;
