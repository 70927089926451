import React, { FC } from 'react';
import s from './styles.module.scss';
import { BottomSheetHeader, BottomSheetUI, ImageCover } from '../..';
import { useBottomSheet, useParseWebView } from '../../../hooks';
import { Box, Stack } from '@mui/material';
import { ImagePaths, ResizeType, ValuesOfConstants } from '../../../utils/constants/config';
import { ProIcon, StarIcon } from '../../../assets/icons';
import DumbbellImage from '../../../assets/image/dumbbellImage.png';
import CarpetImage from '../../../assets/image/CarpetImage.png';
import defaultCover from '../../../assets/image/defaultCover.png';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Button, Badge, Typography } from 'front-package-ui-kit';
import { IGrade } from '../../../api/types';
import parse from 'html-react-parser';

export const TEST_ID = 'DetailsBottomSheet';

export const openBottomSheetDetails = 'openBottomSheetDetails';

const settingsSlider = {
  dots: true,
  infinite: true,
  speed: 500,
  swipeToSlide: true,
  slickGoTo: true,
  swipe: true,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 7000,
};

interface IProps {
  grades: IGrade[];
  isPaid?: boolean;
}

const DetailsBottomSheet: FC<IProps> = ({ grades, isPaid = true }) => {
  const { onCloseBottomSheet, getIsOpenBottomSheet } = useBottomSheet();

  const [textProgramm, textWorkout] = useParseWebView([
    ValuesOfConstants.bottomSheetPaymentProgramm,
    ValuesOfConstants.bottomSheetPaymentWorkout,
  ]);

  const isOpen = getIsOpenBottomSheet(openBottomSheetDetails);
  return (
    <BottomSheetUI
      open={isOpen}
      touchLine
      header={<BottomSheetHeader transparent />}
      height="full"
      onDismiss={() => onCloseBottomSheet()}
      background="grey"
    >
      <Stack className={s.detailsContainer}>
        <Stack direction="column" rowGap="24px" marginBottom="40px">
          <Stack direction="row" justifyContent="center" alignItems="center" columnGap="8px">
            <Typography variant="p" fontSize="32px" lineHeight="40px" fontWeight={700} textTransform="uppercase">
              Kolsa
            </Typography>
            <Badge size="L" color="grey100">
              <Typography color="white" variant="text3" textTransform="uppercase" fontWeight={700} marginRight="2px">
                pro
              </Typography>

              <ProIcon size="medium" />
            </Badge>
          </Stack>

          {!isPaid && (
            <Stack className={s.buttonContainer} justifyContent="center">
              <Button size="L" onClick={() => onCloseBottomSheet()} variant="contained" color="lime" fullWidth>
                <Typography variant="text6" color="black" fontWeight={700}>
                  Оформить
                </Typography>
              </Button>
            </Stack>
          )}
        </Stack>

        <Typography variant="text3" fontWeight={700} marginBottom="14px">
          {isPaid && 'Тебе доступны:'}
          {!isPaid && 'Тебя ждет:'}
        </Typography>

        <Stack
          className={s.containerInform}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          marginBottom="14px"
        >
          <div className={s.text}>{textProgramm}</div>

          <div className={s.imgContainer}>
            <ImageCover img={DumbbellImage} />
          </div>
        </Stack>

        <Stack
          className={s.containerInform}
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          marginBottom="40px"
        >
          <div className={s.text}>{textWorkout}</div>

          <div className={s.imgContainer}>
            <ImageCover img={CarpetImage} />
          </div>
        </Stack>

        <Typography variant="text3" fontWeight={500} textAlign="center" marginBottom="40px">
          Выбери направление <br /> и начни двигаться к цели!
        </Typography>

        <Slider className={s.slider} {...settingsSlider}>
          {grades.map((grade) => (
            <Stack key={`${grade.user_name}-${grade.id}`} className={s.reviewContainer} columnGap="14px">
              <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom="14px">
                <Stack direction="row">
                  {new Array(grade.rating).fill(null).map((_, index) => (
                    <StarIcon key={`${grade.user_name}-${index}`} />
                  ))}
                </Stack>

                <Stack direction="row" columnGap="8px" alignItems="center">
                  <Typography variant="text7">{grade.user_name}</Typography>

                  <Box className={s.photoContainer}>
                    <ImageCover
                      img={{
                        baseApiService: 'APP',
                        imagePath: ImagePaths.gradePhoto,
                        name: grade.user_photo,
                        width: 72,
                        height: 72,
                        resize: ResizeType.resizeBothCenter,
                      }}
                      defaultImg={defaultCover}
                    />
                  </Box>
                </Stack>
              </Stack>

              <div className={s.textContainer}>{parse(grade.text, { trim: true })}</div>
            </Stack>
          ))}
        </Slider>
      </Stack>
    </BottomSheetUI>
  );
};

export default DetailsBottomSheet;
