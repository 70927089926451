import React, { FC, useState, useEffect } from 'react';
import MultiPicker from 'rmc-picker/lib/MultiPicker';
import Picker from 'rmc-picker/lib/Picker';
import 'rmc-picker/assets/index.css';
import cn from 'classnames';
import s from './styles.module.scss';
import { SwiperPickerValues } from './config';
import { TypesLeftRightPicker, TypesBirthDayPicker } from '../../../models';
import { Stack } from '@mui/material';
import { Skeleton } from 'front-package-ui-kit';

export const TEST_ID = 'SwiperPicker';

type ValueOfSwiperPicker = [string | number, string | number];

type TChangeRightLeftPicker = ({ left, right }: TypesLeftRightPicker) => void;

type TChangeBirthDayPicker = ({ left, middle, right }: TypesBirthDayPicker) => void;
interface IPicker {
  options: string[] | number[];
  onChange?: (currentValue: string | number) => void;
  defaultValue: string | number;
  unit?: string;
}
interface IProps {
  leftPicker: IPicker;
  rightPicker: IPicker;
  middlePicker?: IPicker;
  onChange?: TChangeBirthDayPicker | TChangeRightLeftPicker;
  loading?: boolean;
}

const getValuesPicker = (isMiddlePicker: boolean, values: (string | number)[]) => {
  if (isMiddlePicker)
    return {
      leftValue: values[0],
      middleValue: values[1],
      rightValue: values[2],
    };
  return {
    leftValue: values[0],
    rightValue: values[1],
  };
};

const getMiddlePicker = (
  onChange: TChangeBirthDayPicker,
  picker: IPicker | undefined
): onChange is TChangeRightLeftPicker => {
  return picker ? false : true;
};

const SwiperPicker: FC<IProps> = ({ leftPicker, rightPicker, middlePicker, onChange, loading }) => {
  const leftPickerContent = cn(s.indicatorSwiperPicker, s.left, {
    [s.cm]: leftPicker.unit === SwiperPickerValues.cm,
    [s.kg]: leftPicker.unit === SwiperPickerValues.kg,
    [s.h]: leftPicker.unit === SwiperPickerValues.h,
  });

  const rightPickerContent = cn(s.indicatorSwiperPicker, s.right, {
    [s.mm]: rightPicker.unit === SwiperPickerValues.mm,
    [s.gramm]: rightPicker.unit === SwiperPickerValues.gramm,
    [s.min]: rightPicker.unit === SwiperPickerValues.min,
  });

  const middlePickerContent = cn(s.indicatorSwiperPicker, s.middle, {
    [s.kg]: middlePicker ? middlePicker.unit === SwiperPickerValues.kg : null,
    [s.cm]: middlePicker ? middlePicker.unit === SwiperPickerValues.cm : null,
    [s.h]: middlePicker ? middlePicker.unit === SwiperPickerValues.h : null,
  });

  const [value, setValue] = useState(
    middlePicker
      ? [leftPicker.defaultValue, middlePicker.defaultValue, rightPicker.defaultValue]
      : [leftPicker.defaultValue, rightPicker.defaultValue]
  );

  const { leftValue, middleValue, rightValue } = getValuesPicker(!!middlePicker, value);

  const handleChange = (val: ValueOfSwiperPicker) => {
    setValue(val);
  };

  useEffect(() => {
    if (leftPicker.onChange && rightPicker.onChange) {
      leftPicker.onChange(value[0]);

      rightPicker.onChange(value[1]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (!onChange) return;

    if (getMiddlePicker(onChange, middlePicker)) {
      return onChange({ left: value[0], right: value[1] });
    }
    return onChange({ left: value[0], middle: value[1], right: value[2] });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <>
      {!loading && (
        <div
          className={s.swiperPicker}
          data-right-value={String(rightValue).length}
          data-middle-value={String(middleValue).length}
          data-left-value={String(leftValue).length}
        >
          {middlePicker && (
            <MultiPicker className={s.contentSwiperPicker} selectedValue={value} onValueChange={handleChange}>
              <Picker indicatorClassName={leftPickerContent}>
                {leftPicker.options.map((elem, i) => (
                  <Picker.Item className={s.itemSwiperPicker} value={elem} key={`${elem}-${i}`}>
                    {elem}
                  </Picker.Item>
                ))}
              </Picker>

              <Picker indicatorClassName={middlePickerContent}>
                {middlePicker.options.map((elem, i) => (
                  <Picker.Item className={s.itemSwiperPicker} value={elem} key={`${elem}-${i}`}>
                    {elem}
                  </Picker.Item>
                ))}
              </Picker>

              <Picker indicatorClassName={rightPickerContent}>
                {rightPicker.options.map((elem, i) => (
                  <Picker.Item className={s.itemSwiperPicker} value={elem} key={`${elem}-${i}`}>
                    {elem}
                  </Picker.Item>
                ))}
              </Picker>
            </MultiPicker>
          )}

          {!middlePicker && (
            <MultiPicker className={s.contentSwiperPicker} selectedValue={value} onValueChange={handleChange}>
              <Picker indicatorClassName={leftPickerContent}>
                {leftPicker.options.map((elem, i) => (
                  <Picker.Item className={s.itemSwiperPicker} value={elem} key={`${elem}-${i}`}>
                    {elem}
                  </Picker.Item>
                ))}
              </Picker>

              <Picker indicatorClassName={rightPickerContent}>
                {rightPicker.options.map((elem, i) => (
                  <Picker.Item className={s.itemSwiperPicker} value={elem} key={`${elem}-${i}`}>
                    {elem}
                  </Picker.Item>
                ))}
              </Picker>
            </MultiPicker>
          )}
        </div>
      )}

      {loading && (
        <Stack className={s.contentSwiperPicker} alignItems="center" justifyContent="center">
          <Skeleton width="100%" height="34px"></Skeleton>
        </Stack>
      )}
    </>
  );
};

export default SwiperPicker;
