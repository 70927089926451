import { FC } from 'react';
import { Box, Stack } from '@mui/material';
import { Button, Typography } from 'front-package-ui-kit';
import { NotFoundCourses } from '../../../assets/icons';
import s from './styles.module.scss';

export const TEST_ID = 'CoursesNotFound';

interface IProps {
  onClear: () => void;
}

const CoursesNotFound: FC<IProps> = ({ onClear }) => {
  return (
    <Stack height="100%" width="100%" justifyContent="center" alignItems="center">
      <Box>
        <NotFoundCourses />

        <Typography variant="text6" fontWeight={500} marginTop="8px" marginBottom="16px">
          Ничего не найдено
        </Typography>
      </Box>

      <Button onClick={onClear} variant="contained" size="M" color="black">
        <Typography variant="text4" fontWeight={500} color="white">
          Сбросить фильтры
        </Typography>
      </Button>
    </Stack>
  );
};

export default CoursesNotFound;
