import { FC } from 'react';
import { Box, Container } from '@mui/material';
import { CssPlatformContainer } from '../../components';
import { Button, CircleSpinnerIcon, NamesOfColors, Typography } from 'front-package-ui-kit';
import { NoServerConectionIcon } from '../../assets/icons';
import { useAppDispatch, useAuth } from '../../hooks';
import axios, { AxiosError } from 'axios';
import { API_BASE_URL } from '../../api/config';
import { ApiVersion, Environment, ErrorMessages, noticeCreator } from '../../utils';
import { RemoveTokens, setSession } from '../../api/jwt';
import { createServiceNotice } from '../../redux/notifications/slice';
import { setServerConection } from '../../redux/serverConection/slice';
import { setLoading, setTokens } from '../../redux/auth/slice';
import s from './styles.module.scss';

export const TEST_ID = 'NoServerConection';

const NoServerConection: FC = () => {
  const { isLoading } = useAuth();

  const dispatch = useAppDispatch();

  const handleTokenRefresh = async () => {
    const tokensStringify = await window?.getTokens();

    if (!tokensStringify) {
      dispatch(setServerConection(true));

      return;
    }

    const tokens: ITokens = JSON.parse(tokensStringify);

    try {
      dispatch(setLoading(true));

      const { data: newTokens } = await axios.post<ITokens>(
        `${API_BASE_URL}/${ApiVersion.baseService}/clients/refresh`,
        {
          refresh_token: tokens.refresh_token,
        }
      );

      setSession(newTokens);

      dispatch(setTokens(newTokens));

      dispatch(setServerConection(true));
    } catch (e) {
      if (e instanceof AxiosError) {
        if (e.response?.status === 401) {
          await setSession(RemoveTokens.message);

          dispatch(
            createServiceNotice({
              notice: noticeCreator(ErrorMessages.refreshToken, 'error'),
              otherInfo: { error: e, pathname: 'handleTokenRefresh', forEnvironment: Environment.production },
            })
          );

          throw new Error();
        } else {
          dispatch(setServerConection(false));

          throw new Error();
        }
      } else {
        dispatch(setServerConection(false));

        throw new Error();
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <Container disableGutters maxWidth="xs" className={s.noServerConection} data-testid={TEST_ID}>
      <CssPlatformContainer>
        <div className={s.noServerConectionContent}>
          <Box marginBottom="11px">
            <NoServerConectionIcon />
          </Box>

          <Typography textAlign="center" variant="text6" fontWeight={500} marginBottom="16px">
            Потеряли связь с сервером, <br /> но мы уже чиним!
          </Typography>

          <Box width="118px">
            <Button
              onClick={handleTokenRefresh}
              variant="contained"
              fullWidth
              size="M"
              color="black"
              loading={isLoading}
            >
              {!isLoading && (
                <Typography variant="text4" fontWeight={500} color="white">
                  Обновить
                </Typography>
              )}

              {isLoading && <CircleSpinnerIcon size={20} color={NamesOfColors.white100} />}
            </Button>
          </Box>
        </div>
      </CssPlatformContainer>
    </Container>
  );
};

export default NoServerConection;
