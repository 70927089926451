import { FC, memo } from 'react';
import { ImageCover, VideoCover } from '../..';
import { API_VIDEO_URL } from '../../../api/config';
import { IFilesForDetailseCourseCover } from '../../../api/types';
import defaultCover from '../../../assets/image/defaultCover.png';
import { ApiVersion, BaseVideoCoverPath, FilesCoverPath, ResizeType } from '../../../utils/constants/config';
import s from './styles.module.scss';

export const TEST_ID = 'DetailsPageCover';

type TFormats = 'jpg' | 'jpeg' | 'png' | 'webm' | 'mp4' | 'ogg';

const isFormat = (format: string): format is TFormats => {
  return (
    format === 'jpg' ||
    format === 'webm' ||
    format === 'mp4' ||
    format === 'ogg' ||
    format === 'jpeg' ||
    format === 'png'
  );
};

interface IProps {
  files: IFilesForDetailseCourseCover[] | undefined;
  fileCoverPath: FilesCoverPath;
}

interface IProps {}

const DetailsPageCover: FC<IProps> = memo(({ files = [], fileCoverPath }) => {
  const { img, webm, mp4, ogg } = files.reduce(
    (acc, { name: pathName }) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/naming-convention
      const [_, videoFormat] = pathName.split('.');

      const videoFormatLowerCase = videoFormat.toLocaleLowerCase();

      if (isFormat(videoFormatLowerCase)) {
        if (videoFormatLowerCase === 'jpg' || videoFormatLowerCase === 'jpeg' || videoFormatLowerCase === 'png') {
          acc.img = pathName;
        } else {
          acc[videoFormatLowerCase] = pathName;
        }
      }

      return acc;
    },
    { img: '', webm: '', mp4: '', ogg: '' }
  );

  const isVideo = Boolean(webm) || Boolean(mp4) || Boolean(ogg);

  return (
    <div className={s.detailsPageCover}>
      {!isVideo && (
        <ImageCover
          img={{
            baseApiService: 'VIDEO',
            imagePath: fileCoverPath,
            name: img,
            width: 1000,
            height: 1480,
            resize: ResizeType.resizeWidthCenter,
          }}
          fit="cover"
          defaultImg={defaultCover}
        />
      )}

      {isVideo && (
        <VideoCover
          webm={`${API_VIDEO_URL}/${ApiVersion.videoService}/${BaseVideoCoverPath.path}/${webm}`}
          mp4={`${API_VIDEO_URL}/${ApiVersion.videoService}/${BaseVideoCoverPath.path}/${mp4}`}
          ogg={`${API_VIDEO_URL}/${ApiVersion.videoService}/${BaseVideoCoverPath.path}/${ogg}`}
          poster={defaultCover}
        />
      )}
    </div>
  );
});

DetailsPageCover.displayName = 'DetailsPageCover';
export default DetailsPageCover;
