import React, { FC, useRef, useState } from 'react';
import s from './styles.module.scss';
import { Stack, Box } from '@mui/material';
import { StoryCardPointsIcon } from '../../../assets/icons';
import { Typography } from 'front-package-ui-kit';
import DropDownMenu from '../../atoms/DropDownMenu';

export const TEST_ID = 'StoryCard';

interface IProps {
  date: string;
  onHandleDelete?: () => void;
  onHandleEdit: () => void;
  measure: string;
}

const StoryCard: FC<IProps> = ({ date, measure, onHandleDelete, onHandleEdit }) => {
  const wrapperRef = useRef(null);

  const [openDropDown, setOpenDropDown] = useState(false);

  const handleToggleDropDown = () => {
    setOpenDropDown((prevOpen) => !prevOpen);
  };

  const handleDelete = () => {
    if (onHandleDelete) {
      onHandleDelete();
      setOpenDropDown(false);
    }
  };

  const handleEdit = () => {
    onHandleEdit();
    setOpenDropDown(false);
  };

  return (
    <Stack
      className={s.storyCard}
      data-testid={TEST_ID}
      direction="row"
      justifyContent="space-between"
      alignItems="center"
    >
      <Typography variant="text5" fontWeight={500}>
        {measure}
      </Typography>

      <Stack direction="row" columnGap="8px" alignItems="center">
        <Typography variant="text6" fontWeight={500} opacity={0.5}>
          {date}
        </Typography>

        <Stack ref={wrapperRef}>
          <button className={s.dropDownBtn} onClick={() => handleToggleDropDown()}>
            <StoryCardPointsIcon />
          </button>

          <Box className={s.dropDownContainer}>
            {wrapperRef && (
              <DropDownMenu open={openDropDown} setOpen={setOpenDropDown} ref={wrapperRef}>
                <div className={s.dropDownCard}>
                  <button className={s.btn} onClick={handleEdit}>
                    <Typography variant="text5" marginLeft="14px">
                      Редактировать
                    </Typography>
                  </button>
                  <Box className={s.greyLine}></Box>
                  {onHandleDelete && (
                    <button className={s.btn} onClick={handleDelete}>
                      <Typography variant="text5" marginLeft="14px">
                        Удалить
                      </Typography>
                    </button>
                  )}
                </div>
              </DropDownMenu>
            )}
          </Box>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default StoryCard;
