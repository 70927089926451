import React from 'react';

export const NotFoundWeightIcon = () => {
  return (
    <svg width="80" height="83" viewBox="0 0 80 83" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3087_993)">
        <path
          d="M29.7669 38.2555C29.7669 33.3576 25.7752 29.3871 20.8512 29.3871C15.9272 29.3871 11.9355 33.3576 11.9355 38.2555V64.5725C11.9355 69.4704 15.9272 73.4409 20.8512 73.4409C25.7752 73.4409 29.7669 69.4704 29.7669 64.5725V38.2555Z"
          fill="url(#paint0_linear_3087_993)"
        />
        <path
          d="M67.5266 38.2555C67.5266 33.3576 63.5349 29.3871 58.611 29.3871C53.687 29.3871 49.6953 33.3576 49.6953 38.2555V64.5725C49.6953 69.4704 53.687 73.4409 58.611 73.4409C63.5349 73.4409 67.5266 69.4704 67.5266 64.5725V38.2555Z"
          fill="url(#paint1_linear_3087_993)"
        />
        <g filter="url(#filter0_bi_3087_993)">
          <path
            d="M58.9127 82.8354H21.0579C11.7751 82.8354 4.0867 75.64 3.47834 66.3782L0.436527 20.0378C-0.23477 9.87393 7.83127 1.26245 18.0161 1.26245H61.9545C72.1394 1.26245 80.2054 9.87393 79.5341 20.0378L76.4818 66.3782C75.8735 75.64 68.185 82.8354 58.9022 82.8354H58.9127Z"
            fill="url(#paint2_linear_3087_993)"
            fillOpacity="0.2"
          />
          <path
            d="M0.785775 20.0148L0.785766 20.0147C0.127814 10.0529 8.03355 1.61245 18.0161 1.61245H61.9545C71.9371 1.61245 79.8428 10.0529 79.1849 20.0147L79.1849 20.0148L76.1326 66.3552L76.1326 66.3553C75.5363 75.4329 68.0008 82.4855 58.9022 82.4855H21.0579C11.9594 82.4855 4.42385 75.4329 3.82759 66.3553L0.785775 20.0148Z"
            stroke="url(#paint3_linear_3087_993)"
            strokeWidth="0.7"
          />
        </g>
        <rect x="25.3252" y="8.57947" width="29.3264" height="7.646" rx="3.823" fill="url(#paint4_linear_3087_993)" />
      </g>
      <defs>
        <filter
          id="filter0_bi_3087_993"
          x="-12.1893"
          y="-11.3244"
          width="104.349"
          height="106.747"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6.2934" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_3087_993" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_3087_993" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.0978" />
          <feGaussianBlur stdDeviation="4.1956" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3087_993" />
        </filter>
        <linearGradient
          id="paint0_linear_3087_993"
          x1="20.8512"
          y1="29.3871"
          x2="20.8512"
          y2="73.4409"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8FF00" />
          <stop offset="1" stopColor="#00FF57" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3087_993"
          x1="58.611"
          y1="29.3871"
          x2="58.611"
          y2="73.4409"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8FF00" />
          <stop offset="1" stopColor="#00FF57" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3087_993"
          x1="79.9716"
          y1="15.5207"
          x2="109.941"
          y2="64.1016"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9D9C9C" />
          <stop offset="1" stopColor="#C1C1C1" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3087_993"
          x1="5.98638"
          y1="7.67801"
          x2="74.2795"
          y2="78.5532"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3087_993"
          x1="26.0927"
          y1="16.2003"
          x2="36.619"
          y2="29.6494"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.75" />
          <stop offset="1" stopColor="white" stopOpacity="0.39" />
        </linearGradient>
        <clipPath id="clip0_3087_993">
          <rect width="79" height="83" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
