import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyPopularWorkouts = lazy(() => import('./PopularWorkouts'));

const PopularWorkoutsPage = () => (
  <Suspense fallback={<Loading />}>
    <LazyPopularWorkouts />
  </Suspense>
);

export default PopularWorkoutsPage;
