import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyComments = lazy(() => import('./Comments'));

const CommentsPage = () => (
  <Suspense fallback={<Loading />}>
    <LazyComments />
  </Suspense>
);

export default CommentsPage;
