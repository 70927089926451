import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyNeedUpdate = lazy(() => import('./NeedUpdate'));

const NeedUpdatePage = () => (
  <Suspense fallback={<Loading />}>
    <LazyNeedUpdate />
  </Suspense>
);

export default NeedUpdatePage;
