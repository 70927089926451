import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyCourseVideos = lazy(() => import('./CourseVideos'));

const CourseVideosPage = () => (
  <Suspense fallback={<Loading />}>
    <LazyCourseVideos />
  </Suspense>
);

export default CourseVideosPage;
