import React from 'react';

export const NotFoundMyCourses = () => {
  return (
    <svg width="69" height="90" viewBox="0 0 69 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_bi_3051_1389)">
        <path
          d="M60.946 12.6194L56.9733 1.52778C56.8259 1.11579 56.5713 0.762617 56.2424 0.513883C55.9134 0.26515 55.5252 0.132284 55.128 0.132446H11.428C11.0307 0.132284 10.6425 0.26515 10.3136 0.513883C9.98462 0.762617 9.73001 1.11579 9.58263 1.52778L5.6099 12.6194C5.48948 12.9561 5.44475 13.3205 5.47965 13.6809C5.51456 14.0414 5.62801 14.3867 5.8101 14.6868C5.99219 14.9869 6.23735 15.2326 6.52415 15.4024C6.81095 15.5722 7.13063 15.6609 7.45523 15.6608H59.1007C59.4253 15.6609 59.745 15.5722 60.0318 15.4024C60.3186 15.2326 60.5637 14.9869 60.7458 14.6868C60.9279 14.3867 61.0414 14.0414 61.0763 13.6809C61.1112 13.3205 61.0665 12.9561 60.946 12.6194Z"
          fill="url(#paint0_linear_3051_1389)"
          fillOpacity="0.2"
        />
        <path
          d="M56.6438 1.6458L56.6437 1.64567C56.5178 1.29371 56.3025 0.998148 56.0313 0.793058M56.6438 1.6458L56.0313 0.793058M56.6438 1.6458L60.6165 12.7373C60.6165 12.7374 60.6165 12.7374 60.6165 12.7375C60.7193 13.0249 60.7579 13.3375 60.7279 13.6472C60.6979 13.957 60.6005 14.2516 60.4466 14.5053C60.2927 14.7589 60.0881 14.9623 59.8535 15.1012C59.6192 15.2399 59.3609 15.3109 59.1008 15.3108H59.1007H7.45523H7.45509C7.19501 15.3109 6.93674 15.2399 6.70245 15.1012C6.46784 14.9623 6.2632 14.7589 6.10933 14.5053C5.95539 14.2516 5.85802 13.957 5.82803 13.6472C5.79803 13.3374 5.83661 13.0248 5.93945 12.7373L9.91213 1.6458L9.91218 1.64567C10.0381 1.29372 10.2534 0.998149 10.5247 0.793058L10.3136 0.513883M56.6438 1.6458L10.3136 0.513883M56.0313 0.793058C55.7606 0.588431 55.4461 0.482316 55.1281 0.482446L55.128 0.482446L11.428 0.482446H11.4278C11.1099 0.482316 10.7953 0.588431 10.5247 0.793058L10.3136 0.513883M56.0313 0.793058L10.3136 0.513883"
          stroke="url(#paint1_linear_3051_1389)"
          strokeWidth="0.7"
        />
      </g>
      <g filter="url(#filter1_bi_3051_1389)">
        <path
          d="M61.8229 76.6612L57.8501 87.7529C57.7027 88.1649 57.4481 88.518 57.1192 88.7668C56.7902 89.0155 56.4021 89.1484 56.0048 89.1482H12.3048C11.9075 89.1484 11.5194 89.0155 11.1904 88.7668C10.8615 88.518 10.6068 88.1649 10.4595 87.7529L6.48673 76.6612C6.36631 76.3246 6.32159 75.9601 6.35649 75.5997C6.39139 75.2393 6.50484 74.8939 6.68693 74.5938C6.86902 74.2937 7.11419 74.048 7.40098 73.8782C7.68778 73.7085 8.00746 73.6197 8.33206 73.6199H59.9775C60.3021 73.6197 60.6218 73.7085 60.9086 73.8782C61.1954 74.048 61.4406 74.2937 61.6227 74.5938C61.8047 74.8939 61.9182 75.2393 61.9531 75.5997C61.988 75.9601 61.9433 76.3246 61.8229 76.6612Z"
          fill="url(#paint2_linear_3051_1389)"
          fillOpacity="0.2"
        />
        <path
          d="M57.5206 87.6348L57.5206 87.635C57.3947 87.9869 57.1793 88.2825 56.9081 88.4876M57.5206 87.6348L56.9081 88.4876M57.5206 87.6348L61.4933 76.5433C61.4933 76.5433 61.4933 76.5432 61.4934 76.5432C61.5962 76.2558 61.6347 75.9432 61.6047 75.6334C61.5747 75.3237 61.4774 75.0291 61.3234 74.7754C61.1696 74.5218 60.9649 74.3183 60.7303 74.1794C60.496 74.0407 60.2377 73.9698 59.9777 73.9699H59.9775H8.33206H8.33192C8.07184 73.9698 7.81357 74.0407 7.57928 74.1794C7.34467 74.3183 7.14003 74.5218 6.98616 74.7754C6.83222 75.0291 6.73485 75.3237 6.70486 75.6334C6.67486 75.9432 6.71345 76.2559 6.81628 76.5433L10.789 87.6348L10.789 87.635C10.9149 87.9869 11.1303 88.2825 11.4015 88.4876L11.1904 88.7668M57.5206 87.6348L11.1904 88.7668M56.9081 88.4876C56.6375 88.6922 56.3229 88.7983 56.0049 88.7982L56.0048 88.7982L12.3048 88.7982H12.3046C11.9867 88.7983 11.6721 88.6922 11.4015 88.4876L11.1904 88.7668M56.9081 88.4876L11.1904 88.7668"
          stroke="url(#paint3_linear_3051_1389)"
          strokeWidth="0.7"
        />
      </g>
      <rect
        x="0.155762"
        y="10.7571"
        width="67.9983"
        height="67.9983"
        rx="12.7497"
        fill="url(#paint4_linear_3051_1389)"
      />
      <g filter="url(#filter2_bi_3051_1389)">
        <path
          d="M52.8322 15.007H15.4776C9.36274 15.007 4.40564 19.9641 4.40564 26.079V63.4336C4.40564 69.5484 9.36274 74.5055 15.4776 74.5055H52.8322C58.9471 74.5055 63.9042 69.5484 63.9042 63.4336V26.079C63.9042 19.9641 58.9471 15.007 52.8322 15.007Z"
          fill="url(#paint5_linear_3051_1389)"
        />
        <path
          d="M52.8322 15.007H15.4776C9.36274 15.007 4.40564 19.9641 4.40564 26.079V63.4336C4.40564 69.5484 9.36274 74.5055 15.4776 74.5055H52.8322C58.9471 74.5055 63.9042 69.5484 63.9042 63.4336V26.079C63.9042 19.9641 58.9471 15.007 52.8322 15.007Z"
          fill="white"
          fillOpacity="0.5"
        />
        <path
          d="M15.4776 15.3325H52.8322C58.7673 15.3325 63.5787 20.1439 63.5787 26.079V63.4336C63.5787 69.3687 58.7673 74.1801 52.8322 74.1801H15.4776C9.54249 74.1801 4.73111 69.3687 4.73111 63.4336V26.079C4.73111 20.1439 9.5425 15.3325 15.4776 15.3325Z"
          stroke="url(#paint6_linear_3051_1389)"
          strokeOpacity="0.5"
          strokeWidth="0.65095"
        />
      </g>
      <g filter="url(#filter3_i_3051_1389)">
        <path
          d="M47.7747 34.7792C46.9065 33.9188 45.8715 33.2425 44.7323 32.7916C43.5931 32.3406 42.3736 32.1243 41.1478 32.1558C39.922 32.1873 38.7153 32.4659 37.6012 32.9747C36.487 33.4836 35.4885 34.2121 34.6662 35.116C33.8576 34.2263 32.878 33.5063 31.7851 32.9986C30.6922 32.4909 29.5081 32.2056 28.3025 32.1596C27.0969 32.1136 25.8942 32.3078 24.7653 32.7308C23.6365 33.1538 22.6042 33.797 21.7294 34.6225C20.8545 35.448 20.1548 36.4391 19.6714 37.5374C19.188 38.6358 18.9307 39.8191 18.9147 41.0178C18.8987 42.2164 19.1243 43.4062 19.5782 44.5169C20.0321 45.6276 20.7052 46.6368 21.5577 47.485L34.6662 60.5098L47.7747 47.485C49.4697 45.7997 50.4218 43.5147 50.4218 41.1321C50.4218 38.7496 49.4697 36.4645 47.7747 34.7792Z"
          fill="url(#paint7_linear_3051_1389)"
        />
      </g>
      <defs>
        <filter
          id="filter0_bi_3051_1389"
          x="-13.4366"
          y="-18.7723"
          width="93.429"
          height="53.3377"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="9.45235" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_3051_1389" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_3051_1389" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.15078" />
          <feGaussianBlur stdDeviation="6.30157" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3051_1389" />
        </filter>
        <filter
          id="filter1_bi_3051_1389"
          x="-12.5597"
          y="54.7152"
          width="93.429"
          height="53.3377"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="9.45235" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_3051_1389" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_3051_1389" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.15078" />
          <feGaussianBlur stdDeviation="6.30157" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3051_1389" />
        </filter>
        <filter
          id="filter2_bi_3051_1389"
          x="-14.4991"
          y="-3.89768"
          width="97.3079"
          height="97.3079"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="9.45235" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_3051_1389" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_3051_1389" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.15078" />
          <feGaussianBlur stdDeviation="6.30157" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3051_1389" />
        </filter>
        <filter
          id="filter3_i_3051_1389"
          x="18.9139"
          y="32.1528"
          width="31.5078"
          height="31.5078"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3.15078" />
          <feGaussianBlur stdDeviation="1.57539" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3051_1389" />
        </filter>
        <linearGradient
          id="paint0_linear_3051_1389"
          x1="5.46814"
          y1="0.132446"
          x2="43.6378"
          y2="34.8474"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9D9C9C" />
          <stop offset="0.375" stopColor="#C1C1C1" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3051_1389"
          x1="9.39426"
          y1="1.35372"
          x2="15.7544"
          y2="25.7116"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3051_1389"
          x1="6.34497"
          y1="89.1482"
          x2="44.5146"
          y2="54.4333"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9D9C9C" />
          <stop offset="0.375" stopColor="#C1C1C1" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3051_1389"
          x1="10.2711"
          y1="87.9269"
          x2="16.6312"
          y2="63.569"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3051_1389"
          x1="34.1549"
          y1="10.7571"
          x2="34.1549"
          y2="78.7554"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8FF00" />
          <stop offset="1" stopColor="#00FF57" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3051_1389"
          x1="4.40564"
          y1="15.007"
          x2="74.4931"
          y2="32.8035"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.41" />
          <stop offset="0.0001" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_3051_1389"
          x1="53.9853"
          y1="17.7579"
          x2="34.1549"
          y2="74.5055"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.423397" stopColor="white" stopOpacity="0.576603" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_3051_1389"
          x1="34.6162"
          y1="35.0685"
          x2="34.6679"
          y2="60.5098"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0.18" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
