type TKeys = 'one' | 'two' | 'five';
type TVariants = Record<TKeys, string>;

export const getDeclinationText = (number: number, variants: TVariants) => {
  let n = Math.abs(number);
  n %= 100;
  if (n >= 5 && n <= 20) {
    return variants.five;
  }
  n %= 10;
  if (n === 1) {
    return variants.one;
  }
  if (n >= 2 && n <= 4) {
    return variants.two;
  }
  return variants.five;
};
