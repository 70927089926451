import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyPaySubscribe = lazy(() => import('./PaySubscribe'));

const PaySubscribePage = () => (
  <Suspense fallback={<Loading />}>
    <LazyPaySubscribe />
  </Suspense>
);

export default PaySubscribePage;
