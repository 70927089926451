import { Navigate, Route, Routes } from 'react-router-dom';
import { PrivateRoute } from '../components';
import {
  AboutAppPage,
  CollectionsDetailsPage,
  CollectionsPage,
  CommentsPage,
  CourseDetailsPage,
  CourseVideosPage,
  CoursesPage,
  CreateActivityPage,
  EditPage,
  FavoritesPage,
  FeedbackMethodPage,
  FeedbackPage,
  LicenseAgreementPage,
  LoginRegisterPage,
  MainPage,
  ManageSubscribePage,
  MethodPaymentPage,
  NeedUpdatePage,
  NotificationsPage,
  OnboardingPage,
  OrderStatusPage,
  OrderStatusSitePage,
  PaySubscribePage,
  PendingOrderPage,
  PopularWorkoutsPage,
  PrivacyPolicyPage,
  ProcessingPersonalDataPage,
  ProfilePage,
  ReferralProgrammePage,
  SettingNotificationsPage,
  SettingsPage,
  SingleWorkoutsAllPage,
  SingleWorkoutsGroupsPage,
  StoriesPage,
  StorySizesPage,
  StoryWeightPage,
  TestPage,
  UserAgreementPage,
  VideoDetailsPage,
  VideoPlayerPage,
  OpenSupportPage,
} from '../pages';
import NotFoundPage from '../pages/NotFound';
import { Paths } from '../utils';

const {
  main,
  courses,
  courseVideos,
  singleWorkouts,
  popularWorkouts,
  video,
  profile,
  login,
  edit,
  favoritesAll,
  settings,
  videoDetails,
  storySizes,
  storyWeight,
  needUpdate,
  aboutPage,
  licenseAgreement,
  userAgreement,
  onboarding,
  privacyPolicy,
  orders,
  ordersSite,
  pendingOrder,
  paySubscribe,
  notifications,
  processingPersonalData,
  methodPayment,
  manageSubscribe,
  settingNotification,
  collections,
  feedbackMethod,
  feedback,
  referralProgramme,
  testPage,
  comments,
  createActivity,
  stories,
  openSupport,
} = Paths;

const Router = () => {
  return (
    <Routes>
      <Route path="/" element={<Navigate to={main} />} />
      <Route path={main} element={<PrivateRoute component={MainPage} />} />
      <Route path={`${main}${popularWorkouts}`} element={<PrivateRoute component={PopularWorkoutsPage} />} />
      <Route path={`${main}${popularWorkouts}/:id`} element={<PrivateRoute component={VideoDetailsPage} />} />
      {/* <Route path={`${main}${stories}/:id`} element={<PrivateRoute component={StoriesPage} />} /> */}
      <Route path={login} element={<LoginRegisterPage />} />
      <Route path={processingPersonalData} element={<ProcessingPersonalDataPage />} />
      <Route path={onboarding} element={<PrivateRoute component={OnboardingPage} />} />
      <Route path={courses} element={<PrivateRoute component={CoursesPage} />} />
      <Route path={paySubscribe} element={<PrivateRoute component={PaySubscribePage} />} />
      <Route path={`${courses}/:id`} element={<PrivateRoute component={CourseDetailsPage} />} />
      <Route path={`${courses}/:id${courseVideos}`} element={<PrivateRoute component={CourseVideosPage} />} />
      <Route path={`${courses}/:id${videoDetails}/:id`} element={<PrivateRoute component={VideoDetailsPage} />} />
      <Route path={`${profile}${videoDetails}/:id`} element={<PrivateRoute component={VideoDetailsPage} />} />
      <Route path={`${singleWorkouts}/:groupId/:id`} element={<PrivateRoute component={VideoDetailsPage} />} />
      <Route path={`${orders}/:id`} element={<OrderStatusPage />} />
      <Route path={`${ordersSite}/:id`} element={<OrderStatusSitePage />} />
      <Route path={`${pendingOrder}`} element={<PrivateRoute component={PendingOrderPage} />} />
      <Route path={singleWorkouts} element={<PrivateRoute component={SingleWorkoutsGroupsPage} />} />
      <Route path={`${singleWorkouts}/:groupId`} element={<PrivateRoute component={SingleWorkoutsAllPage} />} />
      <Route path={`${video}/:id/:playerType`} element={<PrivateRoute component={VideoPlayerPage} />} />
      <Route path={profile} element={<PrivateRoute component={ProfilePage} />} />
      <Route path={`${profile}${edit}`} element={<PrivateRoute component={EditPage} />} />
      <Route path={`${profile}${notifications}`} element={<PrivateRoute component={NotificationsPage} />} />
      <Route path={`${profile}${favoritesAll}/:courseType`} element={<PrivateRoute component={FavoritesPage} />} />
      <Route path={`${profile}${settings}`} element={<PrivateRoute component={SettingsPage} />} />
      <Route path={`${profile}${feedback}`} element={<PrivateRoute component={FeedbackPage} />} />
      <Route path={`${profile}${storySizes}`} element={<PrivateRoute component={StorySizesPage} />} />
      <Route path={`${profile}${storyWeight}`} element={<PrivateRoute component={StoryWeightPage} />} />
      <Route path={`${courses}/:id${comments}`} element={<PrivateRoute component={CommentsPage} />} />
      <Route path={needUpdate} element={<NeedUpdatePage />} />
      <Route path={`${profile}${settings}${aboutPage}`} element={<PrivateRoute component={AboutAppPage} />} />
      <Route path={`${profile}${settings}${methodPayment}`} element={<PrivateRoute component={MethodPaymentPage} />} />
      <Route
        path={`${profile}${settings}${settingNotification}`}
        element={<PrivateRoute component={SettingNotificationsPage} />}
      />
      <Route
        path={`${profile}${settings}${referralProgramme}`}
        element={<PrivateRoute component={ReferralProgrammePage} />}
      />
      <Route
        path={`${profile}${settings}${manageSubscribe}`}
        element={<PrivateRoute component={ManageSubscribePage} />}
      />
      <Route
        path={`${profile}${settings}${aboutPage}${licenseAgreement}`}
        element={<PrivateRoute component={LicenseAgreementPage} />}
      />
      <Route path={`${profile}${settings}${aboutPage}${privacyPolicy}`} element={<PrivacyPolicyPage />} />
      <Route path={`${profile}${settings}${aboutPage}${userAgreement}`} element={<UserAgreementPage />} />
      <Route
        path={`${profile}${createActivity}/:action/:date`}
        element={<PrivateRoute component={CreateActivityPage} />}
      />
      <Route path={`${collections}/:parentPage`} element={<PrivateRoute component={CollectionsPage} />} />
      <Route path={`${collections}/:parentPage/:id`} element={<PrivateRoute component={CollectionsDetailsPage} />} />
      <Route path={feedbackMethod} element={<PrivateRoute component={FeedbackMethodPage} />} />
      <Route path={openSupport} element={<OpenSupportPage />} />
      <Route path={testPage} element={<PrivateRoute component={TestPage} />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default Router;
