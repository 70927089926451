import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyOrderStatusSite = lazy(() => import('./OrderStatusSite'));

const OrderStatusSitePage = () => (
  <Suspense fallback={<Loading />}>
    <LazyOrderStatusSite />
  </Suspense>
);

export default OrderStatusSitePage;
