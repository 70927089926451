import React from 'react';

export const NotFoundCards = () => {
  return (
    <svg width="90" height="90" viewBox="0 0 90 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.9">
        <g clipPath="url(#clip0_1064_7357)">
          <rect
            x="1.40918"
            y="26.6716"
            width="78.4638"
            height="50.441"
            rx="9.60781"
            transform="rotate(-11.499 1.40918 26.6716)"
            fill="#1D1D1D"
            fillOpacity="0.3"
          />
          <rect
            x="-2.3269"
            y="36.4193"
            width="94.4768"
            height="10"
            transform="rotate(-11.499 -2.3269 36.4193)"
            fill="#1D1D1D"
          />
          <rect
            x="14"
            y="47.5591"
            width="63"
            height="6"
            rx="1"
            transform="rotate(-11.499 14 47.5591)"
            fill="white"
            fillOpacity="0.6"
          />
          <circle cx="72.546" cy="54.5104" r="4" transform="rotate(-11.499 72.546 54.5104)" fill="#696969" />
          <circle cx="77.4456" cy="53.5136" r="4" transform="rotate(-11.499 77.4456 53.5136)" fill="#E2E2E2" />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_1064_7357">
          <rect
            x="1.40918"
            y="26.6716"
            width="78.4638"
            height="50.441"
            rx="9.60781"
            transform="rotate(-11.499 1.40918 26.6716)"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
