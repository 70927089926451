import { Box, CircularProgress } from '@mui/material';
import React from 'react';
import { FC } from 'react';

const Loading: FC = () => (
  <Box
    sx={{
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      minHeight: '100%',
      background: '#f0f0f0',
      color: '#ADFA2E',
    }}
  >
    <CircularProgress color="inherit" />
  </Box>
);

export default Loading;
