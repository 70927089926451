import React, { FC, useEffect, useState } from 'react';
import { Container } from '@mui/material';
import s from './styles.module.scss';
import {
  Environment,
  ErrorMessages,
  getIsNeedOnboarding,
  getIsValue,
  getUserInfo,
  noticeCreator,
  Paths,
  setInitMetrika,
  SuccessMessages,
} from '../../utils';
import Init from './components/Init';
import LoginInput from './components/LoginInput';
import RegistrationCompleted from './components/RegistrationCompleted';
import EnterPassword from './components/EnterPassword';
import EnterMailRecovery from './components/EnterMailRecovery';
import SetRecoveryPassword from './components/SetRecoveryPassword';
import Confirmation from './components/Confirmation';
import SetPassword from './components/SetPassword';
import { useAppDispatch } from '../../hooks';
import { useLocation, useNavigate } from 'react-router-dom';
import api from '../../api';
import { getTextError } from '../../redux/utils';
import { createServiceNotice } from '../../redux/notifications/slice';
import { setSession } from '../../api/jwt';
import { getIsPhoneNumber } from './utils';
import { getNotificationsThunk } from '../../redux/notifications/thunks';
import qs from 'qs';
import { getIsVkAuthRes, IVkAuthRes, SocialAuthState } from './helpers';
import { useStep } from './hooks/useStep';
import SetUserAgreements from './components/SetUserAgreements';
import JoinToCommunity from './components/JoinToCommunity';
import { SplashScreen } from '../../components';
import { getCoursesThunk } from '../../redux/courses/thunks';
import { getRenderSchemesThunk } from '../../redux/renderSchemes/thunks';

enum LoginSubmitTypes {
  login = 'login',
  refresh = 'refresh',
}

enum Steps {
  init = 'init',
  joinToCommunity = 'join_to_community',
  setUserAgreements = 'set_user_agreements',
  loginInput = 'login_input',
  enterPassword = 'enter_password',
  enterMailRecovery = 'enter_mail_recovery',
  setPassword = 'set_password',
  setPasswordRecovery = 'set_password_recovery',
  confirmation = 'confirmation',
  registrationCompleted = 'registration_completed',
}

export const TEST_ID = 'LoginRegister';

export enum LoginTypes {
  email = 'email',
  phone = 'phone',
}

export enum LogicTypes {
  authorization = 'authorization',
  registration = 'registration',
  recovery = 'recovery',
}

const { onboarding, main } = Paths;

const LoginRegister: FC = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);

  const { currentStep, nextStepHandler, goBackHandler } = useStep(Steps.init);

  const [logicType, setLogicType] = useState(LogicTypes.authorization);

  const [loginName, setLoginName] = useState('');

  const [password, setPassword] = useState('');

  const [pin, setPin] = useState('');

  const [loginType, setLoginType] = useState(LoginTypes.email);

  const [socialAuthLoading, setSocialAuthLoading] = useState(false);

  const { search, pathname } = useLocation();

  const parsedSearch = qs.parse(search, { ignoreQueryPrefix: true });

  useEffect(() => {
    if ('state' in parsedSearch && parsedSearch.state === SocialAuthState.vk) {
      if ('payload' in parsedSearch && typeof parsedSearch.payload === 'string') {
        let vkAuthRes: IVkAuthRes;

        if (getIsVkAuthRes(JSON.parse(parsedSearch.payload))) {
          vkAuthRes = JSON.parse(parsedSearch.payload);

          (async () => {
            const vkAuthConfig = {
              silent_token: vkAuthRes.token,
              name: vkAuthRes.user.first_name,
              uuid: vkAuthRes.uuid,
              avatar: vkAuthRes.user.avatar,
            };

            try {
              setSocialAuthLoading(true);

              const { data: vkAuthResponse } = await api.loginVk(vkAuthConfig);

              await setSession({
                access_token: vkAuthResponse.access_token,
                refresh_token: vkAuthResponse.refresh_token,
              });

              if (vkAuthResponse.new === true) {
                nextStepHandler(Steps.registrationCompleted);
              } else {
                const user = await getUserInfo();

                await dispatch(getCoursesThunk({ trusted: user?.trusted ?? false }));

                await dispatch(getRenderSchemesThunk());

                if (user) setInitMetrika(user);

                const isNeedOnboarding = await getIsNeedOnboarding(user);

                if (isNeedOnboarding === true) {
                  navigate(onboarding);
                } else if (isNeedOnboarding === false) {
                  dispatch(getNotificationsThunk());

                  navigate(main);
                }
              }
            } catch (error) {
              dispatch(
                createServiceNotice({
                  notice: noticeCreator(ErrorMessages.socialAuth, 'error'),
                  otherInfo: {
                    error,
                    pathname: `${pathname}${search} loginVk`,
                    forEnvironment: Environment.production,
                  },
                })
              );
            } finally {
              setLoading(false);

              setSocialAuthLoading(false);
            }
          })();
        } else {
          dispatch(
            createServiceNotice({
              notice: noticeCreator(ErrorMessages.socialAuth, 'error'),
              otherInfo: {
                error: { errorText: 'vkAuthRes does not contain token or uuid or user', parsedSearch },
                pathname: `${pathname}${search} loginVk`,
                forEnvironment: Environment.production,
              },
            })
          );
        }
      } else {
        dispatch(
          createServiceNotice({
            notice: noticeCreator(ErrorMessages.socialAuth, 'error'),
            otherInfo: {
              error: {
                errorText: 'VK auth, parsedSearch does not contain payload or payload is not a string',
                parsedSearch,
              },
              pathname: `${pathname}${search} loginVk`,
              forEnvironment: Environment.production,
            },
          })
        );
      }

      return;
    }

    if ('state' in parsedSearch && parsedSearch.state === SocialAuthState.google && 'code' in parsedSearch) {
      (async () => {
        const googleAuthConfig = { code: typeof parsedSearch.code === 'string' ? parsedSearch.code : '' };

        try {
          setSocialAuthLoading(true);

          const { data: googleAuthResponse } = await api.loginGoogle(googleAuthConfig);

          await setSession({
            access_token: googleAuthResponse.access_token,
            refresh_token: googleAuthResponse.refresh_token,
          });

          if (googleAuthResponse.new === true) {
            nextStepHandler(Steps.registrationCompleted);
          } else {
            const user = await getUserInfo();

            await dispatch(getCoursesThunk({ trusted: user?.trusted ?? false }));

            await dispatch(getRenderSchemesThunk());

            if (user) setInitMetrika(user);

            const isNeedOnboarding = await getIsNeedOnboarding(user);

            if (isNeedOnboarding === true) {
              navigate(onboarding);
            } else if (isNeedOnboarding === false) {
              dispatch(getNotificationsThunk());

              navigate(main);
            }
          }
        } catch (error) {
          dispatch(
            createServiceNotice({
              notice: noticeCreator(ErrorMessages.socialAuth, 'error'),
              otherInfo: {
                error,
                pathname: `${pathname}${search} loginGoogle`,
                forEnvironment: Environment.production,
              },
            })
          );
        } finally {
          setLoading(false);

          setSocialAuthLoading(false);
        }
      })();

      return;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const enterPasswordSubmit = async () => {
    setLoading(true);

    try {
      const tokens = (await api.login({ email: loginName, password })).data;

      await setSession(tokens);

      const user = await getUserInfo();

      await dispatch(getCoursesThunk({ trusted: user?.trusted ?? false }));

      await dispatch(getRenderSchemesThunk());

      if (user) setInitMetrika(user);

      const isNeedOnboarding = await getIsNeedOnboarding(user);

      if (isNeedOnboarding === true) {
        navigate(onboarding);
      } else if (isNeedOnboarding === false) {
        dispatch(getNotificationsThunk());

        navigate(main);
      }
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: `${pathname}${search} login`, forEnvironment: Environment.production },
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const loginSubmit = (type: LoginSubmitTypes) => async () => {
    setLoading(true);

    if (loginType === LoginTypes.email) {
      try {
        const { exists } = (await api.register({ email: loginName })).data;

        if (exists === true && type === LoginSubmitTypes.login) {
          nextStepHandler(Steps.enterPassword);

          setLogicType(LogicTypes.authorization);

          return;
        }

        if (exists === false && type === LoginSubmitTypes.login) {
          nextStepHandler(Steps.confirmation);

          setLogicType(LogicTypes.registration);
        }
      } catch (error) {
        const textError = getTextError(error);

        dispatch(
          createServiceNotice({
            notice: noticeCreator(textError, 'error'),
            otherInfo: { error, pathname: `${pathname}${search} register`, forEnvironment: Environment.production },
          })
        );
      } finally {
        setLoading(false);
      }

      return;
    }

    if (loginType === LoginTypes.phone) {
      const phone = loginName.replace(/[^\d]/g, '');

      try {
        const { is_new: isPhoneNew } = (await api.loginByPhone({ phone })).data;

        if (isPhoneNew === true) {
          setLogicType(LogicTypes.registration);
        } else if (isPhoneNew === false) {
          setLogicType(LogicTypes.authorization);
        }

        nextStepHandler(Steps.confirmation);
      } catch (error) {
        const textError = getTextError(error);

        dispatch(
          createServiceNotice({
            notice: noticeCreator(textError, 'error'),
            otherInfo: {
              error,
              pathname: `${pathname}${search} loginByPhone`,
              forEnvironment: Environment.production,
            },
          })
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const confirmationSubmit = async (foundSmsCode?: string) => {
    if (foundSmsCode) setPin(foundSmsCode);

    setLoading(true);

    if (loginType === LoginTypes.email) {
      try {
        await api.emailConfirmationCheck({
          email: loginName,
          code: pin,
        });

        if (logicType === LogicTypes.recovery) {
          nextStepHandler(Steps.setPasswordRecovery);
        } else nextStepHandler(Steps.setPassword);
      } catch (error) {
        const textError = getTextError(error);

        dispatch(
          createServiceNotice({
            notice: noticeCreator(textError, 'error'),
            otherInfo: {
              error,
              pathname: `${pathname}${search} emailConfirmationCheck`,
              forEnvironment: Environment.production,
            },
          })
        );
      } finally {
        setLoading(false);
      }

      return;
    }

    if (loginType === LoginTypes.phone) {
      const phone = loginName.replace(/[^\d]/g, '');

      try {
        const tokens = (
          await api.phoneConfirmation({
            phone,
            code: foundSmsCode ?? pin,
          })
        ).data;

        await setSession(tokens);

        const user = await getUserInfo();

        await dispatch(getCoursesThunk({ trusted: user?.trusted ?? false }));

        await dispatch(getRenderSchemesThunk());

        if (user) setInitMetrika(user);

        const isNeedOnboarding = await getIsNeedOnboarding(user);

        if (logicType === LogicTypes.authorization) {
          if (isNeedOnboarding === true) {
            navigate(onboarding);
          } else if (isNeedOnboarding === false) {
            dispatch(getNotificationsThunk());

            navigate(main);
          }

          return;
        }

        if (logicType === LogicTypes.registration) {
          nextStepHandler(Steps.registrationCompleted);
        }
      } catch (error) {
        const textError = getTextError(error);

        dispatch(
          createServiceNotice({
            notice: noticeCreator(textError, 'error'),
            otherInfo: {
              error,
              pathname: `${pathname}${search} phoneConfirmation`,
              forEnvironment: Environment.production,
            },
          })
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const recoveryEnterMailSubmit = async () => {
    setLoading(true);

    try {
      const { exists } = (await api.register({ email: loginName })).data;

      if (exists === true) {
        await api.passwordRecovery({ email: loginName });

        nextStepHandler(Steps.confirmation);
      }

      if (exists === false) {
        dispatch(
          createServiceNotice({
            notice: noticeCreator('Пользователя не существует', 'error'),
            otherInfo: {
              pathname: `${pathname}/${search} register for passwordRecovery`,
              forEnvironment: Environment.production,
            },
          })
        );
      }
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: {
            error,
            pathname: `${pathname}${search} register | passwordRecovery`,
            forEnvironment: Environment.production,
          },
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const setRecoveryPasswordSubmit = async () => {
    setLoading(true);

    try {
      await api.emailConfirmation({
        code: pin,
        email: loginName,
        password,
      });

      dispatch(
        createServiceNotice({
          notice: noticeCreator(SuccessMessages.setRecoveryPassword, 'success', 1000),
          otherInfo: { pathname: `${pathname}${search}`, forEnvironment: Environment.production },
        })
      );

      nextStepHandler(Steps.loginInput);
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: {
            error,
            pathname: `${pathname}${search} emailConfirmation`,
            forEnvironment: Environment.production,
          },
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const setPasswordSubmit = async () => {
    setLoading(true);

    try {
      const tokens = (
        await api.emailConfirmation({
          code: pin,
          email: loginName,
          password,
        })
      ).data;

      await setSession(tokens);

      nextStepHandler(Steps.registrationCompleted);
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: {
            error,
            pathname: `${pathname}${search} emailConfirmation`,
            forEnvironment: Environment.production,
          },
        })
      );
    } finally {
      setLoading(false);
    }
  };

  const onRegistrationCompletedClickHandler = async () => {
    const user = await getUserInfo();

    await dispatch(getCoursesThunk({ trusted: user?.trusted ?? false }));

    await dispatch(getRenderSchemesThunk());

    navigate(onboarding);
  };

  const enterPasswordHandler = () => {
    setLogicType(LogicTypes.recovery);

    nextStepHandler(Steps.enterMailRecovery);
  };

  const setUserAgreementsHandler = () => {
    nextStepHandler(Steps.joinToCommunity);
  };

  useEffect(() => {
    const isNumber = getIsPhoneNumber(loginName);

    if (isNumber && loginType !== LoginTypes.phone) {
      setLoginType(LoginTypes.phone);
    } else if (loginName !== '' && loginType !== LoginTypes.email && !isNumber) {
      setLoginType(LoginTypes.email);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginName]);

  useEffect(() => {
    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentStep]);

  return (
    <>
      {!socialAuthLoading && (
        <Container disableGutters maxWidth="xs" className={s.loginRegister} data-testid={TEST_ID}>
          {getIsValue(currentStep, Steps.init) && <Init onClick={() => nextStepHandler(Steps.setUserAgreements)} />}

          {currentStep === Steps.setUserAgreements && (
            <SetUserAgreements onBackClick={goBackHandler} onClick={setUserAgreementsHandler} />
          )}

          {currentStep === Steps.joinToCommunity && (
            <JoinToCommunity onBackClick={goBackHandler} onClick={() => nextStepHandler(Steps.loginInput)} />
          )}

          {getIsValue(currentStep, Steps.loginInput) && (
            <LoginInput
              login={loginName}
              onSubmit={loginSubmit(LoginSubmitTypes.login)}
              onBackClick={goBackHandler}
              onChange={setLoginName}
              step="1/2"
              loading={loading}
            />
          )}

          {getIsValue(currentStep, Steps.enterMailRecovery) && (
            <EnterMailRecovery
              onSubmit={recoveryEnterMailSubmit}
              onBackClick={goBackHandler}
              onChange={setLoginName}
              step="1/3"
              email={loginName}
              loading={loading}
            />
          )}

          {getIsValue(currentStep, Steps.enterPassword) && (
            <EnterPassword
              loading={loading}
              password={password}
              onClick={enterPasswordHandler}
              onBackClick={goBackHandler}
              onChange={setPassword}
              onSubmit={enterPasswordSubmit}
              step="2/2"
            />
          )}

          {getIsValue(currentStep, Steps.confirmation) && (
            <Confirmation
              step={loginType === LoginTypes.phone ? '2/2' : '2/3'}
              loginType={loginType}
              loginName={loginName}
              value={pin}
              onChange={setPin}
              onBackClick={goBackHandler}
              onSubmit={confirmationSubmit}
              onRefresh={loginSubmit(LoginSubmitTypes.refresh)}
              loading={loading}
            />
          )}

          {getIsValue(currentStep, Steps.setPassword) && (
            <SetPassword
              loading={loading}
              step="3/3"
              onChange={setPassword}
              onSubmit={setPasswordSubmit}
              onBackClick={goBackHandler}
            />
          )}

          {getIsValue(currentStep, Steps.setPasswordRecovery) && (
            <SetRecoveryPassword
              loading={loading}
              step="3/3"
              onChange={setPassword}
              onSubmit={setRecoveryPasswordSubmit}
              onBackClick={goBackHandler}
            />
          )}

          {getIsValue(currentStep, Steps.registrationCompleted) && (
            <RegistrationCompleted onClick={onRegistrationCompletedClickHandler} />
          )}
        </Container>
      )}

      {socialAuthLoading && <SplashScreen />}
    </>
  );
};

export default LoginRegister;
