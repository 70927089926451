import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyPendingOrder = lazy(() => import('./PendingOrder'));

const PendingOrderPage = () => (
  <Suspense fallback={<Loading />}>
    <LazyPendingOrder />
  </Suspense>
);

export default PendingOrderPage;
