import React from 'react';
import { FC } from 'react';

const CompletedIcon: FC = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Status">
      <path
        id="i"
        d="M16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8Z"
        fill="#7FFF00"
      />
      <path
        id="i_2"
        d="M12.0712 4.90029C11.7317 4.61494 11.2156 4.64782 10.9184 4.97372L9.07345 6.99657L7.22854 9.01942L5.59801 7.31062C5.29324 6.99121 4.77645 6.96947 4.44373 7.26205C4.11101 7.55464 4.08837 8.05077 4.39314 8.37018L6.33176 10.4019C6.78893 10.881 7.56412 10.9136 8.06319 10.4747C8.08763 10.4524 8.09985 10.4413 8.11163 10.4297C8.12341 10.4181 8.13475 10.4062 8.15743 10.3822L12.1477 6.00704C12.4449 5.68114 12.4107 5.18563 12.0712 4.90029Z"
        fill="#1D1D1D"
      />
    </g>
  </svg>
);

export default CompletedIcon;
