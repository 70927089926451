import { PayloadAction, createSlice, isPending, isRejectedWithValue } from '@reduxjs/toolkit';
import { getStatusOrderThunk, getProductsThunk, getCardListThunk, getProductThunk } from './thunks';
import { ICards, IOrderPayment, IProducts } from '../../api/types';
import { IStoreBase } from '../../models';
import { Statuses } from '../../utils';

interface IOrder {
  products: IStoreBase<IProducts[] | null>;
  product: IStoreBase<IProducts | null>;
  order: IStoreBase<IOrderPayment | null>;
  cards: IStoreBase<ICards[] | null>;
}

const initialState: IOrder = {
  products: { data: null, error: '', statuse: Statuses.idle },
  product: { data: null, error: '', statuse: Statuses.idle },
  order: { data: null, error: '', statuse: Statuses.idle },
  cards: { data: null, error: '', statuse: Statuses.idle },
};

export const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    removeCard(state, { payload }: PayloadAction<number>) {
      if (!state.cards.data?.length) return;

      const newCards = state.cards.data.filter(({ id }) => id !== payload);

      state.cards.data = newCards;
    },
    setCard(state, { payload }: PayloadAction<ICards[]>) {
      state.cards.data = payload;
    },

    clearProduct(state) {
      state.product.data = null;
    },

    setProductStatusIdle(state) {
      state.product.statuse = Statuses.idle;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCardListThunk.fulfilled, (state, { payload }: PayloadAction<ICards[]>) => {
        state.cards.error = '';
        state.cards.statuse = Statuses.succeeded;
        state.cards.data = payload;
      })
      .addCase(getStatusOrderThunk.fulfilled, (state, { payload }: PayloadAction<IOrderPayment>) => {
        state.order.error = '';
        state.order.statuse = Statuses.succeeded;
        state.order.data = payload;
      })
      .addCase(getProductsThunk.fulfilled, (state, { payload }: PayloadAction<IProducts[]>) => {
        state.products.error = '';
        state.products.statuse = Statuses.succeeded;
        state.products.data = payload;
      })
      .addCase(getProductThunk.fulfilled, (state, { payload }: PayloadAction<IProducts>) => {
        state.product.error = '';
        state.product.statuse = Statuses.succeeded;
        state.product.data = payload;
      })
      //getStatusOrderThunk
      .addMatcher(isPending(getStatusOrderThunk), (state) => {
        state.order.error = '';
        state.order.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getStatusOrderThunk), (state, { payload }) => {
        state.order.statuse = Statuses.failed;
        state.order.error = typeof payload === 'string' ? payload : '';
      })
      //getCardListThunk
      .addMatcher(isPending(getCardListThunk), (state) => {
        state.cards.error = '';
        state.cards.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getCardListThunk), (state, { payload }) => {
        state.cards.statuse = Statuses.failed;
        state.cards.error = typeof payload === 'string' ? payload : '';
      })
      //getProductsThunk
      .addMatcher(isPending(getProductsThunk), (state) => {
        state.products.error = '';
        state.products.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getProductsThunk), (state, { payload }) => {
        state.products.statuse = Statuses.failed;
        state.products.error = typeof payload === 'string' ? payload : '';
      })
      //getProductThunk
      .addMatcher(isPending(getProductThunk), (state) => {
        state.product.error = '';
        state.product.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getProductThunk), (state, { payload }) => {
        state.product.statuse = Statuses.failed;
        state.product.error = typeof payload === 'string' ? payload : '';
      });
  },
});

export const { removeCard, setCard, clearProduct, setProductStatusIdle } = orderSlice.actions;

export default orderSlice.reducer;
