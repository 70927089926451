import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyNotFound = lazy(() => import('./NotFound'));

const NotFoundPage = () => (
  <Suspense fallback={<Loading />}>
    <LazyNotFound />
  </Suspense>
);

export default NotFoundPage;
