import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyUserAgreement = lazy(() => import('./UserAgreement'));

const UserAgreementPage = () => (
  <Suspense fallback={<Loading />}>
    <LazyUserAgreement />
  </Suspense>
);

export default UserAgreementPage;
