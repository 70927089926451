import React, { FC, ReactNode, memo, useCallback, useState } from 'react';
import { Button, Typography } from 'front-package-ui-kit';
import s from './styles.module.scss';
import { BottomButtonBlock, BottomSheetHeader, BottomSheetUI, SwiperPicker } from '../..';
import { useBottomSheet } from '../../../hooks';
import { TypesLeftRightPicker } from '../../../models';

function calculateHoursAndMinutes(totalMinutes: number) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return { hours, minutes };
}

const HOURS = [...Array(13).keys()];

const MINUTES = [...Array(60).keys()];

export const TEST_ID = 'SetTimeBottomSheet';

export const openSetTimeBottomSheet = 'openSetTimeBottomSheet';

export interface SetTimeBottomSheetProps {
  title?: ReactNode;
  onChange?: () => void;
  onApplyClick?: (value: number) => void;
  defaultValue?: number | null;
}

const SetTimeBottomSheet: FC<SetTimeBottomSheetProps> = ({ onApplyClick, defaultValue, title }) => {
  const [currentValue, setCurrentValue] = useState<TypesLeftRightPicker | null>(null);

  const { onCloseBottomSheet, getIsOpenBottomSheet } = useBottomSheet();

  const isOpen = getIsOpenBottomSheet(openSetTimeBottomSheet);

  const handleClose = useCallback(() => onCloseBottomSheet(), [onCloseBottomSheet]);

  const handleApplyClick = useCallback(() => {
    onCloseBottomSheet();

    if (currentValue === null || !onApplyClick) return;
    onApplyClick(
      typeof currentValue.left === 'number' && typeof currentValue.right === 'number'
        ? currentValue.left * 60 + currentValue.right
        : 0
    );
  }, [currentValue, onApplyClick, onCloseBottomSheet]);

  const { hours: defaultHours, minutes: defaultMinutes } = calculateHoursAndMinutes(defaultValue ?? 0);

  return (
    <BottomSheetUI
      open={isOpen}
      touchLine
      height="auto"
      onDismiss={handleClose}
      header={<BottomSheetHeader title={title} onClose={handleClose} />}
      footer={
        <BottomButtonBlock direction="column" rowGap="8px">
          <Button type="button" size="L" color="black" onClick={handleApplyClick} variant="contained" fullWidth>
            <Typography variant="text6" fontWeight={700} color="white">
              Добавить
            </Typography>
          </Button>

          <Button type="button" size="L" color="black" onClick={handleClose} variant="text" fullWidth>
            <Typography variant="text6" fontWeight={700}>
              Назад
            </Typography>
          </Button>
        </BottomButtonBlock>
      }
    >
      <SwiperPicker
        onChange={(data: TypesLeftRightPicker) => {
          setCurrentValue(data);
        }}
        leftPicker={{
          options: HOURS,
          defaultValue: currentValue?.left ?? defaultHours,
          unit: 'ч',
        }}
        rightPicker={{
          options: MINUTES,
          defaultValue: currentValue?.right ?? (defaultMinutes || 10),
          unit: 'мин',
        }}
      />
    </BottomSheetUI>
  );
};

export const SetTimeBottomSheetMemo = memo(SetTimeBottomSheet);
