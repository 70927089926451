import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const useOpenModal = (
  defaultValue: boolean = false
): [boolean, (state?: any) => void, (state?: any) => void, any, (state: any) => void] => {
  const [modalState, setModalState] = useState(null);

  const { pathname } = useLocation();

  const [openModal, setOpenModal] = useState(defaultValue);

  const onCloseModal = (state?: any) => {
    setModalState(state);

    setOpenModal(false);
  };

  const onOpenModal = (state?: any) => {
    setModalState(state);

    setOpenModal(true);
  };

  useEffect(() => {
    if (!openModal) return;

    setOpenModal(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  return [openModal, onOpenModal, onCloseModal, modalState, setModalState];
};

export default useOpenModal;
