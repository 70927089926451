import { useState } from 'react';
import { SpringEvent, Statuses } from '../components/Drawer';

export const useLoadingBottomSheet = () => {
  const [isLoading, setIsLoading] = useState(true);

  const setLoadingHandler = ({ type }: SpringEvent) => {
    if (type === Statuses.CLOSING || type === Statuses.CLOSED) {
      setIsLoading(true);
    }

    if (type === Statuses.OPEN) {
      setIsLoading(true);

      const timer = setTimeout(() => {
        setIsLoading(false);
      }, 1000);

      return () => {
        clearTimeout(timer);
      };
    }
  };
  return { isLoading, setLoadingHandler };
};
