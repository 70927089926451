import { Stack } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import s from './styles.module.scss';
import { CheckBoxIconOnboarding } from '../../../../assets/icons';
import cn from 'classnames';
import { AnalyticsName, PARAMETERS_TYPES } from '../../../../utils/constants/config';
import { useAppDispatch, useGroups, useLastParameters } from '../../../../hooks';
import { setParametersThunk } from '../../../../redux/parameters/thunks';
import { upperCaseFirst } from '../../../../utils';
import { format } from 'date-fns';
import { Typography } from 'front-package-ui-kit';
import { ButtonNext } from '..';
import { getGroupThunk } from '../../../../redux/groups/thunk';

interface IProps {
  handleStep: () => void;
  stepNumber: number;
  numberOfSteps: number;
}

const ChooseTarget: FC<IProps> = ({ handleStep, stepNumber, numberOfSteps }) => {
  const dispatch = useAppDispatch();

  const [goals] = useLastParameters([PARAMETERS_TYPES.goal.id]);

  const [checkedValues, setCheckedValues] = useState<string[]>(goals ? goals?.value.split(', ') : []);

  const {
    groupsByType: { target: goalsGroup },
  } = useGroups();

  const targetOptions = goalsGroup.map(({ name }) => name);

  const handleSendTargetsValues = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    await dispatch(
      setParametersThunk({
        date: format(new Date(), 'yyyy-MM-dd'),
        parameter_id: PARAMETERS_TYPES.goal.id,
        value: checkedValues.join(', '),
      })
    );

    handleStep();
  };

  const onChangeHandler = (value: string) => () => {
    if (checkedValues.includes(value)) {
      setCheckedValues((prev) => prev.filter((elem) => elem !== value));
    } else setCheckedValues((prev) => [...prev, value]);
  };

  const isDisabled = !Boolean(checkedValues.length);

  useEffect(() => {
    dispatch(getGroupThunk());
  }, [dispatch]);

  return (
    <Stack component="form" onSubmit={handleSendTargetsValues} className={s.containerChooseTarget}>
      <Stack marginBottom="24px" padding="0 8px">
        <Typography variant="h3" color="white" fontWeight={700} marginBottom="8px">
          Выберите цель
        </Typography>

        <Typography variant="text6" color="white" fontWeight={400} opacity={0.6}>
          Поддержим вас на пути к результату!
        </Typography>
      </Stack>

      <div className={s.formGroup}>
        {targetOptions.map((target) => (
          <button
            key={target}
            className={cn(s.btn, {
              [s.checked]: checkedValues.includes(target),
              [s.unchecked]: !checkedValues.includes(target),
            })}
            type="button"
            onClick={onChangeHandler(target)}
          >
            <Typography variant="text4" color="white" fontWeight={500}>
              {upperCaseFirst(target)}
            </Typography>

            {checkedValues.includes(target) && <CheckBoxIconOnboarding type="checked" />}

            {!checkedValues.includes(target) && <CheckBoxIconOnboarding type="unchecked" />}
          </button>
        ))}
      </div>

      <ButtonNext
        disabled={isDisabled}
        stepNumber={stepNumber}
        numberOfSteps={numberOfSteps}
        analyticInfo={{ name: AnalyticsName.onboardingChooseTargetNext }}
      />
    </Stack>
  );
};

export default ChooseTarget;
