import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyFeedbackMethod = lazy(() => import('./FeedbackMethod'));

const FeedbackMethodPage = () => (
  <Suspense fallback={<Loading />}>
    <LazyFeedbackMethod />
  </Suspense>
);

export default FeedbackMethodPage;
