import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyStoryWeight = lazy(() => import('./StoryWeight'));

const StoryWeightPage = () => (
  <Suspense fallback={<Loading />}>
    <LazyStoryWeight />
  </Suspense>
);

export default StoryWeightPage;
