export const getRangeValues = (min: number, max: number) => new Array(max - min + 1).fill(null).map((_, i) => i + min);

const getRangeValuesGramm = (max: number) => new Array(max).fill(null).map((_, i) => i * 100);

export const sizesSwiperValuesCm = getRangeValues(30, 200);

export const sizesSwiperValuesMm = getRangeValues(0, 9);

export const sizesSwiperValuesKg = getRangeValues(30, 300);

export const sizesSwiperValuesGramm = getRangeValuesGramm(10);

export const months = [
  'январь',
  'февраль',
  'март',
  'апрель',
  'май',
  'июнь',
  'июль',
  'август',
  'сентябрь',
  'октябрь',
  'ноябрь',
  'декабрь',
];

export const years = getRangeValues(new Date(1920, 0, 1).getFullYear(), new Date().getFullYear());
