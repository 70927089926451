import * as yup from 'yup';

const regex = /^(?! )(?!.* $)(?!(?:.* ){2}).+$/;

export const validationSchema = yup.object().shape({
  sex: yup.number().nullable(),
  firstName: yup
    .string()
    .nullable()
    .min(2, 'Имя должно содержать не менее 3 символов')
    .matches(regex, 'Введите имя без пробелов и символов'),
});

export enum InputNames {
  sex = 'sex',
  firstName = 'firstName',
}

export default validationSchema;
