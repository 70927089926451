import React from 'react';
import { FC } from 'react';

const FilterIcon: FC = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.5 7.1998C9.08191 7.1998 7.87927 6.27738 7.45935 4.9998H1C0.447715 4.9998 0 4.55209 0 3.9998C0 3.44752 0.447715 2.9998 1 2.9998H7.45935C7.87927 1.72223 9.08191 0.799805 10.5 0.799805C11.9181 0.799805 13.1207 1.72223 13.5406 2.9998H15C15.5523 2.9998 16 3.44752 16 3.9998C16 4.55209 15.5523 4.9998 15 4.9998H13.5406C13.1207 6.27738 11.9181 7.1998 10.5 7.1998ZM1 10.9998C0.447715 10.9998 0 11.4475 0 11.9998C0 12.5521 0.447715 12.9998 1 12.9998H2.45935C2.87927 14.2774 4.08191 15.1998 5.5 15.1998C6.91809 15.1998 8.12073 14.2774 8.54065 12.9998H15C15.5523 12.9998 16 12.5521 16 11.9998C16 11.4475 15.5523 10.9998 15 10.9998H8.54065C8.12073 9.72223 6.91809 8.7998 5.5 8.7998C4.08191 8.7998 2.87927 9.72223 2.45935 10.9998H1ZM6.9 11.9998C6.9 12.773 6.2732 13.3998 5.5 13.3998C4.7268 13.3998 4.1 12.773 4.1 11.9998C4.1 11.2266 4.7268 10.5998 5.5 10.5998C6.2732 10.5998 6.9 11.2266 6.9 11.9998ZM11.9 3.9998C11.9 3.22661 11.2732 2.5998 10.5 2.5998C9.7268 2.5998 9.1 3.22661 9.1 3.9998C9.1 4.773 9.7268 5.3998 10.5 5.3998C11.2732 5.3998 11.9 4.773 11.9 3.9998Z"
        fill="#1D1D1D"
      />
    </svg>
  );
};

export default FilterIcon;
