import React, { createContext, FC, ReactNode, useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';

interface IModalContext {
  getIsOpenBottomSheet: (currentBottomSheetName: string) => boolean;
  onCloseBottomSheet: (clearState?: boolean) => void;
  onOpenBottomSheet: (bottomSheetName: string, state?: any) => void;
  bottomSheetState: any;
  setBottomSheetState: (state: any) => void;
}

const ModalContext = createContext<IModalContext | null>(null);

interface IProps {
  children: ReactNode;
}

const ModalProvider: FC<IProps> = ({ children }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [bottomSheetState, setBottomSheetState] = useState<unknown>(null);

  const getIsOpenBottomSheet = (bottomSheetName: string) => {
    return searchParams.get('currentBottomSheetName') === bottomSheetName;
  };

  const onOpenBottomSheet = (bottomSheetName: string, state: any) => {
    if (searchParams.get('currentBottomSheetName')) {
      console.error(
        `Действие не выполнено! BottomSheet уже открыт!
        Если необходимо изменить содержимое bottomSheetState в открытом BottomSheet, воспользуйтесь функцией setBottomSheetState.
        Либо закройте BottomSheet перед повторным открытием!`
      );

      return;
    }

    setBottomSheetState(state);

    setSearchParams((prev) => {
      const prevParams = [...prev.entries()].reduce((acc, [key, val]) => {
        acc[key] = val;

        return acc;
      }, {} as { [key: string]: string });

      return { ...prevParams, currentBottomSheetName: bottomSheetName };
    });
  };

  const onCloseBottomSheet = (clearState: boolean = false) => {
    if (searchParams.get('currentBottomSheetName')) {
      window.history.back();

      if (clearState) setBottomSheetState(null);
    }
  };

  const { pathname } = useLocation();

  useEffect(() => {
    setBottomSheetState(null);
  }, [pathname]);

  return (
    <ModalContext.Provider
      value={{
        getIsOpenBottomSheet,
        onCloseBottomSheet,
        onOpenBottomSheet,
        bottomSheetState,
        setBottomSheetState,
      }}
    >
      {children}
    </ModalContext.Provider>
  );
};

export { ModalContext, ModalProvider };
