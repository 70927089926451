import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyReferralProgramme = lazy(() => import('./ReferralProgramme'));

const ReferralProgrammePage = () => (
  <Suspense fallback={<Loading />}>
    <LazyReferralProgramme />
  </Suspense>
);

export default ReferralProgrammePage;
