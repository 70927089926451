import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyStorySizes = lazy(() => import('./StorySizes'));

const StorySizesPage = () => (
  <Suspense fallback={<Loading />}>
    <LazyStorySizes />
  </Suspense>
);

export default StorySizesPage;
