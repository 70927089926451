import { createSlice, isPending, isRejectedWithValue, PayloadAction } from '@reduxjs/toolkit';
import { IUser } from '../../api/types';
import {
  getUserInfoThunk,
  getUserAvatarThunk,
  updateUserInfoThunk,
  updatePushNotifyThunk,
  updateHideStarsThunk,
} from './thunks';
import { IStoreBase } from '../../models';
import { Statuses } from '../../utils';

interface IUserStore {
  user: IStoreBase<IUser | null>;
  userAvatar: string | null;
  isAgreement: boolean | null;
}

const initialState: IUserStore = {
  user: { data: null, error: '', statuse: Statuses.idle },
  userAvatar: null,
  isAgreement: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state, { payload }: PayloadAction<IUser>) {
      state.user.data = payload;
    },
    setAgreement(state, { payload }: PayloadAction<boolean>) {
      state.isAgreement = payload;
    },
    updateUserInfo(state, { payload }: PayloadAction<IUser>) {
      // state.error = '';
      // state.isLoading = false;
      state.user.data = payload;
    },
    updateHideStars(state, { payload }: PayloadAction<IUser['hide_stars']>) {
      if (state.user.data) state.user.data.hide_stars = payload;
    },
    removeUserAvatar(state) {
      state.userAvatar = null;
    },
    updateOnboardStep(state, { payload }: PayloadAction<number>) {
      if (state.user.data) state.user.data.onboard_step = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserInfoThunk.fulfilled, (state, { payload }: PayloadAction<IUser>) => {
        state.user.error = '';
        state.user.statuse = Statuses.succeeded;
        state.user.data = payload;
      })
      .addCase(getUserAvatarThunk.fulfilled, (state, { payload }: PayloadAction<string>) => {
        // state.error = '';
        // state.isLoading = false;
        state.userAvatar = payload;
      })
      .addCase(updateUserInfoThunk.fulfilled, (state, { payload }: PayloadAction<IUser>) => {
        // state.error = '';
        // state.isLoading = false;
        state.user.data = payload;
      })
      .addCase(updatePushNotifyThunk.fulfilled, (state, { payload }: PayloadAction<boolean>) => {
        // state.error = '';
        // state.isLoading = false;
        if (state.user.data) state.user.data.push_notify = payload;
      })
      .addCase(updateHideStarsThunk.fulfilled, (state, { payload }: PayloadAction<IUser['hide_stars']>) => {
        // state.error = '';
        // state.isLoading = false;
        if (state.user.data) state.user.data.hide_stars = payload;
      })
      //getUserInfoThunk
      .addMatcher(isPending(getUserInfoThunk), (state) => {
        state.user.error = '';

        state.user.statuse = Statuses.loading;
      })
      .addMatcher(isRejectedWithValue(getUserInfoThunk), (state, { payload }) => {
        state.user.statuse = Statuses.failed;
        state.user.error = typeof payload === 'string' ? payload : '';
      });
  },
});

export const { setUser, setAgreement, updateUserInfo, removeUserAvatar, updateHideStars, updateOnboardStep } =
  userSlice.actions;

export default userSlice.reducer;
