import React, { FC, useState } from 'react';
import s from './styles.module.scss';
import cn from 'classnames';
import { CardMedia } from '@mui/material';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { setImage } from '../../../redux/images/slice';
import ApiClient from '../../../api/ApiClient';
import { getImagePath } from '../../../utils';
import { IImagePathProps } from '../../../models';

export const TEST_ID = 'image-cover';

interface IProps {
  img: string | IImagePathProps | undefined;
  defaultImg?: string;
  maxZIndexPreloader?: boolean;
  fit?: 'cover' | 'contain' | 'fill' | 'none' | 'scale-down';
  loading?: 'eager' | 'lazy' | undefined;
}

const ImageCover: FC<IProps> = ({ img, defaultImg, maxZIndexPreloader, fit = 'cover', loading = 'lazy' }) => {
  const {
    imageStore: { images },
  } = useAppSelector((state) => state);

  const nameForStore = typeof img !== 'string' && img ? `${img.name}/${img.width}/${img.height}` : '';

  const [loadingError, setLoadingError] = useState(false);

  const [isImgUploaded, setIsImgUploaded] = useState(false);

  const isDefaultImg = Boolean(defaultImg);

  const isImg = Boolean(img);

  const preloadImg =
    typeof img !== 'string' && img && img.name && !loadingError && img.name !== ''
      ? getImagePath({ ...img, width: Math.round(img.width / 3), height: Math.round(img.height / 3) })
      : null;

  const savedImg = typeof img !== 'string' ? images[nameForStore] : null;

  const imageForCover = typeof img === 'string' ? img : savedImg;

  const dispatch = useAppDispatch();

  const onLoadedHandler = async () => {
    setIsImgUploaded(true);

    if (typeof img !== 'string' && img && img.name !== '') {
      const imgUrl = getImagePath(img);

      if (!images[nameForStore]) {
        try {
          const res = await ApiClient.get<Blob>(imgUrl, {
            responseType: 'blob',
          });

          if (res.data) {
            const imageUrl = URL.createObjectURL(res.data);

            dispatch(setImage({ name: nameForStore, imageUrl }));
          }
        } catch (error) {
          console.error(error);
        }
      }
    }
  };

  const onErrorHandler = () => {
    setLoadingError(true);
  };

  return (
    <>
      {isImg && !loadingError && (
        <div className={cn(s.imageCover)}>
          <CardMedia
            data-testid={TEST_ID}
            onLoad={onLoadedHandler}
            onError={onErrorHandler}
            loading={loading}
            className={s.img}
            style={{ objectFit: fit }}
            component="img"
            image={imageForCover ?? preloadImg ?? defaultImg}
            alt=""
          />
        </div>
      )}

      {!isImgUploaded && isDefaultImg && (
        <div
          className={cn(s.imageCover, {
            [s.preloadImg]: maxZIndexPreloader && typeof img !== 'string' && img?.name,
          })}
        >
          {<CardMedia className={s.img} component="img" image={defaultImg} style={{ objectFit: fit }} alt="" />}
        </div>
      )}
    </>
  );
};

export default ImageCover;
