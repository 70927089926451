import { Box, Stack } from '@mui/material';
import React, { FC } from 'react';
import { BottomButtonBlock, BottomSheetHeader, BottomSheetUI } from '../..';
import { useBottomSheet } from '../../../hooks';
import { LockIcon } from '../../../assets/icons';
import { Link } from 'react-router-dom';
import s from './styles.module.scss';
import { Paths } from '../../../utils';
import { Button, Typography } from 'front-package-ui-kit';

const { paySubscribe } = Paths;

export const TEST_ID = 'CourseVideosBottomSheet';

export const openPaySubscribeBottomSheet = 'openPaySubscribeBottomSheet';

interface IProps {}

const PaySubscribeBottomSheet: FC<IProps> = () => {
  const { onCloseBottomSheet, getIsOpenBottomSheet } = useBottomSheet();

  const isOpen = getIsOpenBottomSheet(openPaySubscribeBottomSheet);

  return (
    <BottomSheetUI
      open={isOpen}
      touchLine
      height="auto"
      onDismiss={() => onCloseBottomSheet()}
      header={<BottomSheetHeader onClose={() => onCloseBottomSheet()} />}
      footer={
        <BottomButtonBlock>
          <Link className={s.link} to={paySubscribe}>
            <Button size="L" color="black" variant="contained" fullWidth>
              <Typography variant="text6" fontWeight={700} color="white">
                Купить подписку
              </Typography>
            </Button>
          </Link>
        </BottomButtonBlock>
      }
    >
      <Stack alignItems="center" justifyContent="center">
        <Box marginBottom="20px">
          <LockIcon type={2} />
        </Box>

        <Typography variant="text3" fontWeight={700} textAlign="center" marginBottom="20px">
          Тренировки станут доступны <br />
          сразу после покупки
        </Typography>
      </Stack>
    </BottomSheetUI>
  );
};

export default PaySubscribeBottomSheet;
