import { UIEvent, useCallback } from 'react';
import { useAppDispatch, useDebounce } from '.';
import { setScrollPosition } from '../redux/scrollSave/slice';

export const useScrollSave = (pathname: string): [(...args: any[]) => void] => {
  const dispatch = useAppDispatch();

  const handleSetScrollWithDebounce = useDebounce((scrollTop: number) => {
    dispatch(
      setScrollPosition({
        position: scrollTop,
        path: pathname,
      })
    );
  }, 300);

  const onScroll = useCallback(
    (e: UIEvent<HTMLDivElement>) => {
      const scrollTop = e.currentTarget.scrollTop;

      handleSetScrollWithDebounce(scrollTop);
    },
    [handleSetScrollWithDebounce]
  );

  return [onScroll];
};
