import { Box, Stack, StackProps } from '@mui/material';
import React, { FC, ReactNode } from 'react';
import { GoBackButton } from '../..';
import { IGoBackButtonProps } from '../../molecules/GoBackButton';
import cn from 'classnames';
import s from './styles.module.scss';

interface IProps extends StackProps {
  goBackButton?: boolean;
  children?: ReactNode;
  goBackButtonProps?: IGoBackButtonProps;
  onBackClick?: () => void;
  rightAction?: ReactNode;
  center?: boolean;
}

const NavBar: FC<IProps> = ({
  goBackButton,
  children,
  goBackButtonProps,
  onBackClick,
  center,
  rightAction,
  ...props
}) => {
  const classes = cn(s.navBar, {
    [s.navBarWithGoBack]: goBackButton,
    [s.navBarWithRightAction]: rightAction,
    [s.center]: center && (goBackButton || rightAction),
  });

  return (
    <Stack component="nav" className={classes} direction="row" alignItems="center" {...props}>
      {goBackButton && (
        <div className={s.goBackBtn}>
          <GoBackButton onClick={onBackClick} {...goBackButtonProps} />
        </div>
      )}

      {children}

      {rightAction && <Box className={s.rightAction}>{rightAction}</Box>}
    </Stack>
  );
};

export default NavBar;
