import React, { FC } from 'react';
import { getIsValue } from '../../utils';
interface IProps {
  type: 1 | 2;
}

const PendingIcon: FC<IProps> = ({ type }) => {
  return (
    <>
      {getIsValue(type, 1) && (
        <svg width="83" height="82" viewBox="0 0 83 82" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M76.0832 41C76.0832 59.8698 60.5997 75.1667 41.4998 75.1667C22.4 75.1667 6.9165 59.8698 6.9165 41C6.9165 22.1303 22.4 6.83337 41.4998 6.83337C60.5997 6.83337 76.0832 22.1303 76.0832 41ZM60.2407 52.3576C60.9935 50.6234 60.1808 48.6146 58.4255 47.8708L44.9582 42.1644V22.2084C44.9582 20.3214 43.4098 18.7917 41.4998 18.7917C39.5899 18.7917 38.0415 20.3214 38.0415 22.2084V43.2906C38.0415 45.3402 39.2776 47.1927 41.1843 48.0006L55.6992 54.1509C57.4545 54.8947 59.4878 54.0918 60.2407 52.3576Z"
            fill="#1D1D1D"
            fillOpacity="0.6"
          />
        </svg>
      )}

      {getIsValue(type, 2) && (
        <svg width="83" height="82" viewBox="0 0 83 82" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M76.0832 41C76.0832 59.8698 60.5997 75.1667 41.4998 75.1667C22.4 75.1667 6.9165 59.8698 6.9165 41C6.9165 22.1303 22.4 6.83337 41.4998 6.83337C60.5997 6.83337 76.0832 22.1303 76.0832 41ZM60.2407 52.3576C60.9935 50.6234 60.1808 48.6146 58.4255 47.8708L44.9582 42.1644V22.2084C44.9582 20.3214 43.4098 18.7917 41.4998 18.7917C39.5899 18.7917 38.0415 20.3214 38.0415 22.2084V43.2906C38.0415 45.3402 39.2776 47.1927 41.1843 48.0006L55.6992 54.1509C57.4545 54.8947 59.4878 54.0918 60.2407 52.3576Z"
            fill="#ADFA2E"
          />
        </svg>
      )}
    </>
  );
};

export default PendingIcon;
