import React, { FC, ReactNode } from 'react';
import { Stack } from '@mui/material';
import s from './styles.module.scss';
import { Typography } from 'front-package-ui-kit';
import VisaIcon from './icons/VisaIcon';
import MasterCardIcon from './icons/MasterCardIcon';
import MirIcon from './icons/MirIcon';
import MaestroIcon from './icons/MaestroIcon';
import { TypesCardValues } from '../../../utils/constants/config';
import { getIsValue } from '../../../utils';

interface IProps {
  typeCard?: TypesCardValues | null;
  rightIcon?: ReactNode;
  text: ReactNode;
  name: string;
  onClick: () => void;
}

const Card: FC<IProps> = ({ rightIcon, text, typeCard, name, onClick }) => {
  return (
    <Stack className={s.cardContainer}>
      <Stack direction="row" justifyContent="space-between">
        <Stack direction="row" alignItems="center" columnGap="8px">
          {text}

          {getIsValue(TypesCardValues.visa, typeCard) && <VisaIcon />}
          {getIsValue(TypesCardValues.masterCard, typeCard) && <MasterCardIcon />}
          {getIsValue(TypesCardValues.mir, typeCard) && <MirIcon />}
          {getIsValue(TypesCardValues.maestro, typeCard) && <MaestroIcon />}
        </Stack>

        <Stack onClick={onClick}>{rightIcon}</Stack>
      </Stack>

      {!!typeCard && (
        <Typography marginTop="14px" variant="text2">
          **** {name}
        </Typography>
      )}
    </Stack>
  );
};

export default Card;
