import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyFavorites = lazy(() => import('./Favorites'));

const FavoritesPage = () => (
  <Suspense fallback={<Loading />}>
    <LazyFavorites />
  </Suspense>
);

export default FavoritesPage;
