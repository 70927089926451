import React from 'react';

export const NotFoundNotificationIcon = () => {
  return (
    <svg width="83" height="91" viewBox="0 0 83 91" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M78.0595 0H18.8894C16.4778 0 14.532 1.95578 14.532 4.35654V39.4056C14.532 41.8167 16.4882 43.7621 18.8894 43.7621H25.4201V53.4687C25.4201 56.3661 28.9184 57.8149 30.9676 55.7659L42.9735 43.7621H78.0595C80.4711 43.7621 82.4169 41.8063 82.4169 39.4056V4.35654C82.4169 1.94544 80.4607 0 78.0595 0Z"
        fill="url(#paint0_linear_3323_4020)"
      />
      <circle cx="37.7199" cy="22.7865" r="4.12438" fill="url(#paint1_linear_3323_4020)" />
      <circle cx="52.3597" cy="22.7865" r="4.12438" fill="url(#paint2_linear_3323_4020)" />
      <circle cx="66.9995" cy="22.7865" r="4.12438" fill="url(#paint3_linear_3323_4020)" />
      <g filter="url(#filter0_b_3323_4020)">
        <path
          d="M45.4113 12.0891H7.83077C3.82925 12.0891 0.583069 15.4234 0.583069 19.5252V81.8371C0.583069 85.9472 3.82925 89.2732 7.83077 89.2732H45.4113C49.4129 89.2732 52.6591 85.9472 52.6591 81.8371V19.5252C52.6591 15.4234 49.4129 12.0891 45.4113 12.0891ZM42.4865 83.9251C42.1892 84.2305 41.7714 84.4203 41.3215 84.4203H11.9207C11.0127 84.4203 10.2735 83.6692 10.2735 82.7366C10.2735 82.2745 10.4583 81.8453 10.7556 81.5399C11.0529 81.2345 11.4627 81.0447 11.9207 81.0447H41.3215C42.2294 81.0447 42.9687 81.804 42.9687 82.7366C42.9687 83.1988 42.7838 83.628 42.4865 83.9251Z"
          fill="url(#paint4_linear_3323_4020)"
          fillOpacity="0.2"
        />
        <path
          d="M7.83077 12.4391H45.4113C49.211 12.4391 52.3091 15.6081 52.3091 19.5252V81.8371C52.3091 85.7622 49.2113 88.9232 45.4113 88.9232H7.83077C4.03083 88.9232 0.933069 85.7622 0.933069 81.8371V19.5252C0.933069 15.6081 4.03108 12.4391 7.83077 12.4391ZM41.3215 84.7703C41.8696 84.7703 42.3759 84.5396 42.7357 84.1709C43.0983 83.8076 43.3187 83.2899 43.3187 82.7366C43.3187 81.6196 42.4315 80.6947 41.3215 80.6947H11.9207C11.3628 80.6947 10.8641 80.9267 10.5048 81.2957C10.145 81.6653 9.9235 82.1816 9.9235 82.7366C9.9235 83.8559 10.8128 84.7703 11.9207 84.7703H41.3215Z"
          stroke="url(#paint5_linear_3323_4020)"
          strokeWidth="0.7"
        />
      </g>
      <defs>
        <filter
          id="filter0_b_3323_4020"
          x="-10.3224"
          y="1.18368"
          width="73.8869"
          height="98.9949"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5.45272" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_3323_4020" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_3323_4020" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_3323_4020"
          x1="48.4744"
          y1="0"
          x2="48.4744"
          y2="56.7257"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8FF00" />
          <stop offset="1" stopColor="#00FF57" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3323_4020"
          x1="37.7199"
          y1="18.6621"
          x2="41.8443"
          y2="29.2491"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.67" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3323_4020"
          x1="52.3597"
          y1="18.6621"
          x2="56.4841"
          y2="29.2491"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.39" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3323_4020"
          x1="66.9995"
          y1="18.6621"
          x2="71.1239"
          y2="29.2491"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.39" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3323_4020"
          x1="0.583069"
          y1="12.0891"
          x2="64.0203"
          y2="22.9571"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9D9C9C" />
          <stop offset="0.375" stopColor="#C1C1C1" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3323_4020"
          x1="4.25905"
          y1="18.1595"
          x2="65.6214"
          y2="62.4268"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
