import React from 'react';

import { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazySettings = lazy(() => import('./Settings'));

const SettingsPage = () => (
  <Suspense fallback={<Loading />}>
    <LazySettings />
  </Suspense>
);

export default SettingsPage;
