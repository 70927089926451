import React, { FC } from 'react';
import './styles.scss';
import PinInput from 'react-pin-input';
import { Box, BoxProps } from '@mui/material';
import { Typography } from 'front-package-ui-kit';

export const TEST_ID = 'sms-code-input';

interface IProps extends Omit<BoxProps, 'onChange'> {
  error?: string;
  onChange?: (value: string) => void;
  onComplete?: (value: string) => void;
  initialValue?: string | number;
}

const PinInputUI: FC<IProps> = ({ error, onChange, onComplete, initialValue, ...props }) => {
  return (
    <Box data-error={Boolean(error)} {...props}>
      <PinInput
        length={4}
        type="numeric"
        inputMode="number"
        onChange={onChange}
        onComplete={onComplete}
        initialValue={initialValue}
        focus
      />

      {Boolean(error) && (
        <div className="pincode-input-error-container">
          <Typography variant="text7" color="error">
            {error}
          </Typography>
        </div>
      )}
    </Box>
  );
};

export default PinInputUI;
