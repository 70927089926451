import { Box, Stack } from '@mui/material';
import { Typography } from 'front-package-ui-kit';
import React, { FC } from 'react';
import s from './styles.module.scss';

interface IProps {
  isEmpty: boolean;
  isOneParameter: boolean;
  unit: 'см' | 'кг';
  rightTitle: 'вес' | 'объем';
  valueProgress: string | number;
  currentParamValue: string;
}

const ContentDashBoard: FC<IProps> = ({
  isOneParameter,
  unit,
  rightTitle,
  valueProgress,
  currentParamValue,
  isEmpty,
}) => {
  return (
    <>
      {!isEmpty && (
        <Stack direction="row" justifyContent="space-between">
          <Box>
            <Typography variant="text7" fontWeight={500} opacity={0.5} color="white">
              Прогресс
            </Typography>

            {isOneParameter && (
              <Stack height="32px" width="24px" justifyContent="center" flexDirection="column">
                <div className={s.lineOneParameter}></div>
              </Stack>
            )}

            {!isOneParameter && (
              <Stack flexDirection="row" columnGap="9px" alignItems="baseline">
                <Typography variant="p" fontSize="24px" lineHeight="32px" fontWeight={600} color="white">
                  {valueProgress}
                </Typography>

                <Typography variant="p" fontSize="16px" fontWeight={500} color="white">
                  {unit}
                </Typography>
              </Stack>
            )}
          </Box>

          <Box>
            <Typography variant="text7" fontWeight={500} opacity={0.5} color="white">
              Текущий {rightTitle}
            </Typography>

            <Stack flexDirection="row" columnGap="9px" alignItems="baseline" justifyContent="flex-end">
              <Typography variant="p" fontSize="24px" lineHeight="32px" fontWeight={600} color="white">
                {currentParamValue}
              </Typography>

              <Typography variant="p" fontSize="16px" fontWeight={500} color="white">
                {unit}
              </Typography>
            </Stack>
          </Box>
        </Stack>
      )}

      {isEmpty && (
        <Box className={s.emptyContainer} paddingY="8px">
          <Typography variant="text7" color="white" fontWeight={500} opacity={0.5} textAlign="center">
            Добавьте параметры, чтобы увидеть динамику
          </Typography>
        </Box>
      )}
    </>
  );
};

export default ContentDashBoard;
