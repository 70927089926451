import React, { FC } from 'react';
import { Stack } from '@mui/material';
import Input from '../Input';
import { InputNames, validationSchema } from './config';
import { ToggleSex } from '../../../../components';
import { useAppDispatch, useUser } from '../../../../hooks';
import { useFormik } from 'formik';
import s from './styles.module.scss';
import { AnalyticsName, Environment, noticeCreator } from '../../../../utils';
import { createServiceNotice } from '../../../../redux/notifications/slice';
import api from '../../../../api';
import { updateUserInfo } from '../../../../redux/user/slice';
import { getTextError } from '../../../../redux/utils';
import { IUserInfoForUpdate } from '../../../../api/types';
import { Typography } from 'front-package-ui-kit';
import { ButtonNext } from '..';

interface IProps {
  handleStep: () => void;
  stepNumber: number;
  numberOfSteps: number;
}

const AboutSelf: FC<IProps> = ({ handleStep, stepNumber, numberOfSteps }) => {
  const { user } = useUser();

  const dispatch = useAppDispatch();

  const initialValues = {
    sex: user?.sex ?? null,
    firstName: user?.name ?? '',
  };

  const { handleSubmit, errors, getFieldProps, setFieldValue, values, setErrors } = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: async ({ firstName, sex }) => {
      const userInfo: IUserInfoForUpdate = { avatar: user?.avatar ?? '', name: firstName };

      if (sex !== null) userInfo.sex = sex;

      try {
        const userResponse = (await api.updateUserInfo(userInfo)).data.data;

        dispatch(updateUserInfo(userResponse));

        handleStep();
      } catch (error) {
        const textError = getTextError(error);

        dispatch(
          createServiceNotice({
            notice: noticeCreator(textError, 'error'),
            otherInfo: { error, pathname: 'AboutSelf updateUserInfo', forEnvironment: Environment.development },
          })
        );
      }
    },
  });

  const onFocusHandler = () => {
    setErrors({});
  };

  const handleChangeSex = (value: number) => {
    setFieldValue(InputNames.sex, value);
  };

  const isDisabled = Boolean(Object.values(errors).length) || !Boolean(values.firstName.length);

  return (
    <form className={s.containerAboutSelf} onSubmit={handleSubmit}>
      <Stack marginBottom="24px" padding="0 8px">
        <Typography variant="h3" color="white" fontWeight={700} marginBottom="8px">
          Давайте познакомимся
        </Typography>

        <Typography variant="text6" color="white" fontWeight={400} opacity={0.6}>
          Как вас зовут?
        </Typography>
      </Stack>

      <Stack marginBottom="32px">
        <ToggleSex handleChange={handleChangeSex} currentSex={initialValues.sex} marginBottom="16px" />

        <Input
          placeholder="Имя"
          autoFocus
          {...getFieldProps(InputNames.firstName)}
          error={errors.firstName}
          onFocus={onFocusHandler}
        />
      </Stack>

      <ButtonNext
        disabled={isDisabled}
        stepNumber={stepNumber}
        numberOfSteps={numberOfSteps}
        analyticInfo={{ name: AnalyticsName.onboardingAboutSelfNext }}
      />
    </form>
  );
};

export default AboutSelf;
