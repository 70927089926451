import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CoursesTabButtons } from './config';

interface ICourses {
  currentTab: CoursesTabButtons;
  hideCourseTabButtons: boolean;
}

const initialState: ICourses = {
  currentTab: CoursesTabButtons.all,
  hideCourseTabButtons: false,
};

export const coursesSlice = createSlice({
  name: 'courses',
  initialState,
  reducers: {
    setCurrentTab(state, { payload }: PayloadAction<CoursesTabButtons>) {
      state.currentTab = payload;
    },
    setHideCourseTabButtons(state, { payload }: PayloadAction<boolean>) {
      state.hideCourseTabButtons = payload;
    },
  },
});

export const { setCurrentTab, setHideCourseTabButtons } = coursesSlice.actions;

export default coursesSlice.reducer;
