import { Box } from '@mui/material';
import React, { Fragment, forwardRef, ComponentPropsWithoutRef } from 'react';
import DropDownMenu from '../../../../atoms/DropDownMenu';
import { Typography } from 'front-package-ui-kit';
import { TParameterTypeChest, TParameterTypeHips, TParameterTypeWaist } from '../../../../../utils/constants/config';
import s from './styles.module.scss';

interface IProps extends ComponentPropsWithoutRef<'div'> {
  handleSetParameter: (parameter: TParameterTypeHips | TParameterTypeWaist | TParameterTypeChest) => void;
  isOpenDropDown: boolean;
  onOpenDropDown: () => void;
  sizeParameters: (TParameterTypeHips | TParameterTypeWaist | TParameterTypeChest)[];
  currentParameter: TParameterTypeHips | TParameterTypeWaist | TParameterTypeChest | null;
}

const DropDown = forwardRef<HTMLDivElement, IProps>((props, ref) => {
  const { handleSetParameter, isOpenDropDown, onOpenDropDown, sizeParameters, currentParameter } = props;

  return (
    <Box className={s.dropDownContainer}>
      <DropDownMenu open={isOpenDropDown} setOpen={onOpenDropDown} ref={ref}>
        <div className={s.dropDownCard}>
          {sizeParameters.map((parameter, i) => (
            <Fragment key={`${parameter.name}-${i}`}>
              {parameter.name !== currentParameter?.name && (
                <>
                  <button className={s.btn} onClick={() => handleSetParameter(parameter)}>
                    <Typography variant="text5" marginLeft="14px">
                      {`Объем ${parameter.declination.genitive}`}
                    </Typography>
                  </button>
                  {sizeParameters.length >= i + 1 && <Box className={s.greyLine}></Box>}
                </>
              )}
            </Fragment>
          ))}
        </div>
      </DropDownMenu>
    </Box>
  );
});

DropDown.displayName = 'DropDown';

export default DropDown;
