import React, { FC, useRef, useState } from 'react';
import s from './styles.module.scss';
import cn from 'classnames';

export const TEST_ID = 'checkbox';

interface IProps {
  checked?: boolean;
  onChange: (checked: boolean) => void;
  offBackgroundColor?: 'lightGrey' | 'grey';
}

const CheckboxUI: FC<IProps> = ({
  onChange,
  checked: defaultValueChecked = false,
  offBackgroundColor = 'lightGrey',
}) => {
  const [isChecked, setIsChecked] = useState(defaultValueChecked);

  const checkbox = useRef<HTMLInputElement>(null);

  const classes = cn(s.checkbox, {
    [s.active]: isChecked,
    [s.lightGreyBackground]: offBackgroundColor === 'lightGrey',
    [s.greyBackground]: offBackgroundColor === 'grey',
  });

  const triggerInput = () => {
    if (!checkbox.current) return;

    checkbox.current.click();
  };

  const onChangeHandler = ({ target: { checked } }: React.ChangeEvent<HTMLInputElement>) => {
    setIsChecked(checked);

    onChange(isChecked);
  };

  const onClickHandler = () => {
    triggerInput();
  };

  return (
    <div data-testid={TEST_ID} onClick={onClickHandler} className={classes}>
      <input
        ref={checkbox}
        onChange={onChangeHandler}
        type="checkbox"
        name="checkbox"
        defaultChecked={defaultValueChecked}
      />
    </div>
  );
};

export default CheckboxUI;
