import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type ScrollSchema = Record<string, number>;

interface IScrollSaveStore {
  scroll: ScrollSchema;
}

export const initialState: IScrollSaveStore = {
  scroll: {},
};

export const scrollSaveSlice = createSlice({
  name: 'scrollSave',
  initialState,
  reducers: {
    setScrollPosition: (state, { payload }: PayloadAction<{ path: string; position: number }>) => {
      state.scroll[payload.path] = payload.position;
    },
  },
});

export const { setScrollPosition } = scrollSaveSlice.actions;

export default scrollSaveSlice.reducer;
