import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyLicenseAgreement = lazy(() => import('./LicenseAgreement'));

const LicenseAgreementPage = () => (
  <Suspense fallback={<Loading />}>
    <LazyLicenseAgreement />
  </Suspense>
);

export default LicenseAgreementPage;
