import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { ArrowBackIcon } from '../../../assets/icons';
import { Paths } from '../../../utils';
import IconButtonUI from '../../atoms/IconButtonUI';

export const TEST_ID = 'go-back-button';

const { main } = Paths;

export interface IGoBackButtonProps {
  white?: boolean;
  onClick?: () => void;
}

interface IProps extends IGoBackButtonProps {}

const GoBackButton: FC<IProps> = ({ white, onClick }) => {
  const navigate = useNavigate();

  const onClickHandler = () => {
    if (onClick) return onClick();

    const isHaveRouterHistory = window.history.length > 1;

    if (isHaveRouterHistory) {
      navigate(-1);
    } else {
      navigate(main);
    }
  };

  return (
    <IconButtonUI size={36} type="button" data-testid={TEST_ID} onClick={onClickHandler}>
      <ArrowBackIcon white={white} />
    </IconButtonUI>
  );
};

export default GoBackButton;
