import React, { memo, MutableRefObject, ReactNode, useCallback, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { Overlay } from '../Overlay/Overlay';
import s from './Drawer.module.scss';
import { Portal } from '../Portal/Portal';
import { AnimationProvider, useAnimationLibs } from '../../contexts/AnimationProvider';

export type SpringEvent = {
  type: Statuses;
};

interface DrawerProps {
  children: ReactNode;
  isOpen?: boolean;
  onClose?: () => void;
  onSpring?: (event: SpringEvent) => void;
  touchLine?: boolean;
  closeOnContentDrag?: boolean;
  header?: ReactNode;
  footer?: ReactNode;
  onScroll?: (e: Event) => void;
  transparentHeader?: boolean;
  transparentHeaderHeight?: number;
}

const height = window.innerHeight;

export enum Statuses {
  OPEN = 'open',
  CLOSED = 'closed',
  CLOSING = 'closing',
}

export const DrawerContent = memo((props: DrawerProps) => {
  const {
    children,
    isOpen,
    onClose,
    header,
    footer,
    closeOnContentDrag,
    onSpring,
    onScroll,
    touchLine = true,
    transparentHeader = false,
    transparentHeaderHeight,
  } = props;

  const [immediateOpen, setImmediateOpen] = useState(true);

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const timer = useRef<NodeJS.Timer | null>(null);

  const [status, setStatus] = React.useState<Statuses>(isOpen ? Statuses.OPEN : Statuses.CLOSED);

  const divRef = useRef() as MutableRefObject<HTMLDivElement | null>;

  const { Spring, Gesture } = useAnimationLibs();

  const [{ y }, api] = Spring.useSpring(() => ({ y: height }));

  const openDrawer = useCallback(() => {
    api.start({ y: 0, immediate: immediateOpen });
  }, [immediateOpen, api]);

  const close = useCallback(() => {
    setStatus(Statuses.CLOSING);

    timer.current = setTimeout(() => {
      setStatus(Statuses.CLOSED);
    }, 500);

    api.start({
      y: height,
      immediate: false,
    });
  }, [api]);

  const bind = Gesture.useDrag(
    ({ last, velocity: [, vy], direction: [, dy], movement: [, my] }) => {
      if (last) {
        if (my > height * 0.1 || (vy > 0.1 && dy > 0)) {
          onClose?.();
        } else {
          openDrawer();
        }
      } else {
        api.start({ y: my, immediate: true });
      }
    },
    {
      from: () => [0, y.get()],
      filterTaps: true,
      bounds: { top: 0 },
      rubberband: false,
    }
  );

  const scrollTopPosition = () => scrollRef.current?.scrollTop;

  const midleBind = Gesture.useDrag(
    ({ last, velocity: [, vy], direction: [, dy], movement: [, my], cancel }) => {
      if (scrollTopPosition() !== 0) cancel();

      if (last) {
        if (my > height * 0.1 || (vy > 0.1 && dy > 0)) {
          onClose?.();
        } else {
          openDrawer();
        }
      } else {
        api.start({ y: my, immediate: true });
      }
    },
    {
      from: () => [0, y.get()],
      filterTaps: true,
      bounds: { top: 0 },
      rubberband: false,
    }
  );

  useEffect(() => {
    if (isOpen) {
      if (timer.current) {
        clearTimeout(timer.current);
      }
      openDrawer();
      setStatus(Statuses.OPEN);
      return;
    }

    if (isOpen === false) {
      close();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  useEffect(() => {
    onSpring?.({ type: status });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  useEffect(() => {
    const currentScrollRef = scrollRef.current;
    let xDown: number | null = null;
    let yDown: number | null = null;

    const handleScroll = (e: Event) => {
      onScroll?.(e);
    };

    const hadleTouchstart = (e: TouchEvent) => {
      xDown = e.touches[0].clientX;
      yDown = e.touches[0].clientY;
    };

    const handleTouchmove = (e: TouchEvent) => {
      if (!xDown || !yDown || !scrollRef.current || status !== Statuses.OPEN) {
        return;
      }

      const scrollBottomPosition = () => {
        return scrollRef.current!.scrollHeight - scrollRef.current!.scrollTop - scrollRef.current!.offsetHeight;
      };

      var xUp = e.touches[0].clientX;
      var yUp = e.touches[0].clientY;

      var xDiff = xDown - xUp;
      var yDiff = yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          // left
        } else {
          //right
        }
      } else {
        // up
        if (yDiff > 0) {
          if (scrollRef.current && scrollBottomPosition() <= 0) {
            requestAnimationFrame(() => {
              scrollRef.current!.style.overflow = 'hidden';
              scrollRef.current!.scrollTop = scrollRef.current!.scrollHeight;
              scrollRef.current!.style.removeProperty('overflow');
            });

            e.preventDefault();
          }
        } else {
          // down
          if (scrollRef.current && scrollRef.current.scrollTop <= 0) {
            requestAnimationFrame(() => {
              scrollRef.current!.style.overflow = 'hidden';
              scrollRef.current!.scrollTop = 0;
              scrollRef.current!.style.removeProperty('overflow');
            });

            e.preventDefault();
          }
        }
      }

      xDown = e.touches[0].clientX;
      yDown = e.touches[0].clientY;
    };

    scrollRef.current?.addEventListener('scroll', handleScroll);
    scrollRef.current?.addEventListener('touchstart', hadleTouchstart);
    scrollRef.current?.addEventListener('touchmove', handleTouchmove);

    return () => {
      currentScrollRef?.removeEventListener('scroll', handleScroll);
      currentScrollRef?.removeEventListener('touchstart', hadleTouchstart);
      currentScrollRef?.removeEventListener('touchmove', handleTouchmove);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollRef.current, status]);

  useEffect(() => {
    if (transparentHeader && !transparentHeaderHeight)
      console.warn('Bottom sheet: transparentHeaderHeight is not defined');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setImmediateOpen(false);
  }, []);

  if (status === Statuses.CLOSED) {
    return null;
  }

  return (
    <Portal element={document.getElementById('app') ?? document.body}>
      <div className={cn(s.Drawer)}>
        {status === Statuses.OPEN && <Overlay onClick={() => onClose?.()} />}

        <Spring.a.div
          ref={divRef}
          className={s.sheet}
          style={{
            y,
          }}
        >
          {touchLine && <div className={cn(s.touchLine, s.touchAction)} {...bind()}></div>}

          <div className={s.contentContainer}>
            {header && (
              <div className={cn(s.touchAction, { [s.transparentHeader]: transparentHeader })} {...bind()}>
                {header}
              </div>
            )}

            <div
              style={{ marginTop: transparentHeader ? `-${transparentHeaderHeight}px` : 0 }}
              {...(closeOnContentDrag ? midleBind() : {})}
              className={cn(s.scrollContainer, s.touchAction, {
                [s.transparentHeaderScrollContainer]: transparentHeader,
              })}
            >
              <div className={cn(s.scroll, s.touchAction)} ref={scrollRef}>
                {children}
              </div>
            </div>

            {footer && (
              <div className={s.touchAction} {...bind()}>
                {footer}
              </div>
            )}
          </div>
        </Spring.a.div>
      </div>
    </Portal>
  );
});

DrawerContent.displayName = 'DrawerContent';

const DrawerAsync = (props: DrawerProps) => {
  const { isLoaded } = useAnimationLibs();

  if (!isLoaded) {
    return null;
  }

  return <DrawerContent {...props} />;
};

export const Drawer = (props: DrawerProps) => {
  return (
    <AnimationProvider>
      <DrawerAsync {...props} />
    </AnimationProvider>
  );
};
