import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyCollections = lazy(() => import('./Collections'));

const CollectionsPage = () => (
  <Suspense fallback={<Loading />}>
    <LazyCollections />
  </Suspense>
);

export default CollectionsPage;
