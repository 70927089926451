import { Box, Stack } from '@mui/material';
import { Button, Typography } from 'front-package-ui-kit';
import React, { FC, memo } from 'react';
import { IButtonsFilterOptions } from '../../../hooks/useFilter';
import { useBottomSheet } from '../../../hooks';
import { BottomButtonBlock, BottomSheetHeader, BottomSheetUI, DividerUI, FilterMenuItem } from '../..';

export const TEST_ID = 'FiltersBottomSheet';

export const openFiltersBottomSheet = 'openFiltersBottomSheet';

export interface IFiltersBottomSheetProps {
  configuredButtonFilters: IButtonsFilterOptions[];
  onResetClick: () => void;
  numberOfCourses: number;
  numberOfFilters: number;
}

const FiltersBottomSheet: FC<IFiltersBottomSheetProps> = ({
  configuredButtonFilters,
  onResetClick,
  numberOfCourses,
  numberOfFilters,
}) => {
  const { onCloseBottomSheet, getIsOpenBottomSheet } = useBottomSheet();

  const isFiltersActive = Boolean(numberOfFilters);

  const isOpen = getIsOpenBottomSheet(openFiltersBottomSheet);

  return (
    <BottomSheetUI
      data-testid={TEST_ID}
      height="auto"
      touchLine
      onDismiss={() => onCloseBottomSheet()}
      open={isOpen}
      header={
        <BottomSheetHeader
          alignItems="center"
          title={
            <Box width="100%">
              <Typography variant="h4" fontWeight={700} textAlign="center">
                Фильтры
              </Typography>
            </Box>
          }
          onClose={onCloseBottomSheet}
        />
      }
      footer={
        <BottomButtonBlock direction="column" gap="8px">
          <Button variant="contained" fullWidth onClick={() => onCloseBottomSheet()} size="L" color="black">
            <Stack flexDirection="row" columnGap="6px">
              <Typography variant="text6" fontWeight={700} color="white">
                Показать
              </Typography>

              {isFiltersActive && (
                <Typography variant="text6" fontWeight={700} color="lime">
                  {numberOfCourses}
                </Typography>
              )}
            </Stack>
          </Button>

          <Button size="L" fullWidth variant="text" onClick={onResetClick}>
            <Typography variant="text6" fontWeight={700}>
              Сбросить
            </Typography>
          </Button>
        </BottomButtonBlock>
      }
    >
      <Box padding="0 20px">
        {configuredButtonFilters.map(({ onFilterButtonClickHandler, filterButtons, title, filterValues }, i) => (
          <Box key={title}>
            <FilterMenuItem
              title={title}
              filterValues={filterValues}
              onFilterButtonClickHandler={onFilterButtonClickHandler}
              options={filterButtons}
              padding=" 0 0 30px 0"
            />

            {configuredButtonFilters.length > i + 1 && <DividerUI color="grey" marginBottom="16px" />}
          </Box>
        ))}
      </Box>
    </BottomSheetUI>
  );
};

export const FiltersBottomSheetMemo = memo(FiltersBottomSheet);
