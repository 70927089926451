import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProfileTabButtons } from './config';
import { ALL_TIME_VALUE } from './components/SetHistoryIntervalBottomSheet';

interface IProfile {
  activityDay: number;
  currentTab: ProfileTabButtons;
  currentHistoryIntervalYear: string;
  currentHistoryIntervalMonth: number | null;
  isRestructuringContent: boolean;
}

const initialState: IProfile = {
  activityDay: new Date().getTime(),
  currentTab: ProfileTabButtons.progress,
  currentHistoryIntervalYear: ALL_TIME_VALUE,
  currentHistoryIntervalMonth: null,
  isRestructuringContent: false,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setActivityDay(state, { payload }: PayloadAction<number>) {
      state.activityDay = payload;
    },
    setCurrentTab(state, { payload }: PayloadAction<ProfileTabButtons>) {
      state.currentTab = payload;
    },
    setCurrentHistoryIntervalYear(state, { payload }: PayloadAction<string>) {
      state.currentHistoryIntervalYear = payload;
    },
    setCurrentHistoryIntervalMonth(state, { payload }: PayloadAction<number | null>) {
      state.currentHistoryIntervalMonth = payload;
    },
    setIsRestructuringContent(state, { payload }: PayloadAction<boolean>) {
      state.isRestructuringContent = payload;
    },
  },
});

export const {
  setActivityDay,
  setCurrentTab,
  setCurrentHistoryIntervalYear,
  setCurrentHistoryIntervalMonth,
  setIsRestructuringContent,
} = profileSlice.actions;

export default profileSlice.reducer;
