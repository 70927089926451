import React from 'react';

import { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyEdit = lazy(() => import('./Edit'));

const EditPage = () => (
  <Suspense fallback={<Loading />}>
    <LazyEdit />
  </Suspense>
);

export default EditPage;
