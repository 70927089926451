import React, { FC } from 'react';
import { useAppSelector } from '../../../hooks';
import Notice from '../../molecules/Notice';
import s from './styles.module.scss';

const ServiceNotifications: FC = () => {
  const {
    notificationsStore: { serviceNotifications },
  } = useAppSelector((state) => state);

  return (
    <div className={s.notificationsMain}>
      {serviceNotifications.map((notice) => (
        <Notice key={notice.id} {...notice} />
      ))}
    </div>
  );
};

export default ServiceNotifications;
