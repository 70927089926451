import React from 'react';

export const NotFoundHistoryWorkouts = () => {
  return (
    <svg width="111" height="90" viewBox="0 0 111 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect
        x="32.0323"
        y="2.53821"
        width="66.4422"
        height="66.4422"
        rx="12.1912"
        transform="rotate(17.3346 32.0323 2.53821)"
        fill="url(#paint0_linear_3070_218)"
      />
      <g filter="url(#filter0_bi_3070_218)">
        <rect
          x="27.1147"
          y="17.9354"
          width="66.4422"
          height="66.4422"
          rx="12.1912"
          fill="url(#paint1_linear_3070_218)"
          fillOpacity="0.2"
        />
        <rect
          x="27.4402"
          y="18.2609"
          width="65.7913"
          height="65.7913"
          rx="11.8658"
          stroke="url(#paint2_linear_3070_218)"
          strokeWidth="0.65095"
        />
      </g>
      <circle cx="46.012" cy="53.1564" r="3.42878" fill="url(#paint3_linear_3070_218)" />
      <circle cx="46.012" cy="64.5859" r="3.42878" fill="url(#paint4_linear_3070_218)" />
      <circle cx="60.8703" cy="53.1564" r="3.42878" fill="url(#paint5_linear_3070_218)" />
      <circle cx="60.8703" cy="64.5859" r="3.42878" fill="url(#paint6_linear_3070_218)" />
      <circle cx="75.7282" cy="53.1564" r="3.42878" fill="url(#paint7_linear_3070_218)" />
      <circle cx="75.7282" cy="64.5859" r="3.42878" fill="url(#paint8_linear_3070_218)" />
      <rect x="40.1338" y="31.9552" width="39.6215" height="4.87649" rx="2.43825" fill="url(#paint9_linear_3070_218)" />
      <rect
        x="46.6433"
        y="24.8564"
        width="10.9721"
        height="4.87649"
        rx="2.43825"
        transform="rotate(-90 46.6433 24.8564)"
        fill="url(#paint10_linear_3070_218)"
      />
      <rect
        x="69.197"
        y="24.8564"
        width="10.9721"
        height="4.87649"
        rx="2.43825"
        transform="rotate(-90 69.197 24.8564)"
        fill="url(#paint11_linear_3070_218)"
      />
      <defs>
        <filter
          id="filter0_bi_3070_218"
          x="19.8"
          y="10.6207"
          width="81.0717"
          height="81.0717"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="3.65737" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_3070_218" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_3070_218" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.21912" />
          <feGaussianBlur stdDeviation="2.43825" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3070_218" />
        </filter>
        <linearGradient
          id="paint0_linear_3070_218"
          x1="65.2535"
          y1="2.53821"
          x2="65.2535"
          y2="68.9804"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8FF00" />
          <stop offset="1" stopColor="#00FF57" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3070_218"
          x1="27.1147"
          y1="17.9354"
          x2="105.382"
          y2="37.8088"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9D9C9C" />
          <stop offset="0.375" stopColor="#C1C1C1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3070_218"
          x1="60.3359"
          y1="17.9354"
          x2="93.557"
          y2="103.211"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.39" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3070_218"
          x1="46.012"
          y1="49.7276"
          x2="49.4408"
          y2="58.529"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.39" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3070_218"
          x1="46.012"
          y1="61.1571"
          x2="49.4408"
          y2="69.9585"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.39" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3070_218"
          x1="60.8703"
          y1="49.7276"
          x2="64.2991"
          y2="58.529"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.39" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_3070_218"
          x1="60.8703"
          y1="61.1571"
          x2="64.2991"
          y2="69.9585"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.39" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_3070_218"
          x1="75.7282"
          y1="49.7276"
          x2="79.157"
          y2="58.529"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.39" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_3070_218"
          x1="75.7282"
          y1="61.1571"
          x2="79.157"
          y2="69.9585"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.39" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_3070_218"
          x1="41.1708"
          y1="36.8156"
          x2="45.6675"
          y2="48.9863"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.75" />
          <stop offset="1" stopColor="white" stopOpacity="0.39" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_3070_218"
          x1="46.8818"
          y1="31.1152"
          x2="51.1356"
          y2="34.0654"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.76" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_3070_218"
          x1="69.4355"
          y1="31.1152"
          x2="73.6893"
          y2="34.0654"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.76" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
      </defs>
    </svg>
  );
};
