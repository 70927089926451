export const NoServerConectionIcon = () => {
  return (
    <svg width="168" height="170" viewBox="0 0 168 170" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_bi_529_2107)">
        <path d="M64 36H109L124 170H44L64 36Z" fill="url(#paint0_linear_529_2107)" />
      </g>
      <g filter="url(#filter1_bi_529_2107)">
        <ellipse cx="84" cy="47.5" rx="84" ry="16.5" fill="url(#paint1_linear_529_2107)" fillOpacity="0.2" />
        <path
          d="M167.5 47.5C167.5 48.4539 167.01 49.4443 165.947 50.4646C164.884 51.4853 163.288 52.4937 161.188 53.4691C156.991 55.4184 150.885 57.1868 143.301 58.6766C128.14 61.6546 107.174 63.5 84 63.5C60.8263 63.5 39.8601 61.6546 24.6994 58.6766C17.1149 57.1868 11.0093 55.4184 6.81174 53.4691C4.71157 52.4937 3.11613 51.4853 2.05283 50.4646C0.989944 49.4443 0.5 48.4539 0.5 47.5C0.5 46.5461 0.989945 45.5557 2.05283 44.5354C3.11613 43.5147 4.71157 42.5063 6.81174 41.5309C11.0093 39.5816 17.1149 37.8132 24.6994 36.3234C39.8601 33.3454 60.8263 31.5 84 31.5C107.174 31.5 128.14 33.3454 143.301 36.3234C150.885 37.8132 156.991 39.5816 161.188 41.5309C163.288 42.5063 164.884 43.5147 165.947 44.5354C167.01 45.5557 167.5 46.5461 167.5 47.5Z"
          stroke="url(#paint2_linear_529_2107)"
          strokeOpacity="0.5"
        />
      </g>
      <path
        d="M120 35.9796C120 35.9796 113.426 43 84.5 43C55.5741 43 49 35.9796 49 35.9796C49 16.1086 64.8939 0 84.5 0C104.106 0 120 16.1086 120 35.9796Z"
        fill="url(#paint3_linear_529_2107)"
      />
      <path
        d="M49.6543 35.8932C49.5928 35.8433 49.5418 35.7999 49.5006 35.7638C49.6152 16.262 65.2473 0.5 84.5 0.5C103.753 0.5 119.385 16.262 119.499 35.7638C119.458 35.7999 119.407 35.8433 119.346 35.8932C119.127 36.0711 118.775 36.3328 118.259 36.6508C117.226 37.2869 115.536 38.1489 112.939 39.0155C107.744 40.7493 98.9246 42.5 84.5 42.5C70.0754 42.5 61.2564 40.7493 56.0611 39.0155C53.4641 38.1489 51.7738 37.2869 50.7414 36.6508C50.2252 36.3328 49.8732 36.0711 49.6543 35.8932Z"
        stroke="url(#paint4_linear_529_2107)"
        strokeOpacity="0.5"
      />
      <path
        d="M103.223 119.239L67.3593 134.485C66.4744 134.855 65.4441 134.481 65.0244 133.618L62.7094 128.808C62.3995 128.159 62.5105 127.391 63.0092 126.862L79.4471 109.189C80.0897 108.493 81.1707 108.423 81.968 108.947C84.1757 110.395 87.4782 111.903 89.6129 110.669C91.7914 109.404 92.8774 106.242 93.2948 104.072C93.4329 103.338 93.9685 102.712 94.7088 102.531L95.9171 102.234C96.7624 102.029 97.6395 102.449 97.9964 103.225L104.158 116.908C104.567 117.798 104.146 118.851 103.233 119.235L103.223 119.239Z"
        fill="white"
      />
      <path
        d="M104.781 117.988C105.456 119.455 104.796 121.174 103.314 121.833L69.7085 136.77C68.2256 137.429 66.4736 136.771 65.8034 135.313L64.8894 133.325L103.867 115.999L104.781 117.988Z"
        fill="#898989"
      />
      <mask
        id="mask0_529_2107"
        style={{ maskType: 'alpha' }}
        maskUnits="userSpaceOnUse"
        x="62"
        y="101"
        width="43"
        height="34"
      >
        <path
          d="M102.976 119.138L67.5166 134.466C66.6417 134.838 65.623 134.462 65.2081 133.595L62.9192 128.759C62.6128 128.106 62.7225 127.334 63.2156 126.802L79.4681 109.034C80.1034 108.334 81.1722 108.264 81.9605 108.79C84.1433 110.246 87.4085 111.762 89.5191 110.522C91.6731 109.25 92.7468 106.07 93.1595 103.889C93.296 103.151 93.8256 102.522 94.5575 102.34L95.7522 102.041C96.5879 101.835 97.4551 102.257 97.808 103.037L103.9 116.794C104.304 117.689 103.889 118.748 102.985 119.134L102.976 119.138Z"
          fill="#1D1D1D"
        />
      </mask>
      <g mask="url(#mask0_529_2107)">
        <path
          d="M72.0752 116.572L75.4063 119.629"
          stroke="#8A8A8A"
          strokeWidth="1.06971"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M69.616 119.198L72.9695 122.279"
          stroke="#8A8A8A"
          strokeWidth="1.06971"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          d="M74.5432 113.84L77.9373 116.961"
          stroke="#8A8A8A"
          strokeWidth="1.06971"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
      <defs>
        <filter
          id="filter0_bi_529_2107"
          x="32"
          y="24"
          width="104"
          height="158"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_529_2107" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_529_2107" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_529_2107" />
        </filter>
        <filter
          id="filter1_bi_529_2107"
          x="-12"
          y="19"
          width="192"
          height="57"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="6" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_529_2107" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_529_2107" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2" />
          <feGaussianBlur stdDeviation="4" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_529_2107" />
        </filter>
        <linearGradient
          id="paint0_linear_529_2107"
          x1="82.5"
          y1="36"
          x2="83.7896"
          y2="171.701"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8FF00" />
          <stop offset="0.746312" stopColor="#2BFF41" />
          <stop offset="0.983457" stopColor="#00FF57" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_529_2107"
          x1="0"
          y1="31"
          x2="78.8684"
          y2="132.951"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C2C2C2" />
          <stop offset="1" stopColor="#999999" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_529_2107"
          x1="139.993"
          y1="32.5257"
          x2="137.58"
          y2="67.6778"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.423397" stopColor="white" stopOpacity="0.576603" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_529_2107"
          x1="56.3679"
          y1="1.01829"
          x2="55.582"
          y2="36.015"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E6E6E6" />
          <stop offset="1" stopColor="#DDDDDD" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_529_2107"
          x1="108.164"
          y1="1.98808"
          x2="98.8417"
          y2="46.0352"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="0.423397" stopColor="white" stopOpacity="0.576603" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};
