import React, { FC } from 'react';
import { getIsValue } from '../../utils';

interface IProps {
  type: 'checked' | 'unchecked';
}

const ToggleButtonIconSubscribe: FC<IProps> = ({ type }) => {
  return (
    <>
      {getIsValue(type, 'unchecked') && (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10C18 14.4183 14.4183 18 10 18Z"
            fill="#FFFFFF80"
          />
        </svg>
      )}

      {getIsValue(type, 'checked') && (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10ZM15.1644 5.75259C14.7516 5.38567 14.1195 5.42285 13.7526 5.83564L8.48606 11.7605L6.23485 9.32134C5.86027 8.9155 5.22761 8.89015 4.82177 9.26473C4.41592 9.63931 4.39057 10.272 4.76515 10.6778L7.39076 13.5226C7.95263 14.1314 8.90161 14.1694 9.51038 13.6075C9.53719 13.5819 9.5506 13.5691 9.56357 13.5559C9.57654 13.5427 9.58908 13.5291 9.61415 13.5018L15.2474 7.16436C15.6143 6.75158 15.5771 6.11951 15.1644 5.75259Z"
            fill="#7FFF00"
          />
        </svg>
      )}
    </>
  );
};

export default ToggleButtonIconSubscribe;
