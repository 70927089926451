import React, { FC } from 'react';
import { getIsValue } from '../../utils';

interface IProps {
  size: 'small' | 'medium';
}

const ProIcon: FC<IProps> = ({ size }) => {
  return (
    <>
      {getIsValue(size, 'medium') && (
        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18" fill="none">
          <path
            d="M11.0992 1.79528C11.211 1.34073 10.9847 0.872862 10.5408 0.641028C10.0969 0.409194 9.53882 0.4674 9.16404 0.784627L0.3726 8.22619C0.0210302 8.52377 -0.0946355 8.98686 0.0810292 9.39353C0.256694 9.8002 0.6879 10.0676 1.16801 10.0676H4.28908L2.78882 16.2062C2.67802 16.6595 2.90341 17.1258 3.34547 17.3578C3.78753 17.5899 4.3439 17.5338 4.71951 17.2194L13.6226 9.76802C13.9769 9.4715 14.095 9.00753 13.9202 8.5994C13.7453 8.19126 13.3133 7.92253 12.832 7.92253H9.59194L11.0992 1.79528Z"
            fill="url(#paint0_linear_1549_4409)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1549_4409"
              x1="5.29315"
              y1="3.50001"
              x2="4.58307"
              y2="17.8668"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.259084" stopColor="#7FFF00" />
              <stop offset="0.512606" stopColor="#FF0249" />
              <stop offset="0.854739" stopColor="#8000FF" />
            </linearGradient>
          </defs>
        </svg>
      )}

      {getIsValue(size, 'small') && (
        <svg xmlns="http://www.w3.org/2000/svg" width="10" height="12" viewBox="0 0 10 12" fill="none">
          <path
            d="M7.83474 0.914317C7.91367 0.593454 7.75388 0.263197 7.44054 0.0995494C7.12719 -0.0640983 6.73328 -0.0230116 6.46874 0.200913L0.263011 5.45378C0.0148449 5.66384 -0.0668016 5.99072 0.0571971 6.27779C0.181196 6.56485 0.485577 6.75361 0.824481 6.75361H3.02759L1.96858 11.0867C1.89037 11.4067 2.04947 11.7359 2.36151 11.8997C2.67355 12.0634 3.06628 12.0239 3.33142 11.802L9.61593 6.54213C9.86602 6.33282 9.94942 6.00532 9.826 5.71722C9.70258 5.42912 9.39762 5.23943 9.05787 5.23943H6.77078L7.83474 0.914317Z"
            fill="url(#paint0_linear_1785_24236)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_1785_24236"
              x1="3.73634"
              y1="2.11765"
              x2="3.23511"
              y2="12.2589"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.259084" stopColor="#7FFF00" />
              <stop offset="0.512606" stopColor="#FF0249" />
              <stop offset="0.760437" stopColor="#8000FF" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </>
  );
};

export default ProIcon;
