import { Box, StackProps } from '@mui/material';
import { BadgeCounter, Button, Skeleton } from 'front-package-ui-kit';
import { FC, useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ClearFiltersIcon, FilterIcon } from '../../../assets/icons';
import { IButtonsFilterOptions } from '../../../hooks/useFilter';
import s from './styles.module.scss';
import { QuickFilterItem } from '../..';

const skeletons = [
  { width: '36px', height: '36px', borderRadius: '12px' },
  { width: '124px', height: '36px', borderRadius: '12px' },
  { width: '92px', height: '36px', borderRadius: '12px' },
  { width: '100px', height: '36px', borderRadius: '12px' },
  { width: '124px', height: '36px', borderRadius: '12px' },
];

export const TEST_ID = 'QuickFilters';

interface IProps extends Omit<StackProps, 'className'> {
  filters?: IButtonsFilterOptions[];
  onClearClick: () => void;
  onOpenFiltersButtonClick?: () => void;
  numberOfFilters?: number;
  loading?: boolean;
  prefilledFilterValue?: string;
}

const QuickFilters: FC<IProps> = ({
  filters,
  onClearClick,
  onOpenFiltersButtonClick,
  numberOfFilters,
  loading,
  prefilledFilterValue,
  ...props
}) => {
  const [key, setKey] = useState('');
  const [activeIndex, setActiveIndex] = useState(0);
  const [activeFilterOptionsTitleForOrder, setActiveFilterOptionsTitleForOrder] = useState('');
  useEffect(() => {
    const newKey = Math.random().toString(36).substring(2, 15);
    setKey(newKey);
  }, [numberOfFilters]);

  return (
    <Box className={s.quickFilters} data-testid={TEST_ID} {...props} key={key}>
      <Swiper
        className={s.swiper}
        slidesPerView="auto"
        spaceBetween={8}
        watchOverflow={true}
        freeMode
        resistanceRatio={0}
        onSlideChange={(e) => setActiveIndex(e.activeIndex)}
        initialSlide={activeIndex}
      >
        {!loading && (
          <>
            <SwiperSlide>
              <Button
                size="S"
                onClick={onOpenFiltersButtonClick}
                variant="outlined"
                color="grey20"
                buttonType="iconButton"
                style={{ order: 1 }}
              >
                <FilterIcon />

                {Boolean(numberOfFilters) && (
                  <Box position="absolute" width="16px" height="16px" top="-4px" right="-3px">
                    <BadgeCounter color="lime">{`${numberOfFilters}`}</BadgeCounter>
                  </Box>
                )}
              </Button>
            </SwiperSlide>

            {filters?.map((filterOptions, i) => {
              if (filterOptions?.filterButtons?.length > 0) {
                return (
                  <SwiperSlide
                    style={{
                      order: i === 0 ? 2 : filterOptions.title === activeFilterOptionsTitleForOrder ? 3 : i + 3,
                    }}
                    key={filterOptions.title}
                  >
                    <QuickFilterItem
                      prefilledFilterValue={prefilledFilterValue}
                      filterOptions={filterOptions}
                      setActiveFilterOptionsTitleForOrder={setActiveFilterOptionsTitleForOrder}
                    />
                  </SwiperSlide>
                );
              } else {
                return null;
              }
            })}

            <SwiperSlide style={{ order: 200 }}>
              {(numberOfFilters ?? 0) > 0 && (
                <Button size="S" onClick={onClearClick} variant="outlined" color="grey20" buttonType="iconButton">
                  <ClearFiltersIcon />
                </Button>
              )}
            </SwiperSlide>
          </>
        )}

        {loading && (
          <>
            {skeletons.map((skeleton, i) => (
              <SwiperSlide key={i}>
                <Skeleton {...skeleton} />
              </SwiperSlide>
            ))}
          </>
        )}
      </Swiper>
    </Box>
  );
};

export default QuickFilters;
