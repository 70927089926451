import { useUser } from '.';
import { getPaidOrderByMaxExpired } from '../utils';

const useGetOrderPaid = () => {
  const { user } = useUser();

  const orderPaid = user ? getPaidOrderByMaxExpired(user.orders) : null;

  return { orderPaid };
};

export default useGetOrderPaid;
