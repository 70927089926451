import { TPlatform } from '../models';
import { IStory } from '../pages/Stories/model/types';
import { CourseOrWorkoutTypes, ResizeType, WorkoutsProgressStatuses, CourseProgressStatus } from '../utils';
import {
  CourseTypes,
  LevelsID,
  PushNotificationsTypes,
  RenderSchemaVariants,
  RenderSchemesNames,
  TypesCardValues,
  VirtualGroups,
} from '../utils/constants/config';

export interface ITokens {
  access_token: string;
  refresh_token: string;
}

export interface ISocialAuthResponse extends ITokens {
  new: boolean;
}

export interface IResponseError {
  error: string;
  error_description: string;
  message: string;
}

export interface IAuthRequestConfig {
  email: string;
  password: string;
}

export interface IAuthVkRequestConfig {
  silent_token: string;
  name: string;
  uuid: string;
}

export interface IAuthGoogleRequestConfig {
  code: string;
}

export interface IAuthRefreshConfig {
  refresh_token: string;
}

export interface IImageRequestConfig {
  identifier: string;
  size: ISize;
}

export interface IResponse<T> {
  data: T;
}

interface IRecommendation {
  name: string;
  cover: string;
  duration: string;
  level: string;
  muscles: string;
  type: string;
  is_public: boolean;
  course_type: number;
  trainer_id: number;
  direction: string;
  active_area: string;
  inventory: string;
  pulse_zone: string;
}

export interface IProgressWorkout {
  id: number;
  status: WorkoutsProgressStatuses;
  date: string;
}

export interface IProgressCourse {
  id: number;
  status: CourseProgressStatus;
  date: string;
}

export type TWorkoutType = 'workout' | 'weekend' | 'trailer';

export interface IAccents {
  id: number;
  title: string;
}

export interface ILevel {
  id: LevelsID;
  title: string;
}

export interface IWorkout {
  id: number;
  description: string;
  title: string;
  source_type: number;
  source_id: string;
  is_public: boolean;
  rating: string;
  active: boolean;
  intensity: number | null;
  files: IFilesForDetailseCourseCover[];
  favorite: boolean;
  progress?: IProgressWorkout;
  image: string | null;
  // levels: ILevel[] | null;
  duration: number | null;
  active_area: string | null;
  inventory: string | null;
  week: string | null;
  day: string | null;
  type: TWorkoutType;
  calories: number | null;
  accents: IAccents[];
  rating_me: string;
  short_description?: string;
}

export type TypeGroups = 'popular' | 'new' | 'duration' | 'level' | string;

export interface IGroups {
  id: number | string;
  name: string;
  type: TypeGroups;
  active?: boolean;
  cover?: string;
}

export type TypeDurationProduct = 'day' | 'week' | 'month' | 'year' | '';

export interface IProducts {
  badge: string | null;
  description: string | null;
  duration: number;
  duration_type: TypeDurationProduct;
  goods: [];
  id: number;
  price_promotion: number;
  price: number;
  sort: number;
  title: string;
  type: string;
  promotion: {
    percent: string | null;
    discount: string | null;
  } | null;
}

export interface IProductConfig {
  promocode?: string;
  platform?: TPlatform;
}

export interface IConstant {
  id: number;
  key: string;
  type: string;
  value: string;
}

export interface IWorkoutDetailse extends IWorkout {
  recommendations?: IRecommendation[];
  course: ICourse;
}

export interface IFilesForDetailseCourseCover {
  id: string;
  name: string;
}

export type IDateRange = {
  from: string;
  to: string;
};

export interface ICourse {
  id: number;
  name: string;
  cover: string | null;
  duration: number | null;
  levels: ILevel[];
  muscles: string | null;
  type: string | null;
  description: string | null;
  recommendations: IRecommendation[];
  rating: number;
  active: boolean;
  is_public: boolean;
  course_type: CourseTypes;
  progress?: IProgressCourse;
  trainer_id: number | null;
  direction: string | null;
  active_area: string | null;
  inventory: string | null;
  pulse_zone: string | null;
  groups: number[];
  workouts: IWorkout[];
  favorite: boolean;
  ordered: boolean;
  avatars: string;
  current_performing: number;
}

export type ICoursesInGroup = Omit<ICourse, 'workouts' | 'ordered'> & {
  pivot?: IPivot;
  created_at?: string;
  update_at?: string;
};

export interface ICourseDetailse extends ICourse {
  files?: IFilesForDetailseCourseCover[];
}

export interface IRating {
  id: string;
  type: CourseOrWorkoutTypes;
  rating: 1 | 2 | 3 | 4 | 5;
}

export interface ICountry {
  id: number;
  name: string;
  iso: null;
  sort: number;
  select: boolean;
}

export interface ISocial {
  id: number;
  image: string;
  link: string;
  name: string;
  order: number;
}

export interface IParameters {
  id: number;
  client_id: number;
  created_at: string;
  date: string;
  parameter_id: number;
  updated_at: string;
  value: string;
}

export interface IUser {
  id: number;
  name: string;
  phone: string | null;
  email: string;
  avatar: string | null;
  active: boolean;
  dob: string | null;
  sex: 0 | 1 | null;
  country: ICountry | null;
  country_id: number | null;
  orders: IOrderPayment[];
  trusted: boolean;
  push_notify: boolean;
  hide_stars: string | null;
  onboard_step?: number;
  referral_mode: number;
}

export interface IFavorites {
  id: number;
  type: CourseOrWorkoutTypes;
}

export interface IChangeWorkoutProgress {
  id: string;
  status: WorkoutsProgressStatuses;
}

export interface IChangeCourseProgress {
  id: string;
  status: CourseProgressStatus;
}

export interface IChangeCourseProgressResponse {
  id: number;
  progress: IProgressCourse;
}

export interface ITrainer {
  id: number;
  name: string;
  photo: string;
  description: string;
  cover: string;
  education: string;
  responsibility: string;
  power: string;
  text_html: string;
}

export interface ISize {
  width: number;
  height: number;
  resize: ResizeType;
}

export interface IUserInfoForUpdate {
  name: string;
  avatar: string;
  crop?: ICrop;
  dob?: string;
  country_id?: number | null;
  sex?: 0 | 1;
}

export interface IOnboardStep {
  onboard_step: number;
}

export interface PostFileResponse {
  id: string;
  user_filename: string;
}

export interface IPivot {
  course_group_id: number;
  course_id: number;
  sort: number;
}

export type TImages = Record<string, string>;

export interface IRegistrationByPhoneConfig {
  phone: string;
}

export interface IRegistrationByEmailConfig {
  email: string;
  password: string;
}

export interface IConfirmationConfig {
  code: string;
}

export interface IEmailConfirmationConfig extends IConfirmationConfig {
  email: string;
}

export interface IPasswordRecovery {
  email: string;
}

export interface ISetSize {
  date: string;
  parameter_id: number;
  value: string;
}

export interface IUpdateSize extends ISetSize {
  id: number;
}

export interface IReason {
  reason: string;
}

export interface IPhoneConfirmationConfig extends IConfirmationConfig {
  phone: string;
}

export interface IVersion {
  id: number;
  name: string;
  platform: string;
  version: number;
  required_update: boolean;
  created_at: string | null;
  updated_at: string | null;
  link: string | null;
}

export enum MethodPaymentType {
  yookassa = 'yookassa',
  robokassa = 'robokassa',
}

export interface IOrder {
  method: MethodPaymentType;
  product_id: number;
  single: boolean | undefined;
  platform: 'web' | 'app' | 'android' | 'ios' | undefined;
  native: boolean;
}

export interface IOrderPayment {
  expired_at: string | null;
  paid_at: string | null;
  id: number;
  link: string;
  price: number;
  product_id: number;
  status: number;
  message: string | null;
  product: { id: number; title: string };
}
export interface IRequestRegisterEmail {
  email: string;
}

export interface IResponseRegisterEmail {
  exists: boolean;
}

export interface IRequestRegisterPhone {
  phone: string;
}

export interface IResponseRegisterPhone {
  is_new: boolean;
}

export interface IRequestEmailConfirmationCheck {
  email: string;
  code: string;
}

export interface IRequestPhoneConfirmation {
  phone: string;
  code: string;
}

export interface IRequestEmailConfirmation {
  email: string;
  code: string;
  password: string;
}

export interface IFeedback {
  name: string;
  text: string;
  email: string;
  images: string[];
  model: string;
  os: string;
  size: string;
}

export interface IReviews {
  text?: string;
  stars: string;
}

interface INotificationParams {
  id: number;
  notification_id: number;
  name: string;
  value: string;
}

export interface INotification {
  id: number;
  template_id: number;
  group_id: number | null;
  type: PushNotificationsTypes;
  recipient: string;
  delay: number;
  created_at: string;
  updated_at: string;
  readed_at?: string | null;
  text: string;
  title: string;
  params: INotificationParams[];
}

export interface IClientAgreement {
  id: number;
  client_id: number;
  document: string;
  version: string;
  deleted_at: string | null;
  created_at: string;
  updated_at: string;
}

export interface ISetClientAgreement extends Omit<IClientAgreement, 'deleted_at'> {}

export interface IParamClientAgreement {
  document: string;
  version: string;
}

export interface IIntensity {
  id: number;
  title: 'низкая' | 'средняя' | 'высокая';
}

export interface ICards {
  active: boolean;
  data: string;
  id: number;
}

export interface ICardInform {
  card_type: TypesCardValues;
  expiry_month: string;
  expiry_year: string;
  first6: string;
  last4: string;
  id: number;
}

export interface ISetPushNotify {
  push_notify: boolean;
}
export interface ISetHideStars {
  hide_stars: boolean;
}

export interface IGrade {
  id: number;
  sort: number;
  created_at: string;
  updated_at: string;
  user_name: string;
  rating: number;
  text: string;
  user_photo: string;
}

export interface IPromoBanner {
  cover: string;
  link: string;
}

export interface IId {
  id: number;
}

export interface IGroupElem {
  variant: RenderSchemaVariants;
  ids?: IId[];
  type: string;
  title?: string;
  component: 'smallCard' | 'iconCard';
}

export interface IDurationGroupElemConfiguration {
  name: string;
  from?: number;
  to?: number;
}

export interface IDurationGroupElem extends Omit<IGroupElem, 'type' | 'component'> {
  type: VirtualGroups.duration;
}

export interface ILevelGroupElem extends Omit<IGroupElem, 'type' | 'component'> {
  type: VirtualGroups.level;
}

export interface ICollectionElem {
  variant: RenderSchemaVariants;
  id: number;
}

export interface IBannerElemAutoplay {
  delay: number;
  disableOnInteraction: boolean;
}

export interface IBannerElem {
  variant: RenderSchemaVariants.banner;
  autoplay?: IBannerElemAutoplay;
}

export type TRenderSchemaElem = IGroupElem | ICollectionElem | IBannerElem | IDurationGroupElem | ILevelGroupElem;

export interface IDurationFiltersSchema {
  name: string;
  from?: number;
  to?: number;
}

export type TRenderSchemes = Record<RenderSchemesNames.duration, IDurationFiltersSchema[]> &
  Record<RenderSchemesNames.schemeWorkout, TRenderSchemaElem[]>;

export interface IReferralLinkCreate {
  url: string;
}

export interface IReferralProduct {
  id: number;
  count_referrals: number;
  counter_referrals: number;
  image: string | null;
  text: string | null;
  title: string;
  present_id: number;
  present_type: string;
}

export interface IReferralLink {
  client_id: number;
  count_referrals: number;
  counter: number;
  created_at: string;
  id: number;
  link: string;
  updated_at: string;
}

export interface IGotProduct {
  referral_program_ids: number[];
}
export interface ICreateCommentRequest {
  group_id: number;
  parent_id: number | null;
  title: string;
  text: string;
  images: string[];
}

export interface IEditCommentRequest {
  id: number;
  title: string;
  text: string;
  images: string[];
}

export interface IComment {
  id: number;
  group_id: number;
  client_id: number;
  client: IClient;
  parent_id?: number | null;
  title: string;
  text: string;
  images: ICommentImage[];
  status: ICommentStatus;
  replies: IComment[];
  like_count: number;
  liked_me: boolean;
  uname: boolean;
  created_at: string;
  updated_at: string;
  user_id: number | null;
  baned: BanReason[];
}

export enum BanReason {
  TITLE = 'title',
  TEXT = 'text',
  IMAGES = 'images',
}

export enum ICommentStatus {
  NEW = 'new',
  APPROVED = 'approved',
  HIDDEN = 'hidden',
  CANCELLED = 'cancelled',
}

export interface ICommentImage {
  id: number;
  comment_id: number;
  name: string;
  created_at: string;
  updated_at: string;
  ban: boolean;
}

export interface IClient {
  id: number;
  name: string;
  avatar: string;
}

export enum CommentSortType {
  LIKE = 'like',
  DATE_ASC = 'asc',
  DATE_DESC = 'desc',
}

export enum CommentGroupType {
  COURSE = 'course',
}

export interface IGetCommentsGroupResponse {
  id: number;
  type: CommentGroupType;
  type_id: number;
  active: boolean;
  created_at: string;
  updated_at: string;
}

export interface IUserActivity {
  id: number;
  client_id: number;
  date: string;
  minutes: number;
  text: string;
  created_at: string;
  updated_at: string;
}

export interface ICreatetUserActivity {
  date: string;
  minutes: number;
  text: string;
}

export interface IEditUserActivity extends ICreatetUserActivity {
  id: number;
}

export interface IGetCommentsByGroupIdResponse extends IResponse<IComment[]> {
  meta: GetCommentMeta;
  links: GetCommentLink;
}

interface GetCommentMeta {
  current_page: number;
  from: number;
  last_page: number;
  links: GetCommentMetaLink[];
  path: string;
  per_page: number;
  to: number;
  total: number;
}

interface GetCommentMetaLink {
  url: string | null;
  label: string;
  active: boolean;
}

interface GetCommentLink {
  first: string;
  last: string;
  prev: string | null;
  next: string | null;
}

export interface IBanner {
  id: number;
  title: string;
  text: string;
  image: string;
  sort: number;
  link: string;
  button?: string;
}
export interface IGetAllStoriesResponse extends IResponse<IStory[]> {
  meta: {};
  links: {};
}

export interface IFAQ {
  active: boolean;
  answer: string;
  course_id: number;
  created_at: string;
  id: number;
  question: string;
  updated_at: string;
}

export interface ICrop {
  height: number;
  width: number;
  x: number;
  y: number;
}
