import React, { FC } from 'react';
import { BottomButtonBlock, BottomSheetHeader, BottomSheetUI } from '../..';
import { useBottomSheet } from '../../../hooks';
import { Box, Stack } from '@mui/material';
import { Button, Typography } from 'front-package-ui-kit';
import { MethodPaymentType } from '../../../api/types';
import s from './styles.module.scss';

export const openMethodsPaymentBottomSheet = 'openMethodsPaymentBottomSheet';

interface IProps {
  onClose: (method: MethodPaymentType) => void;
  onOnRecurrent: () => void;
  onOffRecurrent: () => void;
}

const BottomSheetMethodsPayment: FC<IProps> = ({ onClose, onOnRecurrent, onOffRecurrent }) => {
  const { onCloseBottomSheet, getIsOpenBottomSheet, bottomSheetState } = useBottomSheet();

  const isMethodsPayment = bottomSheetState === 'payment';

  const isAutoRenewal = bottomSheetState === 'autoRenewal';

  const isOpen = getIsOpenBottomSheet(openMethodsPaymentBottomSheet);

  const handleOnRecurrent = () => {
    onOnRecurrent();

    onCloseBottomSheet();
  };

  const handleOffRecurrent = () => {
    onOffRecurrent();

    onCloseBottomSheet();
  };

  return (
    <BottomSheetUI
      open={isOpen}
      touchLine
      height="auto"
      onDismiss={onCloseBottomSheet}
      footer={
        <>
          {isMethodsPayment && (
            <BottomButtonBlock>
              <Button onClick={() => onCloseBottomSheet()} size="L" color="white" fullWidth variant="contained">
                <Typography color="black" variant="text6" fontWeight={700}>
                  Закрыть
                </Typography>
              </Button>
            </BottomButtonBlock>
          )}

          {isAutoRenewal && (
            <BottomButtonBlock direction="column" rowGap="8px">
              <Button variant="contained" color="black" size="L" onClick={handleOnRecurrent} fullWidth>
                <Typography variant="text6" fontWeight={700} color="white">
                  Включить и перейти к оплате
                </Typography>
              </Button>

              <Button variant="text" size="L" onClick={handleOffRecurrent} fullWidth>
                <Typography variant="text6" fontWeight={700} color="black">
                  Оформить без автопродления
                </Typography>
              </Button>
            </BottomButtonBlock>
          )}
        </>
      }
      header={
        <>
          {isMethodsPayment && (
            <BottomSheetHeader
              title={
                <Box width="100%" textAlign="center">
                  <Typography variant="text3" fontWeight={700}>
                    Варианты оплаты
                  </Typography>
                </Box>
              }
              onClose={onCloseBottomSheet}
            />
          )}

          {isAutoRenewal && <BottomSheetHeader onClose={onCloseBottomSheet} />}
        </>
      }
    >
      <>
        {isMethodsPayment && (
          <Stack justifyContent="center" alignItems="center" textAlign="center" rowGap="8px" paddingX="30px">
            <Button
              onClick={() => onClose(MethodPaymentType.yookassa)}
              size="L"
              color="grey20"
              fullWidth
              variant="outlined"
            >
              <Typography color="black" variant="text6" fontWeight={500}>
                Оплата российскими картами
              </Typography>
            </Button>

            <Button
              onClick={() => onClose(MethodPaymentType.robokassa)}
              size="L"
              color="grey20"
              fullWidth
              variant="outlined"
            >
              <Typography color="black" variant="text6" fontWeight={500}>
                Оплата картами зарубежных банков
              </Typography>
            </Button>
          </Stack>
        )}

        {isAutoRenewal && (
          <Stack className={s.textContent}>
            <Typography variant="text3" textAlign="center" fontWeight={700}>
              Включить автопродление
            </Typography>

            <Typography variant="text6" textAlign="center">
              Привяжем карту, чтобы продлять подписку автоматически. Отключить можно в любой момент в личном кабинете
            </Typography>
          </Stack>
        )}
      </>
    </BottomSheetUI>
  );
};

export default BottomSheetMethodsPayment;
