import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MethodPaymentType } from '../../api/types';

interface IFiltersStore {
  methodPayment: MethodPaymentType | null;
}

export const initialState: IFiltersStore = {
  methodPayment: null,
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setMethodPayment(state, { payload }: PayloadAction<MethodPaymentType>) {
      state.methodPayment = payload;
    },
  },
});

export const { setMethodPayment } = paymentSlice.actions;

export default paymentSlice.reducer;
