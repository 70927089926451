import React from 'react';

export const NotFoundFavoriteIcon = () => {
  return (
    <svg width="111" height="90" viewBox="0 0 111 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_809_297)">
        <path
          d="M44.8221 82.7713C44.1352 83.4787 43.1276 83.6862 42.2172 83.3078C35.1961 80.3893 5.45011 67.0678 1.82447 49.4625C-2.85338 26.7479 22.589 17.7155 32.9138 31.54C36.9361 14.7609 63.877 13.0054 68.5549 35.72C72.1805 53.3253 50.1187 77.3161 44.8221 82.7713Z"
          fill="url(#paint0_linear_809_297)"
        />
        <g filter="url(#filter0_bi_809_297)">
          <path
            d="M55.852 83.1077C54.8893 83.2095 53.9997 82.7244 53.5523 81.8537C50.1019 75.1391 36.0863 45.9605 44.4407 30.1932C55.2196 9.85005 80.0688 19.2002 79.1406 36.3578C92.7348 26.1069 114.131 41.9742 103.352 62.3173C94.9976 78.0846 63.276 82.3228 55.852 83.1077Z"
            fill="url(#paint1_linear_809_297)"
            fillOpacity="0.2"
          />
          <path
            d="M55.852 83.1077C54.8893 83.2095 53.9997 82.7244 53.5523 81.8537C50.1019 75.1391 36.0863 45.9605 44.4407 30.1932C55.2196 9.85005 80.0688 19.2002 79.1406 36.3578C92.7348 26.1069 114.131 41.9742 103.352 62.3173C94.9976 78.0846 63.276 82.3228 55.852 83.1077Z"
            stroke="url(#paint2_linear_809_297)"
            strokeWidth="0.65"
            strokeLinejoin="round"
          />
        </g>
      </g>
      <defs>
        <filter
          id="filter0_bi_809_297"
          x="35.9997"
          y="12.8059"
          width="76.1098"
          height="76.1424"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="2.75032" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_809_297" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_809_297" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="0.916773" />
          <feGaussianBlur stdDeviation="1.83355" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_809_297" />
        </filter>
        <linearGradient
          id="paint0_linear_809_297"
          x1="31.0062"
          y1="22.2772"
          x2="43.6035"
          y2="83.4467"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8FF00" />
          <stop offset="1" stopColor="#00FF57" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_809_297"
          x1="54.0804"
          y1="12"
          x2="113.273"
          y2="67.84"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9D9C9C" />
          <stop offset="0.375" stopColor="#C1C1C1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_809_297"
          x1="83.5361"
          y1="28.0621"
          x2="53.8568"
          y2="82.4898"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <clipPath id="clip0_809_297">
          <rect width="110" height="90" fill="white" transform="translate(0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
};
