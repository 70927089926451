import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyNotifications = lazy(() => import('./Notifications'));

const NotificationsPage = () => (
  <Suspense fallback={<Loading />}>
    <LazyNotifications />
  </Suspense>
);

export default NotificationsPage;
