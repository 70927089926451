import React, { FC } from 'react';

const FailureIcon: FC = () => {
  return (
    <svg width="91" height="90" viewBox="0 0 91 90" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M79.5 45C79.5 63.7777 64.2777 79 45.5 79C26.7223 79 11.5 63.7777 11.5 45C11.5 26.2223 26.7223 11 45.5 11C64.2777 11 79.5 26.2223 79.5 45Z"
        fill="#FFE769"
      />
      <path
        d="M59.9142 33.4142C60.6953 32.6332 60.6953 31.3668 59.9142 30.5858C59.1332 29.8047 57.8668 29.8047 57.0858 30.5858L45.5 42.1716L33.9142 30.5858C33.1332 29.8047 31.8668 29.8047 31.0858 30.5858C30.3047 31.3668 30.3047 32.6332 31.0858 33.4142L42.6716 45L31.0858 56.5858C30.3047 57.3668 30.3047 58.6332 31.0858 59.4142C31.8668 60.1953 33.1332 60.1953 33.9142 59.4142L45.5 47.8284L57.0858 59.4142C57.8668 60.1953 59.1332 60.1953 59.9142 59.4142C60.6953 58.6332 60.6953 57.3668 59.9142 56.5858L48.3284 45L59.9142 33.4142Z"
        fill="white"
      />
    </svg>
  );
};

export default FailureIcon;
