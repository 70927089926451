import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazyMain = lazy(() => import('./Main'));

const MainPage = () => (
  <Suspense fallback={<Loading />}>
    <LazyMain />
  </Suspense>
);

export default MainPage;
