import { Box, Stack } from '@mui/material';
import React, { FC, useState } from 'react';
import { BottomButtonBlock, BottomSheetHeader, BottomSheetUI, FormTextarea, IconButtonUI, RatingBar } from '../..';
import api from '../../../api';
import { FeedBackSuccessIcon } from '../../../assets/icons';
import { useAnalytics, useAppDispatch, useBottomSheet, useUser } from '../../../hooks';
import { createServiceNotice } from '../../../redux/notifications/slice';
import { getTextError } from '../../../redux/utils';
import { AnalyticsName, Environment, getIsValue, noticeCreator } from '../../../utils';
import { Button, CloseIcon, NamesOfColors, Typography } from 'front-package-ui-kit';
import { TRatings } from '../RatingBar';
import { useLocation } from 'react-router-dom';

export const TEST_ID = 'FeedbackBottomSheet';

const TEXT_LIMIT = 5000;

export const openFeedbackBottomSheet = 'openFeedbackBottomSheet';

enum Steps {
  rating = 'rating',
  enterMessage = 'enter_message',
  ratingCompleted = 'rating_completed',
}

const postMarketReviews = window?.postMarketReviews;

const LOW_RATING = 3;

interface IProps {}

const FeedbackBottomSheet: FC<IProps> = () => {
  const { user } = useUser();

  const { setAnalytics } = useAnalytics();

  const { pathname } = useLocation();

  const { getIsOpenBottomSheet, onCloseBottomSheet } = useBottomSheet();

  const isOpen = getIsOpenBottomSheet(openFeedbackBottomSheet);

  const [currentStep, setCurrentStep] = useState(Steps.rating);

  const [rating, setRating] = useState<0 | TRatings>(0);

  const [text, setText] = useState('');

  const dispatch = useAppDispatch();

  const isRating = getIsValue(currentStep, Steps.rating);

  const isEnterMessage = getIsValue(currentStep, Steps.enterMessage);

  const isRatingCompleted = getIsValue(currentStep, Steps.ratingCompleted);

  const postReviews = async () => {
    const stars = rating.toString();

    setAnalytics(AnalyticsName.appEvaluation, { stars, pathname });

    try {
      await api.postReviews({ text, stars });

      setCurrentStep(Steps.ratingCompleted);
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'postReviews', forEnvironment: Environment.development },
        })
      );
    }
  };

  const onEstimateClickHandler = async () => {
    //если рейтинг выше 3-х звезд, пытаемся открыть маркет
    if (rating > LOW_RATING) {
      const postMarketResponse = postMarketReviews(rating);

      //если маркет открывается, отправляем оценку на бэк и переходим на шаг ratingCompleted
      if (postMarketResponse === true) {
        postReviews();

        setCurrentStep(Steps.ratingCompleted);

        return;
      }

      //если маркет открыть не получилось, переходим на шаг enterMessage
      if (postMarketResponse === false) {
        setCurrentStep(Steps.enterMessage);
      }

      return;
    }

    //если рейтинг меньше 3-х звезд, пытаемся открыть поддержку
    const openSupportResponse = window.openSupport({
      name: user?.name ?? '',
      email: user?.email ?? '',
      phone: user?.phone ?? '',
    });

    //если поддержку открыть не получилось, переходим на шаг enterMessage
    if (openSupportResponse === true) return;

    setCurrentStep(Steps.enterMessage);
  };

  const onMessageClearClickHandler = () => {
    setText('');
  };

  const onChangeTextHandler = ({ target: { value } }: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (value.length > TEXT_LIMIT) return;

    setText(value);
  };

  return (
    <BottomSheetUI
      open={isOpen}
      touchLine
      height="auto"
      onDismiss={onCloseBottomSheet}
      header={<BottomSheetHeader title={isRatingCompleted && <FeedBackSuccessIcon />} onClose={onCloseBottomSheet} />}
      footer={
        <BottomButtonBlock rowGap="8px" direction="column">
          {isRating && (
            <Button
              disabled={rating === 0}
              onClick={onEstimateClickHandler}
              color="black"
              size="L"
              variant="contained"
              fullWidth
            >
              <Typography
                variant="text6"
                opacity={!rating ? 0.3 : 1}
                fontWeight={700}
                color={!rating ? 'black' : 'white'}
              >
                Оценить
              </Typography>
            </Button>
          )}

          {isEnterMessage && (
            <>
              <Button
                disabled={text.length === 0}
                color="black"
                size="L"
                onClick={postReviews}
                variant="contained"
                fullWidth
              >
                <Typography
                  variant="text6"
                  opacity={text.length === 0 ? 0.3 : 1}
                  fontWeight={700}
                  color={text.length === 0 ? 'black' : 'white'}
                >
                  Отправить
                </Typography>
              </Button>

              <Button onClick={() => setCurrentStep(Steps.rating)} size="L" variant="text" fullWidth>
                <Typography variant="text6" fontWeight={700}>
                  Назад
                </Typography>
              </Button>
            </>
          )}

          {isRatingCompleted && (
            <Button onClick={() => onCloseBottomSheet()} size="L" color="black" variant="contained" fullWidth>
              <Typography variant="text6" color="white" fontWeight={700}>
                Хорошо
              </Typography>
            </Button>
          )}
        </BottomButtonBlock>
      }
    >
      {isRating && (
        <>
          <Typography fontWeight={700} textAlign="center" variant="text3" marginBottom="30px">
            Оцените приложение
          </Typography>

          <RatingBar
            columnGap="16px"
            marginBottom="30px"
            currentRating={rating}
            size="medium"
            setCurrentRating={setRating}
          />
        </>
      )}

      {isEnterMessage && (
        <Box padding="0 20px">
          <Typography marginBottom="30px" textAlign="center" variant="h4" fontWeight={700}>
            Поделитесь своими <br />
            впечатлениями
          </Typography>

          <FormTextarea
            endAdornment={
              Boolean(text.length) && (
                <IconButtonUI type="button" onClick={onMessageClearClickHandler} size={24}>
                  <CloseIcon size={24} color={NamesOfColors.grey100} />
                </IconButtonUI>
              )
            }
            value={text}
            onChange={onChangeTextHandler}
            label="Ваше сообщение"
            rows={5}
            marginBottom="30px"
          />
        </Box>
      )}

      {isRatingCompleted && (
        <Stack alignItems="center">
          <Typography variant="h4" fontWeight={700} marginBottom="20px">
            Спасибо за оценку!
          </Typography>
        </Stack>
      )}
    </BottomSheetUI>
  );
};

export default FeedbackBottomSheet;
