import React from 'react';

export const NotFoundSizesIcon = () => {
  return (
    <svg width="111" height="58" viewBox="0 0 111 58" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_3087_1214)">
        <path
          d="M75.7871 48.4949L75.8242 17.6822C75.8242 17.6822 72.5695 31.1661 39.0921 31.6311C0.965813 32.1606 0.5 19.0771 0.5 19.0771L0.76631 44.4746C0.76631 44.4746 22.4373 54.7813 37.9508 54.7813C52.6753 54.7813 75.7871 48.4949 75.7871 48.4949Z"
          fill="url(#paint0_linear_3087_1214)"
        />
        <g filter="url(#filter0_bi_3087_1214)">
          <path
            d="M96.2002 31.4818H37.53C17.0274 31.3285 0.5 24.4261 0.5 15.9131V42.4362C0.5 44.3152 1.16467 46.1558 2.45567 47.5235C8.00314 53.3905 22.0636 57.5575 38.1435 57.5575H96.2002V31.4818Z"
            fill="url(#paint1_linear_3087_1214)"
            fillOpacity="0.2"
          />
        </g>
        <path
          d="M86.0535 31.6309H104.741C107.822 31.6309 110.327 34.1362 110.327 37.2167V52.1335C110.327 55.214 107.822 57.7193 104.741 57.7193H86.0535V31.6437V31.6309Z"
          fill="url(#paint2_linear_3087_1214)"
        />
        <circle cx="97.9342" cy="44.5083" r="3.42878" fill="url(#paint3_linear_3087_1214)" />
        <mask
          id="mask0_3087_1214"
          style={{ maskType: 'luminance' }}
          maskUnits="userSpaceOnUse"
          x="0"
          y="16"
          width="97"
          height="42"
        >
          <path
            d="M96.2139 31.5894H37.5437C17.041 31.436 0.513672 24.5336 0.513672 16.0207V42.5437C0.513672 44.4227 1.17835 46.2633 2.46935 47.631C8.01682 53.4981 22.0772 57.6651 38.1572 57.6651H96.2139V31.5894Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_3087_1214)">
          <path
            d="M4.6875 40.5174V51.4078"
            stroke="white"
            strokeWidth="0.929929"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M9.83862 47.1514V55.1019"
            stroke="white"
            strokeWidth="0.929929"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M14.9897 49.8737V57.8242"
            stroke="white"
            strokeWidth="0.929929"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M20.1538 46.9341V57.8245"
            stroke="white"
            strokeWidth="0.929929"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M25.3049 52.814V59.2946"
            stroke="white"
            strokeWidth="0.929929"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M30.4563 52.814V59.2946"
            stroke="white"
            strokeWidth="0.929929"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M35.6204 46.9341V59.2945"
            stroke="white"
            strokeWidth="0.929929"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M40.7715 52.814V59.2946"
            stroke="white"
            strokeWidth="0.929929"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M45.9229 52.814V59.2946"
            stroke="white"
            strokeWidth="0.929929"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M51.0867 46.9341V59.2945"
            stroke="white"
            strokeWidth="0.929929"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M56.238 52.814V59.2946"
            stroke="white"
            strokeWidth="0.929929"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M61.3381 52.7629L61.3893 59.2946"
            stroke="white"
            strokeWidth="0.929929"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M66.5532 46.9341V59.2945"
            stroke="white"
            strokeWidth="0.929929"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M71.7046 52.814V59.2946"
            stroke="white"
            strokeWidth="0.929929"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M76.9707 52.7754V59.371"
            stroke="white"
            strokeWidth="0.929929"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
          <path
            d="M82.2498 47.0876V59.4351"
            stroke="white"
            strokeWidth="0.929929"
            strokeMiterlimit="10"
            strokeLinecap="round"
          />
        </g>
        <g filter="url(#filter1_bi_3087_1214)">
          <path
            d="M38.1435 32.0806C58.9335 32.0806 75.7871 25.1103 75.7871 16.5119C75.7871 7.91358 58.9335 0.94323 38.1435 0.94323C17.3536 0.94323 0.5 7.91358 0.5 16.5119C0.5 25.1103 17.3536 32.0806 38.1435 32.0806Z"
            fill="url(#paint4_linear_3087_1214)"
            fillOpacity="0.2"
          />
          <path
            d="M75.5081 16.5119C75.5081 18.5636 74.5039 20.5439 72.6338 22.3725C70.7628 24.202 68.041 25.8625 64.6549 27.2629C57.8842 30.0631 48.5111 31.8017 38.1435 31.8017C27.776 31.8017 18.4029 30.0631 11.6322 27.2629C8.24614 25.8625 5.52428 24.202 3.65326 22.3725C1.78315 20.5439 0.778979 18.5636 0.778979 16.5119C0.778979 14.4603 1.78315 12.48 3.65326 10.6514C5.52428 8.82184 8.24614 7.1614 11.6322 5.761C18.4029 2.96073 27.776 1.22221 38.1435 1.22221C48.5111 1.22221 57.8842 2.96073 64.6549 5.761C68.041 7.1614 70.7628 8.82184 72.6338 10.6514C74.5039 12.48 75.5081 14.4603 75.5081 16.5119Z"
            stroke="url(#paint5_linear_3087_1214)"
            strokeOpacity="0.3"
            strokeWidth="0.557957"
          />
        </g>
        <path
          d="M38.1437 23.0054C46.8268 23.0054 53.8658 20.0982 53.8658 16.512C53.8658 12.9258 46.8268 10.0187 38.1437 10.0187C29.4607 10.0187 22.4216 12.9258 22.4216 16.512C22.4216 20.0982 29.4607 23.0054 38.1437 23.0054Z"
          fill="url(#paint6_linear_3087_1214)"
        />
      </g>
      <defs>
        <filter
          id="filter0_bi_3087_1214"
          x="-10.6591"
          y="4.75398"
          width="118.018"
          height="63.9627"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5.57957" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_3087_1214" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_3087_1214" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.85986" />
          <feGaussianBlur stdDeviation="3.71972" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3087_1214" />
        </filter>
        <filter
          id="filter1_bi_3087_1214"
          x="-10.6591"
          y="-10.2159"
          width="97.6054"
          height="53.4557"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feGaussianBlur in="BackgroundImageFix" stdDeviation="5.57957" />
          <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_3087_1214" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_3087_1214" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1.85986" />
          <feGaussianBlur stdDeviation="3.71972" />
          <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
          <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.4 0" />
          <feBlend mode="normal" in2="shape" result="effect2_innerShadow_3087_1214" />
        </filter>
        <linearGradient
          id="paint0_linear_3087_1214"
          x1="77.8757"
          y1="31.3683"
          x2="7.92739"
          y2="34.9239"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8FF00" />
          <stop offset="1" stopColor="#00FF57" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_3087_1214"
          x1="0.5"
          y1="15.9131"
          x2="90.0198"
          y2="68.1492"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9D9C9C" />
          <stop offset="0.375" stopColor="#C1C1C1" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_3087_1214"
          x1="98.1902"
          y1="31.6309"
          x2="98.1902"
          y2="57.7193"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8FF00" />
          <stop offset="1" stopColor="#00FF57" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_3087_1214"
          x1="97.9342"
          y1="41.0796"
          x2="101.363"
          y2="49.881"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.81" />
          <stop offset="1" stopColor="white" stopOpacity="0.75" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_3087_1214"
          x1="0.5"
          y1="0.94323"
          x2="69.061"
          y2="43.0362"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9D9C9C" />
          <stop offset="0.375" stopColor="#C1C1C1" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_3087_1214"
          x1="38.1435"
          y1="0.943229"
          x2="48.6511"
          y2="32.5396"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" stopOpacity="0.39" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_3087_1214"
          x1="56.4534"
          y1="16.9183"
          x2="25.0283"
          y2="16.0765"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A8FF00" />
          <stop offset="1" stopColor="#00FF57" />
        </linearGradient>
        <clipPath id="clip0_3087_1214">
          <rect width="110.662" height="57.6556" fill="white" transform="translate(0.5 0.34436)" />
        </clipPath>
      </defs>
    </svg>
  );
};
