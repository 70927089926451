import { useMemo } from 'react';
import { useAppSelector } from '.';
import { IProducts } from '../api/types';
import { Statuses } from '../utils';

const getProductsForPayment = (products: IProducts[]) => {
  return products.filter((product) => product.duration_type === 'month');
};

export const useProducts = () => {
  const {
    orderStore: {
      product: { data: product, statuse: statuseProduct, error: errorProduct },
      products: { data: products, statuse },
    },
    authStore: { isLoading },
  } = useAppSelector((state) => ({ orderStore: state.orderStore, authStore: state.authStore }));

  const isProducts = Boolean(products?.length);

  const isProductsLoading = ((statuse === Statuses.idle || statuse === Statuses.loading) && !isProducts) || isLoading;

  const productsForPayment = useMemo(() => getProductsForPayment(products || []), [products]);

  return {
    products,
    isProducts,
    isProductsLoading,
    product,
    statuseProduct,
    errorProduct,
    productsForPayment,
  };
};
