import { useAppSelector } from '.';
import { Statuses } from '../utils';

export const useCards = () => {
  const {
    orderStore: {
      cards: { data: cards, statuse },
    },
    authStore: { isLoading },
  } = useAppSelector((state) => ({ orderStore: state.orderStore, authStore: state.authStore }));

  const isCards = Boolean(cards?.length);

  const isCardsLoading = ((statuse === Statuses.idle || statuse === Statuses.loading) && !isCards) || isLoading;

  return {
    cards,
    isCards,
    isCardsLoading,
  };
};
