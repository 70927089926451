import { ICardInform, ICards, IOrderPayment } from '../api/types';

export const getStatusPaid = (orderPaid: IOrderPayment | null) => {
  if (orderPaid && orderPaid.expired_at) {
    return new Date(orderPaid.expired_at) > new Date();
  }
  return null;
};

export const getPaidOrderByMaxExpired = (orders: IOrderPayment[]) => {
  const paidOrder = orders.filter((order) => order.expired_at);

  if (!paidOrder.length) return null;

  const [lastExpiredDate] = paidOrder.sort((current, prev) => {
    if (current.expired_at && prev.expired_at) {
      return new Date(prev.expired_at).getTime() - new Date(current.expired_at).getTime();
    }
    return 0;
  });

  return lastExpiredDate;
};

export const getTransformedCardAccount = (cards: ICards[] | null): ICardInform[] => {
  if (cards && cards.length) {
    return cards.map((card) => {
      const parseData: Omit<ICardInform, 'id'> = JSON.parse(card.data);

      return {
        ...parseData,
        id: card.id,
      };
    });
  }
  return [];
};
