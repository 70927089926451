import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { matcherHelper } from '../utils';
import { TImages } from '../../api/types';

interface IImages {
  images: TImages;
  userAvatars: TImages | null;
  error: string;
  isLoading: boolean;
}

const initialState: IImages = {
  images: {},
  userAvatars: null,
  error: '',
  isLoading: false,
};

export const imagesSlice = createSlice({
  name: 'images',
  initialState,
  reducers: {
    setImage(state, { payload: { name, imageUrl } }: PayloadAction<{ name: string; imageUrl: string }>) {
      state.images[name] = imageUrl;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        (action) => matcherHelper.isPendingAction(action.type, imagesSlice.name),
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => matcherHelper.isRejectedAction(action.type, imagesSlice.name),
        (state, { payload }) => {
          state.isLoading = false;
          state.error = payload;
        }
      );
  },
});

export const { setImage } = imagesSlice.actions;

export default imagesSlice.reducer;
