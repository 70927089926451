import { createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../api';
import { getTextError } from '../utils';
import { removeCard, setCard, clearProduct } from './slice';
import { createServiceNotice } from '../notifications/slice';
import { Environment, ErrorMessages, SuccessMessages, noticeCreator } from '../../utils';
import { AppDispatch, RootState } from '../store';

export const getStatusOrderThunk = createAsyncThunk(
  'order/getStatusOrderThunk',
  async (id: number, { rejectWithValue, dispatch }) => {
    try {
      return (await api.getOrderInform(id)).data.data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'order/getStatusOrderThunk', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const getCardListThunk = createAsyncThunk('cards/getCardListThunk', async (_, { rejectWithValue, dispatch }) => {
  try {
    return (await api.getCardList()).data.data;
  } catch (error) {
    const textError = getTextError(error);

    dispatch(
      createServiceNotice({
        notice: noticeCreator(textError, 'error'),
        otherInfo: { error, pathname: 'cards/getCardListThunk', forEnvironment: Environment.development },
      })
    );

    return rejectWithValue(textError);
  }
});

export const deleteCardThunk = createAsyncThunk<
  {},
  number,
  {
    dispatch: AppDispatch;
    state: RootState;
    rejectWithValue: string;
  }
>('cards/deleteCardThunk', async (id: number, { rejectWithValue, dispatch, getState }) => {
  const state = getState();

  try {
    dispatch(removeCard(id));

    dispatch(
      createServiceNotice({
        notice: noticeCreator(SuccessMessages.deleteCard, 'success', 1000),
        otherInfo: { pathname: 'cards/deleteCardThunk', forEnvironment: Environment.production },
      })
    );

    await api.deleteCard(id);
  } catch (error) {
    const textError = getTextError(error);

    if (state.orderStore.cards.data?.length) dispatch(setCard(state.orderStore.cards.data));

    dispatch(
      createServiceNotice({
        notice: noticeCreator(ErrorMessages.deleteCard, 'error'),
        otherInfo: { error, pathname: 'cards/deleteCardThunk', forEnvironment: Environment.production },
      })
    );

    return rejectWithValue(textError);
  }
});

export const getProductsThunk = createAsyncThunk(
  'products/getProductsThunk',
  async (params: { [key: string]: string | undefined }, { rejectWithValue, dispatch }) => {
    try {
      return (await api.getProducts(params)).data.data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'products/getProductsThunk', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);

export const getProductThunk = createAsyncThunk(
  'products/getProductThunk',
  async ({ id, promocode }: { id: number; promocode?: string }, { rejectWithValue, dispatch }) => {
    try {
      const currentPlatform = await window?.getPlatform();

      return (await api.getProduct(id, { promocode: promocode, platform: currentPlatform })).data.data;
    } catch (error) {
      const textError = getTextError(error);

      dispatch(clearProduct());

      dispatch(
        createServiceNotice({
          notice: noticeCreator(textError, 'error'),
          otherInfo: { error, pathname: 'products/getProductThunk', forEnvironment: Environment.development },
        })
      );

      return rejectWithValue(textError);
    }
  }
);
