import React, { lazy, Suspense } from 'react';

import { Loading } from '../../components';

const LazySettingNotifications = lazy(() => import('./SettingNotifications'));

const SettingNotificationsPage = () => (
  <Suspense fallback={<Loading />}>
    <LazySettingNotifications />
  </Suspense>
);

export default SettingNotificationsPage;
