import React, { FC } from 'react';
import s from './styles.module.scss';
import { Skeleton } from 'front-package-ui-kit';

export const TEST_ID = 'CourseCardSkeleton';

interface IProps {}

const CourseCardSkeleton: FC<IProps> = () => {
  return (
    <div className={s.courseCardSkeleton} data-testid={TEST_ID}>
      <div className={s.content}>
        <Skeleton width="100%" height="100%" />
      </div>
    </div>
  );
};

export default CourseCardSkeleton;
