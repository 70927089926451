import React, { FC, useEffect, useRef, useState } from 'react';
import { Container } from '@mui/material';
import s from './styles.module.scss';
import Lottie, { LottieRefCurrentProps } from 'lottie-react';
import rastrAnimation from '../../assets/json/rastr.json';
import vectorAnimation from '../../assets/json/vector.json';
import rotateReceivedGift from '../../assets/json/rotateReceivedGift1.json';
import { BottomNavigationUI, CssPlatformContainer, TabButtons } from '../../components';
import { bottomNavigationOptions } from '../../utils';
import { TabPanelUnstyled, TabsUnstyled } from '@mui/base';

import staticGift1 from '../../assets/json/staticGift1.json';
import rotateNoReceivedGift1 from '../../assets/json/rotateNoReceivedGift1.json';
import getGift1 from '../../assets/json/getGift1.json';
import rotateReceivedGift1 from '../../assets/json/rotateReceivedGift1.json';
import staticGift2 from '../../assets/json/staticGift2.json';
import rotateNoReceivedGift2 from '../../assets/json/rotateNoReceivedGift2.json';
import getGift2 from '../../assets/json/getGift2.json';
import rotateReceivedGift2 from '../../assets/json/rotateReceivedGift2.json';
import staticGift3 from '../../assets/json/staticGift3.json';
import rotateNoReceivedGift3 from '../../assets/json/rotateNoReceivedGift3.json';
import getGift3 from '../../assets/json/getGift3.json';
import rotateReceivedGift3 from '../../assets/json/rotateReceivedGift3.json';

export const TEST_ID = 'Test';

const TAB_BUTTONS = ['Растровая', 'Векторная'];

const Test: FC = () => {
  const [currentTab, setCurrentTab] = useState(TAB_BUTTONS[0]);
  const lotRef = useRef<LottieRefCurrentProps>(null);

  useEffect(() => {
    if (lotRef) {
      setTimeout(() => {
        lotRef.current?.setDirection(-1);
        lotRef.current?.play();
      }, 2000);
    }
  }, [lotRef]);

  return (
    <Container disableGutters maxWidth="xs" className={s.test} data-testid={TEST_ID}>
      <BottomNavigationUI options={bottomNavigationOptions}>
        <CssPlatformContainer>
          <div className={s.testContent}>
            <TabsUnstyled className={s.tabContainerContext} value={currentTab}>
              <TabButtons options={TAB_BUTTONS} onChange={setCurrentTab} defaultValue={currentTab} />

              <TabPanelUnstyled className={s.tabContent} value={TAB_BUTTONS[0]}>
                1{/* <Lottie style={{ height: '100%' }} animationData={rastrAnimation} /> */}
                <Lottie lottieRef={lotRef} animationData={staticGift1} />
                <Lottie lottieRef={lotRef} animationData={rotateNoReceivedGift1} />
                <Lottie lottieRef={lotRef} animationData={getGift1} />
                <Lottie lottieRef={lotRef} animationData={rotateReceivedGift1} />
                {/* <Lottie style={{ height: '100%' }} animationData={rastrAnimation} /> */}
                2
                <Lottie lottieRef={lotRef} animationData={staticGift2} />
                <Lottie lottieRef={lotRef} animationData={rotateNoReceivedGift2} />
                <Lottie lottieRef={lotRef} animationData={getGift2} />
                <Lottie lottieRef={lotRef} animationData={rotateReceivedGift2} />
                {/* <Lottie style={{ height: '100%' }} animationData={rastrAnimation} /> */}
                3
                <Lottie lottieRef={lotRef} animationData={staticGift3} />
                <Lottie lottieRef={lotRef} animationData={rotateNoReceivedGift3} />
                <Lottie lottieRef={lotRef} animationData={getGift3} />
                <Lottie lottieRef={lotRef} animationData={rotateReceivedGift3} />
              </TabPanelUnstyled>

              <TabPanelUnstyled className={s.tabContent} value={TAB_BUTTONS[1]}>
                <Lottie animationData={vectorAnimation} />
              </TabPanelUnstyled>
            </TabsUnstyled>
          </div>
        </CssPlatformContainer>
      </BottomNavigationUI>
    </Container>
  );
};

export default Test;
