import React, { FC, memo, useEffect, useRef, useState } from 'react';
import s from './styles.module.scss';
import { useAppSelector, usePlatform, useScrollSave } from '../../../hooks';
import { SaveScrollPathnames } from '../../../utils';
import { useUpdateEffect } from 'usehooks-ts';
import cn from 'classnames';

export const TEST_ID = 'SaveScroll';

export interface SaveScrollProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  pathname: [SaveScrollPathnames, string];
  resetScrollDeps?: any[];
}

const SaveScroll: FC<SaveScrollProps> = ({ children, pathname, className, resetScrollDeps = [], ...props }) => {
  const { platform } = usePlatform();

  const [hide, setHide] = useState(true);

  const key = `${pathname[0]}-${pathname[1]}`;

  const { scroll } = useAppSelector((state) => state.scrollSaveStore);

  const timer = useRef<NodeJS.Timer | null>(null);

  const ref = useRef<HTMLDivElement | null>(null);

  const [onScroll] = useScrollSave(key);

  useEffect(() => {
    if (!scroll[key]) {
      if (hide === true) setHide(false);

      return;
    }

    if (timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => {
      ref.current?.scrollTo(0, scroll[key]);

      setHide(false);
    }, 100);

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current]);

  useEffect(() => {
    if (platform !== 'ios') return;

    const currentRef = ref.current;

    let xDown: number | null = null;
    let yDown: number | null = null;

    const hadleTouchstart = (e: TouchEvent) => {
      xDown = e.touches[0].clientX;
      yDown = e.touches[0].clientY;
    };

    const handleTouchmove = (e: TouchEvent) => {
      if (!xDown || !yDown || !ref.current) {
        return;
      }

      const scrollBottomPosition = () => {
        return ref.current!.scrollHeight - ref.current!.scrollTop - ref.current!.offsetHeight;
      };

      var xUp = e.touches[0].clientX;
      var yUp = e.touches[0].clientY;

      var xDiff = xDown - xUp;
      var yDiff = yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        if (xDiff > 0) {
          // left
        } else {
          //right
        }
      } else {
        // up
        if (yDiff > 0) {
          if (ref.current && scrollBottomPosition() <= 0) {
            requestAnimationFrame(() => {
              ref.current!.style.overflow = 'hidden';
              ref.current!.scrollTop = ref.current!.scrollHeight;
              ref.current!.style.removeProperty('overflow');
            });

            e.preventDefault();
          }
        } else {
          // down
          if (ref.current && ref.current.scrollTop <= 0) {
            requestAnimationFrame(() => {
              ref.current!.style.overflow = 'hidden';
              ref.current!.scrollTop = 0;
              ref.current!.style.removeProperty('overflow');
            });

            e.preventDefault();
          }
        }
      }

      xDown = e.touches[0].clientX;
      yDown = e.touches[0].clientY;
    };

    ref.current?.addEventListener('touchstart', hadleTouchstart);
    ref.current?.addEventListener('touchmove', handleTouchmove);

    return () => {
      currentRef?.removeEventListener('touchstart', hadleTouchstart);
      currentRef?.removeEventListener('touchmove', handleTouchmove);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref.current, platform]);

  useUpdateEffect(() => {
    if (ref.current) {
      ref.current.scrollTo(0, 0);
    }
  }, [...resetScrollDeps]);

  return (
    <div
      data-testid={TEST_ID}
      ref={ref}
      onScroll={onScroll}
      className={cn(s.saveScroll, { [s.hide]: hide }, className)}
      {...props}
    >
      {children}
    </div>
  );
};

export const SaveScrollMemo = memo(SaveScroll);
