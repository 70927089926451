import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProducts } from '../../api/types';

interface IManageSubscribePage {
  foundedProduct: IProducts | null;
}

const initialState: IManageSubscribePage = {
  foundedProduct: null,
};

export const manageSubscribePageSlice = createSlice({
  name: 'manageSubscribePage',
  initialState,
  reducers: {
    setFoundedProduct(state, { payload }: PayloadAction<IProducts | null>) {
      state.foundedProduct = payload;
    },
  },
});

export const { setFoundedProduct } = manageSubscribePageSlice.actions;

export default manageSubscribePageSlice.reducer;
